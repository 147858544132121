import React from 'react'

const ResultsInfo = ({
    currentPage,
    ItemsPerPage,
    TotalResults,
    annonces,
    totalPages,
  }) => {
  return (
    <>
      {totalPages == 1 ? (
        <p className="woocommerce-result-count">
          {`${annonces?.length} résultats affichés`}
        </p>
      ) : (
        <p className="woocommerce-result-count">
          {`Affichage de ${(currentPage - 1) * ItemsPerPage + 1}–${Math.min(
            (currentPage - 1) * ItemsPerPage + 1 + annonces?.length - 1,
            TotalResults
          )} sur ${TotalResults} résultats`}
        </p>
      )}
    </>
  )
}

export default ResultsInfo