import React, { useState } from 'react'
import { isLogged, redirectTo } from '../constants/AuthFunctions';
import bgImage from "../assests/Slide-2-Vendez-vos-produits-ou-services-trouvez-des-acheteurs-et-clients-cibles-2.jpg";
import { Link, useNavigate } from 'react-router-dom';
import { dashboardRoute } from '../constants/env';
function Hero() {
  
  const navigate = useNavigate()
  const [auth, setAuth] = useState(isLogged());
  // Add your CSS styles here to make the hero responsive and adjustable
  const heroStyles = {
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100vw',
        overflow: 'hidden',
        zIndex: '-1',
        marginTop : '-10px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        color: 'white',
        textAlign: 'center'
      }
  return (
    <>
    <div className="page-content">
      <div className="entry-content"></div>
    </div>
    <div
      className="stretch-full-width slider-with-das mb-5"
      style={heroStyles}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg hero">
            <h4>Créer votre page <br/> entreprise & </h4>
            <h1>Contacter des professionnels <br/> maintenant! </h1>
            <div
              className="call-to-action bg-warning"
              style={{
                cursor : 'pointer'
              }}
              onClick={
                auth ? 
                  (e) => redirectTo(e)
                :
                  () => navigate('/auth/login')
              }
            >
              <i className="fa-solid fa-building px-3"></i>
                {
                  auth ? "Gérer ma page entreprise" : "Créer ma page entreprise"
                }
            </div>
          </div>

          <div className="col-lg-auto">
            <div className="da-block pt-xl-5 mt-2 pb-xl-4">
              <div className="da">
                <div className="da-inner px-3 py-3 position-relative">
                  <Link
                    className="da-media d-flex stretched-link"
                    to={
                      !auth ? "/auth/login" : `/fournisseurs/${JSON.parse(localStorage.getItem("user"))?.company_id}`
                    }
                  >
                    <div className="da-media-body">
                      <div
                        className="da-text mb-3 text-capitalize"
                        style={{ fontSize: "16px", lineHeight: "26px", textAlign : "start" }}
                      >
                        Vendez vos{" "}
                        <strong>
                          produits <br /> ou services
                        </strong>{" "}
                        : trouvez <br /> des acheteurs et clients cibles
                      </div>
                      <div className="da-action" >
                        {
                          auth ? "Voir ma Page Entreprise" : "Créer ma Page Entreprise"
                        }
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="da">
                <div className="da-inner px-3 py-3 position-relative">
                  <a
                    className="da-media d-flex stretched-link"
                    target="_blank"
                    href={
                      auth ? "#" : `${dashboardRoute}/mes-announces`
                    }
                    onClick={
                      auth ? (e) => redirectTo(e) : null
                    }
                  >
                    <div className="da-media-body">
                      <div
                        className="da-text mb-3 text-capitalize"
                        style={{ fontSize: "16px", lineHeight: "26px", textAlign : "start" }}
                      >
                        Vous êtes <strong>Acheteur</strong>,{" "}
                        <strong>Vendeur</strong> ou
                        <br /> Bien <strong>Exportateur</strong>. Publiez
                        Votre
                        <br /> annonce maintenant !
                      </div>
                      <div className="da-action">Publier Mon Annonce</div>
                    </div>
                  </a>
                </div>
              </div>
              
              <div className="da mt-2">
                <div
                  className="da-inner px-3 py-3 position-relative"
                  style={{ background: "#ee9e06" }}
                >
                  <Link
                    className="da-media d-flex stretched-link"
                    to="/plans"
                  >
                    <div className="da-media-body">
                      <div
                        className="da-text mb-2 text-capitalize"
                        style={{ fontSize: "16px", lineHeight: "26px", textAlign : "start" }}
                      >
                        Valoriser Votre image,
                        <br /> attirez plus <strong>de clients</strong>
                        <br /> et devenez un Fournisseur <br /> / Acheteur /
                        Exportateur confirmé
                      </div>
                      <div className="da-action">Devenir PREMIUM</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </>
  )
}

export default Hero