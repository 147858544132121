import React, { useEffect, useState } from 'react';
import { axiosClient } from '../../constants/axionsClient';
import { Loading } from '../'
import { Link } from 'react-router-dom';
import { product } from '../../constants/images';

const Announce = ({item}) => {

    const {
      ref, title, photo, price
    } = item

    return (
        <li key={ref}>
          <Link to={`/product/${ref}`}>
            <img
              width="1"
              height="1"
              src={photo || product}
              className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
              alt=""
              decoding="async"
              loading="lazy"
            />
            <span className="product-title">{title}</span>
          </Link>
          <span className="electro-price">
            <span className="woocommerce-Price-amount amount">
              <bdi>{price}
              <span className="woocommerce-Price-currencySymbol">DH </span>
              </bdi>
            </span>
          </span>
        </li>
    )

} 

const RecentAnnonce = () => {

  const [products, setProducts] = useState([])
  const [IsLoading, setIsLoading] = useState(true)


  useEffect(()=>{

    (async()=>{

      try {
        
        const res = await axiosClient.get(`/recent-announces`)

        if(res.status === 200){

          setProducts(res.data)

        }

      } catch (error) {
        console.log(error);
      }

      setIsLoading(false)

    })()

  }, [])

  return (
    <aside
      id="woocommerce_products-2"
      className="widget woocommerce widget_products"
    >
      <h3 className="widget-title">Plus récents</h3>

      {

        IsLoading && <Loading style={null} loadingStyle={{
          width:'40px',
        }} />

      }

      <ul className="product_list_widget">
        
        {
          products?.map((item) => (
            <Announce item={item} />
          ))
        }

      </ul>

    </aside>
  );
};

export default RecentAnnonce;
