import React, { useEffect, useState } from "react";
import { ActionListSouhaits, Loading, NavLink, RelatedProducts } from "../../components";
import { axiosClient } from "../../constants/axionsClient";
import { Link, useNavigate, useParams } from "react-router-dom";
import SideAnnonceDetails from "./SideAnnonceDetails";
import { product } from "../../constants/images";
import toast from 'react-hot-toast'
import { isLogged } from "../../constants/AuthFunctions";

const ImagesComponent = ({photos}) => {
  
  const [index, setindex] = useState(0);

  return (
    
    <>
      <div className="image-container main-image">
        <img
          style={{
            borderRadius : '0.5rem',
            width : '100%',
            height : '100%'
          }}
          src={(photos && photos[index]) || product}
          alt="Image"
        />
      </div>

      <div className="thumbnail-container">
        {photos &&
          photos?.map((item, i) => (
            <img
              key={i}
              src={item || product}
              className={`thumbnail-image ${
                i === index ? "active" : ""
              }`}
              onMouseEnter={() => setindex(i)}
              alt={`Image_${i}`}
            />
          ))}
      </div>
    </>
  )
}

const AnnounceDetails = () => {

  const { ref } = useParams();
  const navigate = useNavigate()
  const [Announce, setAnnounce] = useState({});
  const [auth, setauth] = useState(isLogged());
  const [isSouhaits, setIsSouhaits] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);
  const [Quantite, setQuantite] = useState(0);

  useEffect(() => {

    document.body.removeAttribute("class");
    document.body.classList.add(
      "product-template-default",
      "single",
      "single-product",
      "postid-2933",
      "logged-in",
      "admin-bar",
      "theme-electro",
      "woocommerce",
      "woocommerce-page",
      "woocommerce-js",
      "translatepress-fr_FR",
      "left-sidebar",
      "normal",
      "wpb-js-composer",
      "js-comp-ver-6.8.0",
      "vc_responsive",
      "customize-support"
    );

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove(
        "product-template-default",
        "single",
        "single-product",
        "postid-2933",
        "logged-in",
        "admin-bar",
        "theme-electro",
        "woocommerce",
        "woocommerce-page",
        "woocommerce-js",
        "translatepress-fr_FR",
        "left-sidebar",
        "normal",
        "wpb-js-composer",
        "js-comp-ver-6.8.0",
        "vc_responsive",
        "customize-support"
      );
    };

  }, []);

  useEffect(() => {

    (async () => {

      window.scrollTo({ top : 0, behavior : 'smooth' })

      setIsLoading(true)

      try {

        const res = await axiosClient.get(`/get-announce/${ref}`);

        if (res.status === 200) {
          setAnnounce(res.data);
          setQuantite(res.data.min_command)
        }

      } catch (error) {
        console.log(error);
      }

      setIsLoading(false)

    })();

  }, [ref]);

  const miunsQuantity = () => {

    setQuantite(pre => {

      if(pre - 1 >= Announce?.min_command){
        return pre - 1
      }

      return pre

    }) 
  }

  const addQuantity = () => {

    setQuantite(pre => {

      if(pre + 1 <= Announce?.stock_dispo){
        return pre + 1
      }

      return pre

    }) 
  }

  const order = async (event) => {
    event.preventDefault()
    const token = localStorage.getItem('token')

    if (!token) {
          
      sessionStorage.setItem('redirectPath', window.location.pathname);

      toast.error('Vous serez invité à vous connecter')

      setTimeout(() => {
        navigate('/auth/login')
        return;
      }, 1500);

    }

    try {
      
      const res = await axiosClient.post(`/commands`, {
        product_id : Announce.ref,
        quantity : Quantite
      })

      if(res.status == 201){

        toast.success('Votre commande a été enregistrée. Vous pouvez vous rendre dans le panneau de controle pour suivre vos commandes', {
          duration: 2500,
        })

        setAnnounce({
          ...Announce,
          stock_dispo : Announce.stock_dispo - Quantite
        })

      }

    } catch (error) {
      
      if(error?.response?.status == 422){
        toast.error(
          error.response.data.message || 
          error.response.data.quantity || 'Les information no valide')
      }

    }

  }

  return (
    <>
      <div>
        <NavLink
          step_1="Accuiel"
          step_2={Announce?.category || null}
          step_3={Announce?.title || null}
        />
      </div>

      <div id="primary" className="content-area">
            <main id="main" className="site-main">
              <div className="woocommerce-notices-wrapper"></div>
              {
                IsLoading ? <Loading style={{padding: '150px 0px',}}/> 
                :

                (
                  <div
                    id="product-2933"
                    className="product type-product post-2933 status-publish first instock product_cat-materiel-electrique-electronique-optique has-post-thumbnail shipping-taxable purchasable product-type-simple"
                  >
                    <div className="single-product-wrapper row">
                      <div className="product-images-wrapper">
                        <div
                          className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-5 images electro-carousel-loaded"
                          data-columns="5"
                          style={{
                            opacity: 1,
                            transition: "opacity 0.25s ease-in-out",
                          }}
                        >
                          <a href="#" className="woocommerce-product-gallery__trigger">
                            <img
                              draggable="false"
                              role="img"
                              className="emoji"
                              alt="🔍"
                              src="https://s.w.org/images/core/emoji/15.0.3/svg/1f50d.svg"
                            />
                          </a>
                          <div className="parent-image-container woocommerce-product-gallery__wrapper">
                            <ImagesComponent photos={Announce?.photo}/>
                          </div>
                        </div>
                      </div>
        
                      <div className="summary entry-summary">
                        <span className="loop-product-categories">
                          <a
                            href="https://www.b2bmaroc.com/product-category/materiel-electrique-electronique-optique/"
                            rel="tag"
                          >
                            {Announce?.category}
                          </a>
                        </span>
                        <h1 className="product_title entry-title">{Announce?.title}</h1>


                        {
                          (Announce?.min_command && Announce.stock_dispo) && (
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}>
    
                              <div className="availability">
                                Quantité minimale:{" "}
                                <span className="electro-stock-availability">
                                  <p className="stock in-stock">{Announce?.min_command}</p>
                                </span>
                              </div>
    
                              <div className="availability">
                                Quantité disponible:{" "}
                                <span className="electro-stock-availability">
                                  <p className="stock in-stock">{Announce?.stock_dispo}</p>
                                </span>
                              </div>
    
                            </div>
                          ) 
                        }

        
                        <hr className="single-product-title-divider" />
                        
                        {
                          auth && <ActionListSouhaits announce_ref={Announce?.ref} is_added_status={Announce?.in_wishlist} />
                        }
        
                        <p className="price">
                          <span className="electro-price">
                            <span className="woocommerce-Price-amount amount">
                              <bdi>

                                {
                                  Announce?.price ? 
                                    <>
                                      {Announce?.price}{" "}
                                      <span className="woocommerce-Price-currencySymbol">
                                        DH
                                      </span>
                                    </>
                                  :
                                  <p>À Convenir</p>
                                }

                              </bdi>
                            </span>
                          </span>
                        </p>

                        <div className="woocommerce-product-details__short-description">
                          {Announce?.description}
                        </div>

                        {

                          (Announce?.min_command && Announce.stock_dispo) && (
                            <div
                              style={{
                                display:'flex',
                                alignItems : 'center',
                                gap : '10px',
                                marginBottom : '20px'
                              }}
                            >
                              
                              <p 
                                onClick={miunsQuantity}
                                style={{
                                color : 'red',
                                marginBottom : 0,
                                cursor : 'pointer',
                                fontSize : '20px'
                              }}
                              >-</p>
    
                              <input 
                                type='number'
                                style={{
                                  border: '1px #808080ab solid',
                                  borderRadius: '13px',
                                  textAlign : 'center'
                                }}
                                readOnly
                                min={Announce.min_command}
                                max={Announce.stock_dispo}
                                value={Quantite}
                              />
    
                              <p 
                                onClick={addQuantity}
                                style={{
                                  color : 'green',
                                  marginBottom : 0,
                                  cursor : 'pointer',
                                  fontSize : '20px'
                                }}
                              >+</p>
    
                            </div>
                          )

                        }

                        <div style={{
                          display : 'flex',
                          alignItems:'center',
                          gap:'15px'
                        }}>

                          <button
                            style={{
                              padding: '.594rem 1rem',
                              fontSize: '.875rem',
                              borderRadius: '1.375rem',
                              background : '#1f384b',
                              color : 'white'
                            }}
                            onClick={(e) => order(e)}
                          >
                            Commander
                          </button>
                          <Link
                            style={{
                              padding: '.594rem 1rem',
                              fontSize: '.875rem',
                              borderRadius: '1.375rem',
                              background : '#fba707',
                              color : 'white'
                            }}
                            to={`/fournisseurs/${Announce?.company_id}`}
                          >
                            Contacter le Fournisseur
                          </Link>

                        </div>

                      </div>
                    </div>
                    {( Announce?.category && Announce?.ref ) && (
                      <RelatedProducts 
                        category_name={Announce?.category} 
                        AnnounceRef={Announce?.ref}
                      />
                    )}

                  </div>
                )
              }
            </main>
      </div>

      <SideAnnonceDetails />
    </>
  );
};

export default AnnounceDetails;
