import React, {useState, useEffect, useRef} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ContentInfo, HeaderInfo, Loading } from '../../components'
import { axiosClient } from '../../constants/axionsClient'

const Fournisseur = () => {

  const { number } = useParams();
  const navigate = useNavigate()
  const [companyInfo, setCompanyInfo] = useState({})
  const [IsLoading, setIsLoading] = useState(true)
  const [TopProductsCount, setTopProductsCount] = useState(0)
  const [infoIsHidden, setInfoIsHidden] = useState(true)

  useEffect(() => {

    (async() => {

      setIsLoading(true)
      try {
        
        const res = await axiosClient.get(`/companies/initial-info/${number}`);

        if(res.status == 200){

          setCompanyInfo(res?.data)

        }

      } catch (error) {
        
        console.log(error);

      }
      setIsLoading(false)

    })()

  }, [])

  useEffect(() => {
    window.scrollTo({ top : 0, behavior : 'smooth' })
  }, [])

  if(IsLoading) return <Loading style={{
    padding: '150px 0',
  }}/> 

  const contactFournisseur = async (e, id) => {
        
      e.preventDefault()

      const token = localStorage.getItem('token');

      if (!token) {
          
          sessionStorage.setItem('redirectPath', window.location.pathname);

          navigate('/auth/login')
          return;

      }

      try {
          
          const res = await axiosClient.get(`/companies/${id}`)

          if(res.status == 200){
            setCompanyInfo(res?.data)
            setInfoIsHidden(false)
          }

      } catch (error) {
        
        console.log(error);

      }

  }

  return (
    <>
      
      <HeaderInfo contactFournisseur={contactFournisseur} TopProductsCount={TopProductsCount} information={companyInfo} />

      <ContentInfo infoIsHidden={infoIsHidden} setTopProductsCount={setTopProductsCount} number={number} companyInfo={companyInfo} />

    </>
  )

}

export default Fournisseur