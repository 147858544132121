import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import articles from "./articlesData";

const Blog1 = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const index = parseInt(id) - 1;

  const article = articles[index];

  if (!article) {
    return <div>Article non trouvé</div>;
  }

  const goToPrevious = () => {
    if (index > 0) {
      navigate(`/blog/${index}`);
    }
  };

  const goToNext = () => {
    if (index < articles.length - 1) {
      navigate(`/blog/${index + 2}`);
    }
  };

  return (
    <div className="blog1-container">
      <nav className="blog1-nav">
      <Link to="/blog" className="blog1-link">
        Accueil >
      </Link>
        <span className="blog1-category">{article.category} ></span>
        <span className="blog1-title-nav">{article.title}</span>
      </nav>

      <img
        src={article.imageUrl}
        alt={article.title}
        className="blog1-image"
      />
      <h1 className="blog1-title">{article.title}</h1>
      <p className="blog1-meta">
        {article.category} • {article.date}
      </p>
      <div className="blog1-divider"></div>
      <div
        className="blog1-content"
        dangerouslySetInnerHTML={{ __html: article.content }}
      ></div>

      <div className="blog1-navigation">
        <button
          onClick={goToPrevious}
          disabled={index === 0}
          className={`blog1-button ${
            index === 0 ? "blog1-button-disabled" : ""
          }`}
        >
          ← Précédent
        </button>
        <button
          onClick={goToNext}
          disabled={index === articles.length - 1}
          className={`blog1-button ${
            index === articles.length - 1 ? "blog1-button-disabled" : ""
          }`}
        >
          Suivant →
        </button>
      </div>
    </div>
  );
};

export default Blog1;
