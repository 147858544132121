import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Loading } from "../";
import { axiosClient } from "../../constants/axionsClient";
import { logo } from "../../constants/images";

const Seller = ({item}) => {

  const {id, photo, name} = item

  return (
    <li key={id}>
      <Link style={{width:'100%'}} to={`/fournisseurs/${id}`}>
        <img
          width="1"
          height="1"
          src={photo || logo}
          className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
          alt=""
          decoding="async"
          loading="lazy"
        />
        <span className="product-title">{name}</span>
      </Link>
    </li>
  );
};

const TopSellers = () => {

  const [sells, setSells] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);

  useEffect(()=>{

    (async()=>{

      try {
        
        const res = await axiosClient.get(`/companies/recommanded-companies`)

        if(res.status === 200){

          setSells(res.data?.data)

        }

      } catch (error) {
        console.log(error);
      }

      setIsLoading(false)

    })()

  }, [])

  return (
    <aside
      id="woocommerce_products-2"
      className="widget woocommerce widget_products"
    >
      <h3 className="widget-title">Meilleurs Fournisseurs</h3>

      {IsLoading && (
        <Loading
          style={null}
          loadingStyle={{
            width: "40px",
          }}
        />
      )}

      <ul className="product_list_widget">
        {sells?.map((item) => (
          <Seller item={item} />
        ))}
      </ul>
    </aside>
  );
};

export default TopSellers;
