import React, { useState, useEffect } from 'react';
import { baseRoute } from '../../constants/env';

const CityFilter = ({
    ville,
    setVille,
    handleSubmit,
    hundelReset,
    setSelectedCountry
}) => {

    const [CountriesAndCities, setCountriesAndCities] = useState([])
    const [countries, setCountries] = useState([])
    const [cities, setCities] = useState([])

    useEffect(() => {

        (async () => {
    
        try {
            
            const response = await fetch(`/assets/filtered_countries.json`);
            const data = await response.json();
            setCountriesAndCities(data);
            const countryNames = data.map((country) => country.country_name);
            setCountries(countryNames);
    
        } catch (error) {
    
            console.error('Error fetching countries:', error);
            return [];
            
        }
    
        })()
    
    }, [])

    const hundelChange = (e) => {

        if(e.target.value != ''){

            setSelectedCountry(e.target.value)
            setCities(
                CountriesAndCities.filter(item => item.country_name == e.target.value)[0]?.cities
            )

        }

    }

  return (
    <aside id="woocommerce_price_filter-1" className="widget woocommerce widget_price_filter">
      <h3 className="widget-title">Ville</h3>
      <form onSubmit={(e) => handleSubmit(e, 'ville')}>
        <div className="price_slider_wrapper">
          <div style={{width:'100%'}} className="price_slider_amount" data-step="10">
            <label className="screen-reader-text" htmlFor="min_price">Select payer</label>
            <select
                style={{
                    padding: '.594rem 1rem',
                    marginBottom : '10px'
                }}
                onChange={(e) => hundelChange(e)}
            >
                <option value=''>Select payer</option>
                {
                    countries?.map(item => (
                        <option value={item}>{item}</option>
                    ))
                }
            </select>
            <label className="screen-reader-text" htmlFor="max_price">Select ville</label>
            <select
                style={{
                    padding: '.594rem 1rem',
                    marginBottom : '10px'
                }}
                value={ville}
                onChange={(e) => setVille(e.target.value)}
            >
                <option value=''>Select ville</option>
                {
                    cities?.map(item => (
                        <option value={item}>{item}</option>
                    ))
                }
            </select>
            <div style={{
              display: 'flex',
              flexWrap : 'wrap',
              marginBottom : '5px',
              gap : '5px'
            }}>

              <button type="submit" className="button">Filtrer</button>
              <button onClick={(e) => hundelReset(e, 'ville')} className="button">Reset</button>

            </div>
            <div className="clear"></div>
          </div>
        </div>
      </form>
    </aside>
  );
};

export default CityFilter;
