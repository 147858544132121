import React, { useState, useEffect } from "react";
import { axiosClient } from "../../constants/axionsClient";
import { AnnoncesAndOffres, Hero } from "../../components";
import { Link } from "react-router-dom";

const Home = () => {

  const [annonces, setAnnonces] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    (async () => {
      try {
        const res = await axiosClient.get(
          `/get-announcements/${currentPage}?sortWith=default`
        );
        if (res.status === 200) {
          setAnnonces(res?.data?.announcements);
          document.body.scrollIntoView({ behavior: 'smooth' });
        }

      } catch (error) {

        console.log(error);

      }

      setIsLoading(false)

    })();

  }, []);

  useEffect(() => {
    document.body.removeAttribute("class");
    document.body.classList.add(
      "home",
      "page-template-default",
      "page-id-79",
      "page",
      "wp-custom-logo",
    );

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove(
        "home",
        "page",
        "page-template-default",
        "page",
        "page-id-79",
        "wp-custom-logo",
      );
    };
  }, []);   

  return (
    <div id="primary" className="content-area">
      <main id="main" className="site-main">
        <Hero />

        <div className="row mt-5">
          <div className="col">
            <section className="w-100 mb-0">
              <header className="mb-0 pb-4">
                <h2 className="h1">Annonces et Offres</h2>
              </header>
              <div className="products-list-inner">
                <div className="products-block">
                  <div className="woocommerce columns-1">
                    
                    <AnnoncesAndOffres isLoading={isLoading} annonces={annonces} />

                    {
                      (annonces?.length > 0 && !isLoading) && (
                        
                        <div className="row">
                          <div className="pb-3 mt-3 text-center">
                            <Link
                              to="/annonces-offres"
                              className="button bg-warning"
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                padding: "15px",
                                boxShadow: "0px 0px 5px -3px #FBA707",
                                border: "none",
                                color : 'white'
                              }}
                            >
                              Voir plus de produits ou Services{" "}
                              <i className="vc_btn3-icon vc-material vc-material-arrow_forward"></i>
                            </Link>
                          </div>
                        </div>

                      )
                    }

                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;