import React from "react";
import { SwiperAnnonces, CompanyAnnonces } from "../";
import { premiumImg } from "../../constants/images";

const BusinessCard = ({ companyInfo }) => {
  return (
    <div className="profile-info-summery-wrapper dokan-clearfix">
      <div style={{ gap: 0 }} className="profile-info-summery">
        <div className="profile-info-head row">
          <div className="col-10">
            <h1 className="store-name mb-3" id="entreprise-nom-2">
              {companyInfo?.name} <img src={premiumImg} alt="premuim" title="premuim" width='30' />
            </h1>
          </div>
          <div className="col-2 profile-img profile-img-circle">
            <img src={companyInfo.plan} alt="plan" title={companyInfo.type} size="160" />
          </div>
        </div>
        <div className="profile-summery-info-buttons-wrapper">
          <div className="profile-summery-info-wrapper">
            <div className="profile-info">
              <ul className="dokan-store-info">
                <li className="dokan-store-address" id="address-store">
                  <i className="fas fa-map-marker-alt"></i>
                  <span className="street_1 street_2" id="entreprise-adresse">
                    {companyInfo.address}
                  </span>
                  ,
                  <span className="city" id="entreprise-codepostal">
                    {companyInfo.zip_code}
                  </span>{" "}
                  -
                  <span className="city" id="entreprise-ville">
                    Aïn Dorij
                  </span>
                  .
                  <span className="country" id="entreprise-pays">
                    {companyInfo.country}
                  </span>
                </li>
                <br></br>
                <li className="dokan-store-phone" id="phone-store" style={{display: 'block', width: '100%'}}>
                  <i className="fas fa-phone"></i>
                  <a href="#">
                    <strong>Tél:</strong>{" "}
                    <span id="entreprise-tel">{companyInfo?.phone}</span>
                  </a>
                </li>
                <br></br>
                <li className="dokan-store-phone" style={{display: 'block', width: '100%'}} id="mail-store">
                  <i className="fas fa-inbox"></i>
                  <a href="#" id="entreprise-mail">
                    <strong>Email :</strong> {companyInfo?.email}
                  </a>
                </li>
                {companyInfo?.website && (
                  <li className="dokan-store-phone" id="mail-store">
                    <i className="fas fa-globe"></i>
                    <a href="#" id="entreprise-mail">
                      <strong className="me-2">Web :</strong>{" "}
                      {companyInfo?.website}
                    </a>
                  </li>
                )}
                {companyInfo?.facebook_link && (
                  <li className="dokan-store-phone" id="mail-store">
                    <i className="fab fa-facebook"></i>
                    <a href="#" id="entreprise-mail">
                      <strong className="me-2">Facebook :</strong>{" "}
                      {companyInfo?.facebook_link}
                    </a>
                  </li>
                )}
                {companyInfo?.instagram_link && (
                  <li className="dokan-store-phone" id="mail-store">
                    <i className="fab fa-instagram"></i>
                    <a href="#" id="entreprise-mail">
                      <strong className="me-2">Instagram :</strong>{" "}
                      {companyInfo?.instagram_link}
                    </a>
                  </li>
                )}
              </ul>
              <div className="store-social-wrapper" id="social-store"></div>
              <div className="dokan-store-support-and-follow-wrap"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HiddenBusinessCard = () => {
  return (
    <div className="profile-info-summery-wrapper dokan-clearfix">
      <div style={{ gap: 0, padding: '20px'}} className="profile-info-summery">
        <p>Veuillez cliquer sur <b>Contactez ce fournisseur</b> pour voir les informations de contact.</p>
      </div>
    </div>
  );
};

const StorePresentation = ({ companyInfo, infoIsHidden }) => {

  return (
    <div className="row mb-4">
      <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
        <section className="w-100 mb-0">
          <header className="mb-4">
            <h2 className="h1">Présentation</h2>
          </header>
          <div className="wpb_text_column wpb_content_element">
            <div className="wpb_wrapper">
              <p className="text-justify" style={{ lineHeight: "2.5em" }}>
                {companyInfo.description}
              </p>
            </div>
          </div>
        </section>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12" id="presentation">
        <div
          className="business-card"
          style={{
            borderTopWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "5px",
            borderLeftWidth: "1px",
            backgroundColor: "#fcfcfc",
            borderLeftColor: "#dbbb4d",
            borderLeftStyle: "solid",
            borderRightColor: "#dbbb4d",
            borderRightStyle: "solid",
            borderTopColor: "#dbbb4d",
            borderTopStyle: "solid",
            borderBottomColor: "#dbbb4d",
            borderBottomStyle: "solid",
            borderRadius: "5px",
          }}
        >
            {
                infoIsHidden ? <HiddenBusinessCard /> : <BusinessCard companyInfo={companyInfo} />
            }

        </div>
      </div>
    </div>
  );
};

const ContentInfo = ({ infoIsHidden, number, companyInfo, setTopProductsCount }) => {
  return (
    <div className="container">
      <StorePresentation infoIsHidden={infoIsHidden} companyInfo={companyInfo} />

      <SwiperAnnonces
        header="Pinned Products"
        RequetsPath={`/pinnded-announces/${number}`}
      />

      <div id="top-produits">
        <SwiperAnnonces
          header="Top Products"
          setTopProductsCount={setTopProductsCount}
          RequetsPath={`/top-announces/${number}`}
        />
      </div>

      <CompanyAnnonces number={number} />
    </div>
  );
};

export default ContentInfo;
