import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { isLogged, logout, redirectTo } from "../constants/AuthFunctions";
import { dashboardRoute } from "../constants/env";

const TopBar = () => {

  const [auth, setAuth] = useState(isLogged());

  useEffect(() => {
      setAuth(isLogged());
  }, []);

  const Logout = (e) => {
      e.preventDefault()
      logout(e)
  }


  return (
    <div className="top-bar top-bar-v4 bg-light border-0 hidden-lg-down d-none d-xl-block">
      <div className="container clearfix">
        <ul
          id="menu-top-bar-left"
          className="nav nav-inline float-start electro-animate-dropdown flip"
        >
          <li
            id="menu-item-3233"
            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3233"
          >
            <a
              title="Le site référence de mise en relation entre les professionnels au Maroc et à l'international"
              href="#"
            >
              Le site référence de mise en relation entre les professionnels au
              Maroc et à l’international
            </a>
          </li>
        </ul>
        <ul
          id="menu-top-bar-right"
          className="nav nav-inline float-end electro-animate-dropdown flip"
        >
          {
            auth ? (
              <>
                <li 
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5413"
                  onClick={(e) => redirectTo(e)}
                >
                  <a href="#">
                    Tableau de board
                  </a>
                </li>
                <li 
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5413"
                  onClick={(e) => Logout(e)}
                >
                  <a href="#">
                  Déconnexion
                  </a>
                </li>
              </>
            )

            :

            (

              <>
                <li
                  id="menu-item-5413"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5413"
                >
                  <Link title="Connexion" to="/auth/login">
                    Connexion
                  </Link>
                </li>
                <li
                  id="menu-item-5414"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5414"
                >
                  <a
                    title="Rejoindre gratuitement"
                    href={`${dashboardRoute}/register`}
                  >
                    Rejoindre gratuitement
                  </a>
                </li>
              </>
            )
          }
        </ul>{" "}
      </div>
    </div>
  );
};

export default TopBar;
