import React, { useState, useRef, useEffect } from 'react';
import { AnnouncesOption, FilterSide, NavLink } from '../../components';
import { axiosClient } from '../../constants/axionsClient';

const Wishlist = () => {
    const CompRef = useRef()

    const [annonces, setAnnonces] = useState([])
    const [minPrice, setMinPrice] = useState(30);
    const [maxPrice, setMaxPrice] = useState(3000);
    const [ville, setVille] = useState('')

    const [isFiltred, setIsFiltred] = useState(false)
    const [isCityFiltred, setIsCityFiltred] = useState(false)

    const [ItemsPerPage, setItemsPerPage] = useState(2)
    
    const [totalPages, setTotalPages] = useState(0)
    const [TotalResults, setTotalResults] = useState(0)

    const [IsLoading, setIsLoading] = useState(true)
    const [Scrolling, setScrolling] = useState(false)

    useEffect(() => {
        
        if (CompRef.current && Scrolling) {
            CompRef.current.scrollIntoView({ behavior: 'smooth' });
            setScrolling(false)
        }

    }, [Scrolling])

    const handleSubmit = (event, type) => {
        event.preventDefault();

        if(type === 'price'){

            if(maxPrice < minPrice){
    
                setMinPrice(maxPrice)
                setMaxPrice(minPrice)
    
            }
    
            setIsFiltred(true)
            getAnnonces(
                maxPrice < minPrice ? minPrice : maxPrice, 
                minPrice > maxPrice ? maxPrice : minPrice, 
                isCityFiltred ? ville : '',
                'default' , 1
            )

        }

        if(type === 'ville'){

            getAnnonces(
                isFiltred ? minPrice : '', 
                isFiltred ? maxPrice : '', 
                ville,
                'default' , 1
            )
            setIsCityFiltred(true)

        }

    };

    const hundelReset = (event, type) => {

        event.preventDefault();

        if(type === 'ville'){

            setVille('')
            setIsCityFiltred(false)

            getAnnonces(
                isFiltred ? maxPrice : '', 
                isFiltred ? minPrice : '', 
                '',
                'default' , 1
            )
            

        }

        if(type === 'price'){
            setMaxPrice(0)
            setMinPrice(0)
            setIsFiltred(false)

            getAnnonces(
                '', 
                '', 
                isCityFiltred ? ville : '',
                'default' , 1
            )

        }

    };

    const getAnnonces = async (
        max_price = '', min_price = '', ville = '', sortWith = 'default', CurrentPage
    ) => {

        setIsLoading(true)

        try {

            const res = await axiosClient.get(`/wishlist/${CurrentPage}?ville=${ville}&sortWith=${sortWith}&min_price=${min_price}&max_price=${max_price}`)

            if(res.status === 200){

                setAnnonces(res?.data?.announcements)
                setTotalPages(res?.data?.total_pages)
                setTotalResults(res?.data?.totalResults)

            }

        } catch (error) {
            console.log(error);
        }

        setIsLoading(false)

    }

  return (
    <>
      <div>
        <NavLink step_1="Accuiel" step_2='liste de souhaits' />
      </div>
      <div className="site-content-inner row">

          <div id="primary" className="content-area">

              <AnnouncesOption
                  getAnnonces={getAnnonces}
                  header='liste de souhaits'
                  annonces={annonces}
                  TotalResults={TotalResults}
                  totalPages={totalPages}
                  isFiltred={isFiltred}
                  maxPrice={maxPrice}
                  minPrice={minPrice}
                  ItemsPerPage={ItemsPerPage}
                  IsLoading={IsLoading}
                  CompRef={CompRef}
                  setScrolling={setScrolling}
                  RecommondedProducts={false}
              />

          </div>

          <FilterSide
              maxPrice={maxPrice}
              minPrice={minPrice}
              setMinPrice={setMinPrice}    
              setMaxPrice={setMaxPrice}    
              handleSubmit={handleSubmit}
              hundelReset={hundelReset}
              ville={ville}
              setVille={setVille}
          />

      </div>
    </>
  )
}

export default Wishlist