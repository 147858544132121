import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { axiosClient } from '../constants/axionsClient';
import { baseRoute } from '../constants/env';
import { loginImg } from '../constants/images';

const Login = () => {
    
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

    const hundelClick = async (e) => {

        e.preventDefault()
        setIsLoading(true)
        try {
            
            const res = await axiosClient.post("login", { 
                email : email, 
                password : password 
            })

            if(res.status == 200){

                const redirectedPath = sessionStorage.getItem('redirectPath');

                localStorage.setItem('user', JSON.stringify(res.data.user))
                localStorage.setItem('token', res.data.access_token)

                window.location.href = redirectedPath || baseRoute

            }

        } catch (err) {
          
            if (err.response && err.response.status === 422) {
                setErrors(
                    Object.values(err.response.data.errors).map((item) => item[0])
                );

            } else if(err.response && err.response.status === 401) {

              setErrors([err.response.data.message])

            } else {
                setErrors([]);
            }

        }
        setIsLoading(false)

    }

    if(user){

        return navigate(
            JSON.parse(localStorage.getItem('isAdmin')) ? 'dashboard' : 'company'
        )
        
    }

  return (
        <div className="login-container">
          <div className="left-section" style={{backgroundColor: '#f5a741'}}>
            <img src={loginImg} alt="Illustration" />
          </div>
          <div className="right-section">
            <div className="content-wrapper">
              <div className="text-center">
                <h2 style={{
                    color: '#333e48'
                }}>Bienvenue</h2>
              </div>
              <div className="form-group text-center">
                <p>Connectez-vous à votre compte</p>
              </div>
              <form onSubmit={hundelClick}>
                <input
                    className="form-group"
                    label="email"
                    type="email"
                    name="email"
                    placeholder='Email'
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                />
                <input
                    className="form-group"
                    label="Mot de passe"
                    type="password"
                    name="password"
                    placeholder='Password'
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                />
                <button disabled={isLoading} type="submit" className="button-submit">
                    Connecter
                </button>
                
                {
                    errors.length > 0 && (
                        <ul className='errors-bar'>
                            {
                                errors?.map((item, key) => (
                                    <li key={key} >{item}</li>
                                ))
                            }
                        </ul>
                    )
                }
              </form>
              <div className="link-container">
                Vous n'avez pas de compte?{" "}
                <span className="link" onClick={() => navigate("/register")}>
                  Rejoignez-nous
                </span>
              </div>
              <div className="link-container">
                <a href="#" className="link">Mot de passe oublié?</a>
              </div>
            </div>
          </div>
        </div>
  )
}

export default Login