import React from "react";

const SearchBarLaptop = ({
    hundelSearch,
    setSearchQuery,
    setTypeSearch,
    SearchQuery,
    TypeSearch
}) => {
    

  return (
    <form
      className="navbar-search col"
      autocomplete="off"
      onSubmit={(e) => hundelSearch(e)}
    >
      <label
        className="sr-only screen-reader-text visually-hidden"
        for="search"
      >
        Search for:
      </label>

      <div className="input-group">

        <div className="input-search-field">
          <input
            type="text"
            id="search"
            className="form-control search-field product-search-field"
            dir="ltr"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={SearchQuery}
            name="s"
            placeholder="Qu&#039;est-ce que vous recherchez..."
            autocomplete="off"
          />
        </div>

        <div className="input-group-addon search-categories d-flex">
          <select
            name="product_cat"
            id="electro_header_search_categories_dropdown"
            className="postform resizeselect"
            defaultValue={TypeSearch}
            onChange={(e) => setTypeSearch(e.target.value)}
          >
            <option value='products'>
              Produits
            </option>
            <option className="level-0" value="companies">
              Fournisseurs
            </option>
          </select>
        </div>

        <div className="input-group-btn">
          <button type="submit" className="btn btn-secondary">
            <i className="ec ec-search"></i>
          </button>
        </div>
      </div>
    </form>
  );
}

export default SearchBarLaptop