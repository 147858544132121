import React from 'react'
import { redirectTo } from '../../constants/AuthFunctions';

const GoldenPlan = ({plan}) => {
  
    return (
        <div
          id="main_column_7"
          data-has_custom_column_width="false"
          data-order="main_column_1"
          className="arp_none ArpPricingTableColumnWrapper arpricemaincolumn style_column_7 hover_effect no_animation arp_style_1"
          style={{ height: '1905px', marginBottom: '10px' }}
          data-column-footer-position="0"
          has_column_highlighted="false"
        >
          <div className="arpplan is_column_clickable column_1 arp_col_toggle_selected arp_col_active_toggle arp_col_toggle_first_step">
            <div className="planContainer">
              <div className="arp_column_content_wrapper">
                <div className="arpcolumnheader">
                  <div className="arppricetablecolumntitle">
                    <div
                      className="bestPlanTitle package_title_first toggle_step_first"
                      style={{ height: '80px' }}
                    >
                      Gold
                    </div>
                  </div>
                  <div className="arppricetablecolumnprice arp_default">
                    <div
                      className="arp_price_wrapper"
                      data-column="main_column_7"
                      style={{ height: '100px' }}
                    >
                      <span className="price_text_first_step toggle_step_first">
                        <span className="arp_price_value">{plan?.price} <span className="arp_currency">DH</span></span>
                        <span className="arp_price_duration" style={{ fontSize: '18px', fontWeight: 'normal' }}>
                        HT / an
                        </span>
                    </span>
                    </div>
                    <div
                      className="column_description column_description_first_step toggle_step_first"
                      data-column_name="main_column_7"
                      data-template_id="arptemplate_11"
                      data-type="other_columns_buttons"
                      data-level="column_description_level"
                      style={{ height: '55px' }}
                    >
                      Badge Premium et badge vérifié optionnel
                    </div>
                    <div className="arpcolumnfooter">
                      <div
                        className="arppricetablebutton toggle_step_first"
                        style={{ textAlign: 'center', height: '55px' }}
                      >
                        <button
                          type="button"
                          className="arp_price_tbl_btn_column_7 bestPlanButton arp_shadow_button toggle_step_first"
                          data-is-post-variables=""
                          data-post-variables=""
                          //onClick={() =>
                            //arp_redirect(
                              //'29112',
                              //'https://premium.b2bmaroc.com/produit/pack-gold/',
                              //'0',
                              //'0',
                              //'0',
                              //this,
                              //'102',
                              //'main_column_7'
                           // )
                          //}
                          onClick={(e) => redirectTo(e)}
                        >
                          <span className="btn_content_first_step bestPlanButton_text">
                            Commander maintenant
                          </span>
                        </button>
                        <div
                          className="arp_paypal_form"
                          id="paypal_form_first_column_7"
                          style={{ display: 'none' }}
                        ></div>
                      </div>
                      <div
                        className="arp_footer_content arp_btn_after_content"
                        style={{ display: 'none' }}
                      >
                        <span className="footer_content_first_step arp_footer_content_text toggle_step_first"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="arpbody-content arppricingtablebodycontent">
                  <ul
                    className="arp_opt_options arppricingtablebodyoptions"
                    id="1"
                    style={{ textAlign: 'left' }}
                  >
                    {[
                      'Badge Premium, badge Gold et badge vérifié optionnel',
                      'Top Référencement sur le site',
                      'Top Référencement par catégorie/sous-catégorie',
                      `Catalogue de ${plan?.max_announcements || 'illimités'} produits et services`,
                      'Votre page entreprise visible par tous les utilisateurs',
                      'Votre Fiche, Contact et site web visibles par tous les utilisateurs',
                      'Votre nom d’entreprise en sous domaine du site',
                      'Prospection illimitée au sein de la plateforme',
                      `Mise à jour de ${plan?.max_update || 'illimités'} produits ou services en top de la liste des offres et annonces`,
                      `Mettre ${plan?.max_pinned_announcements || 'illimités'} produits ou services favoris au premier plan dans votre page`,
                      'Aide à la prospection',
                      'Aide à la configuration de votre page entreprise',
                      'Publication des annonces d’achat ou de vente illimités',
                      'Possibilité de publier une annonce confidentielle d’achat ou de vente pour vous protéger face à la concurrence',
                      'Publication des milliers de produits ou services en un seul clic à l’aide d’un fichier Excel CSV',
                      'Une photo cover personnalisable dans votre page entreprise',
                      'Pack Export inclus (si cela fait partie de votre activité)',
                      'Statistiques avancées de vos opérations',
                      'Voir qui a visité votre page',
                      'Liste de souhait des produits et services',
                      'Création gratuite de votre compte et votre page',
                    ].map((item, index) => (
                      <li
                        key={index}
                        className={`${
                          index % 2 === 0 ? 'arp_odd_row' : 'rowlightcolorstyle arp_even_row'
                        } arptemplate_11_column_7_row_${index}`}
                        id={`arp_column_7_row_${index}`}
                        style={{ height: '70px' }}
                      >
                        <span
                          data-isblank=""
                          className="row_description_first_step arp_row_description_text toggle_step_first"
                          data-tipso=""
                        >
                          {item}
                        </span>
                      </li>
                    ))}
                    <li className="arp_last_list_item rowlightcolorstyle arp_even_row"></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

}

const DiamantPlan = ({plan}) => {
    return (
        <div id="main_column_8" 
          data-has_custom_column_width="false" 
          data-order="main_column_2" 
          className="arp_none ArpPricingTableColumnWrapper arpricemaincolumn style_column_8 hover_effect no_animation arp_style_2" 
          style={{ height: '1905px', marginBottom: '10px' }} 
          data-column-footer-position="0" 
          has_column_highlighted="false">
        
        <div className="arpplan is_column_clickable column_1 arp_col_toggle_selected arp_col_active_toggle arp_col_toggle_first_step">
          <div className="planContainer">
            <div className="arp_column_content_wrapper">
              <div className="arpcolumnheader">
                <div className="arppricetablecolumntitle">
                  <div className="bestPlanTitle package_title_first toggle_step_first" style={{ height: '80px' }}>
                    Diamant
                  </div>
                </div>
                <div className="arppricetablecolumnprice arp_default">
                  <div className="arp_price_wrapper" data-column="main_column_8" style={{ height: '100px' }}>
                    <span className="price_text_first_step toggle_step_first">
                      <span className="arp_price_value">{plan?.price} 3000<span className="arp_currency">DH</span></span>
                      <span className="arp_price_duration" style={{ fontSize: '18px', fontWeight: 'normal' }}>
                        HT / an
                      </span>
                    </span>
                  </div>
                  <div className="column_description column_description_first_step toggle_step_first" 
                      data-column_name="main_column_8" 
                      data-template_id="arptemplate_11" 
                      data-type="other_columns_buttons" 
                      data-level="column_description_level" 
                      style={{ height: '55px' }}>
                    Badge Diamant, badge Premium et badge vérifié optionnel
                  </div>
                  <div className="arpcolumnfooter">
                    <div className="arppricetablebutton toggle_step_first" style={{ textAlign: 'center', height: '55px' }}>
                      <button 
                        type="button" 
                        className="arp_price_tbl_btn_column_8 bestPlanButton arp_shadow_button toggle_step_first" 
                        data-is-post-variables="" 
                        data-post-variables="" 
                       // onClick={() => arp_redirect("29112", "https://premium.b2bmaroc.com/produit/pack-diamant/", "0", "0", "0", this, "102", "main_column_8")}>
                       onClick={(e) => redirectTo(e)}
        >
                        <span className="btn_content_first_step bestPlanButton_text">
                          Commander maintenant
                        </span>
                      </button>
                      <div className="arp_paypal_form" id="paypal_form_first_column_8" style={{ display: 'none' }}></div>
                    </div>
                    <div className="arp_footer_content arp_btn_after_content" style={{ display: 'none' }}>
                      <span className="footer_content_first_step arp_footer_content_text toggle_step_first"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="arpbody-content arppricingtablebodycontent">
                <ul className="arp_opt_options arppricingtablebodyoptions" id="1" style={{ textAlign: 'left' }}>
                  {[
                    "Badge Premium, badge Diamant unique et badge vérifié optionnel",
                    "Référencement optimal sur le site",
                    "Référencement optimal par catégorie/sous-catégorie",
                    `Catalogue de ${plan?.max_announcements || 'illimités'} produits et services`,
                    "Votre page entreprise visible par tous les utilisateurs",
                    "Votre Fiche, Contact et site web visibles par tous les utilisateurs",
                    "Votre nom d’entreprise en sous domaine du site",
                    "Prospection illimitée au sein de la plateforme",
                    `Mise à jour de ${plan?.max_update || 'illimités'} produits ou services en top de la liste des offres et annonces`,
                    `Mettre ${plan?.max_pinned_announcements || 'illimités'} produits ou services favoris au premier plan dans votre page`,
                    "Aide à la prospection",
                    "Aide à la configuration de votre page entreprise",
                    "Publication des annonces d’achat ou de vente illimités",
                    "Possibilité de publier une annonce confidentielle d’achat ou de vente pour vous protéger face à la concurrence",
                    "Publication des milliers de produits ou services en un seul clic à l’aide d’un fichier Excel CSV",
                    "Une photo cover personnalisable dans votre page entreprise",
                    "Pack Export inclus (si cela fait partie de votre activité)",
                    "Statistiques avancées de vos opérations",
                    "Voir qui a visité votre page",
                    "Liste de souhait des produits et services",
                    "Création gratuite de votre compte et votre page"
                  ].map((item, index) => (
                    <li key={index} className={`arp_${index % 2 === 0 ? 'odd' : 'even'}_row arptemplate_11_column_8_row_${index}`} style={{ height: '70px' }}>
                      <span data-isblank="" className="row_description_first_step arp_row_description_text toggle_step_first" data-tipso="">
                        {item}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
}

const SilverPlan = ({plan}) => {
    return (
        <div id="main_column_2" data-has_custom_column_width="false" data-order="main_column_0" className="arp_none ArpPricingTableColumnWrapper arpricemaincolumn style_column_2 hover_effect no_animation arp_style_0" style={{ height: '1905px', marginBottom: '10px' }} data-column-footer-position="0" has_column_highlighted="false">
          <div className="arpplan is_column_clickable column_1 arp_col_toggle_selected arp_col_active_toggle arp_col_toggle_first_step">
            <div className="planContainer">
              <div className="arp_column_content_wrapper">
                <div className="arpcolumnheader">
                  <div className="arppricetablecolumntitle">
                    <div className="bestPlanTitle package_title_first toggle_step_first" style={{ height: '80px' }}>Silver</div>
                  </div>
                  <div className="arppricetablecolumnprice arp_default">
                    <div className="arp_price_wrapper" data-column="main_column_2" style={{ height: '100px' }}>
                      <span className="price_text_first_step toggle_step_first">
                        <span className="arp_price_value">{plan?.price}<span className="arp_currency">DH</span></span>
                        <span className="arp_price_duration" style={{ fontSize: '18px', fontWeight: 'normal' }}>HT / an</span>
                      </span>
                    </div>
                    <div className="column_description column_description_first_step toggle_step_first" data-column_name="main_column_2" data-template_id="arptemplate_11" data-type="other_columns_buttons" data-level="column_description_level" style={{ height: '55px' }}>
                      Badge Premium, badge Silver et badge vérifié optionnel
                    </div>
                    <div className="arpcolumnfooter">
                      <div className="arppricetablebutton toggle_step_first" style={{ textAlign: 'center', height: '55px' }}>
                        <button type="button" className="arp_price_tbl_btn_column_2 bestPlanButton arp_shadow_button toggle_step_first" data-is-post-variables="" data-post-variables="">
                          <span className="btn_content_first_step bestPlanButton_text"
                                                      onClick={(e) => redirectTo(e)}

                          >Commander maintenant</span>
                        </button>
                        <div className="arp_paypal_form" id="paypal_form_first_column_2" style={{ display: 'none' }}></div>
                      </div>
                      <div className="arp_footer_content arp_btn_after_content" style={{ display: 'none' }}>
                        <span className="footer_content_first_step arp_footer_content_text toggle_step_first"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="arpbody-content arppricingtablebodycontent">
                  <ul className="arp_opt_options arppricingtablebodyoptions" id="1" style={{ textAlign: 'center' }}>
                  {[
                      "Badge Premium, badge Diamant unique et badge vérifié optionnel",
                      "Référencement optimal sur le site",
                      "Référencement optimal par catégorie/sous-catégorie",
                      `Catalogue de ${plan?.max_announcements || 'illimités'} produits et services`,
                      "Votre page entreprise visible par tous les utilisateurs",
                      "Votre Fiche, Contact et site web visibles par tous les utilisateurs",
                      "Votre nom d’entreprise en sous domaine du site",
                      "Prospection illimitée au sein de la plateforme",
                      `Mise à jour de ${plan?.max_update || 'illimités'} produits ou services en top de la liste des offres et annonces`,
                      `Mettre ${plan?.max_pinned_announcements || 'illimités'} produits ou services favoris au premier plan dans votre page`,
                      "Aide à la prospection",
                      "Aide à la configuration de votre page entreprise",
                      "Publication des annonces d’achat ou de vente illimités",
                      "Possibilité de publier une annonce confidentielle d’achat ou de vente pour vous protéger face à la concurrence",
                      "Publication des milliers de produits ou services en un seul clic à l’aide d’un fichier Excel CSV",
                      "Une photo cover personnalisable dans votre page entreprise",
                      "Pack Export inclus (si cela fait partie de votre activité)",
                      "Statistiques avancées de vos opérations",
                      "Voir qui a visité votre page",
                      "Liste de souhait des produits et services",
                      "Création gratuite de votre compte et votre page"
                    ].map((item, index) => (
                      <li key={index} className={`arp_${index % 2 === 0 ? 'odd' : 'even'}_row arptemplate_11_column_8_row_${index}`} style={{ height: '70px' }}>
                        <span data-isblank="" className="row_description_first_step arp_row_description_text toggle_step_first" data-tipso="">
                          {item}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

const Plans_1 = ({plans}) => {
  console.log(plans)
  return (
    <section
      data-vc-full-width="true"
      data-vc-full-width-init="true"
      data-vc-stretch-content="true"
      className="vc_section vc_custom_1717176414993"
      style={{
        padding : '20px 15px',
        boxSizing: 'border-box'
      }}
    >
      <div
        className="vc_row wpb_row vc_row-fluid vc_custom_1717176491055 vc_row-o-full-height vc_row-o-columns-stretch vc_row-o-equal-height vc_row-flex"
        style={{ minHeight: '20.9068vh' }}
      >
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner vc_custom_1649896497480">
            <div className="wpb_wrapper">
              <h2
                style={{
                  fontSize: '36px',
                  color: '#434343',
                  textAlign: 'left',
                }}
                className="vc_custom_heading contact-page-title"
              >
                Nos Packs PREMIUM
              </h2>
              <div
                className="arp_template_main_container"
                id="arp_template_main_container"
                style={{ width: '1400px', textAlign: 'center' }}
                data-hide-blank-rows=""
                data-is-tempalte="0"
                data-woocomerce-cart-url="https://www.b2bmaroc.com/?page_id=6723"
                data-is-display-tooltip="1"
                data-mobile-width="480"
                data-is-responsive="1"
                data-is-animated="0"
                data-arp-template="arptemplate_102"
                data-arp-uniq-id="29112"
                data-template-type="normal"
                data-table-preview="0"
                data-reference-template="arptemplate_11"
                data-hover-type="hover_effect"
                data-column-mobile="1"
                data-column-tablet="3"
                data-column-desktop=""
                data-all-column-width="350"
                data-tablet-width="768"
                data-space-columns="0"
                data-responsive-width-arr='{"with_space":["23%"],"no_space":["25%"]}'
                data-column-wrapper-width-arr="40"
                data-toggle-animation="arp_toggle_fade"
                data-is-price-animation=""
                data-column-wrapper-highlighted-height="20"
                data-column-wrapper-default-height="40"
                data-enable-loader="0"
                data-enable-analytics="arp_enable_analytics"
            >
                <div className="ArpTemplate_main arp_front_main_container" id="ArpTemplate_main" style={{}}>
                    <div className="ArpPriceTable arp_outer_wrapper_all_columns arp_price_table_102 arp_price_ref_table_arptemplate_11 arptemplate_102 arptemplate_102_29112 arpcustom_skin">
                        <div className="arp_inner_wrapper_all_columns" id="ArpPricingTableColumns" style={{}}>
                            <div className='arp_allcolumnsdiv'>
                                <SilverPlan plan={plans?.find(item => item.type == 'Silver' )}/>
                                <GoldenPlan plan={plans?.find(item => item.type == 'Golden' )}/>
                                <DiamantPlan plan={plans?.find(item => item.type == 'Diamant' )}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              <div style={{ clear: 'both' }}></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Plans_1