import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { categories } from "../constants/categories";
import { SearchBarLaptop, SearchBarPhone } from "./";
import { HeaderBg } from "../constants/images";
import { FlagFrBg } from "../constants/images";
import { FlagUsBg } from "../constants/images";
import { isLogged, logout, redirectTo } from "../constants/AuthFunctions";

const PhoneMenu = ({hundelOpenMenu, auth, redirectTo}) => {

  const CateButton = useRef()
  const CateMenu = useRef()

  const openCategories = () => {

    if(CateButton.current.classList.contains('show')){

      CateButton.current.classList.remove('show')
      CateMenu.current.classList.remove('show')

    }else{

      CateButton.current.classList.add('show')
      CateMenu.current.classList.add('show')

    }

  }

  return (
    <div className="off-canvas-navigation light" id="default-oc-header">
      <ul id="menu-categories-1" className="nav nav-inline yamm">
        <li
          id="menu-item-6521"
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5164 current_page_item menu-item-6521 active"
        >
          <Link onClick={() => hundelOpenMenu()} title="Accueil" to="/">
            Accueil
          </Link>
        </li>
        <li
          id="menu-item-6539"
          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6539"
        >
          {
            auth ? 
            <Link onClick={(e) => redirectTo(e)}
              title="Espace Client"
              to="#"
            >
              Espace Client
            </Link>
            :
            <Link onClick={() => hundelOpenMenu()}
              title="Espace Client"
              to="/auth/login"
            >
              Espace Client
            </Link>
          }
        </li>
        <li
          id="menu-item-6642"
          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-6642 dropdown"
        >
          <a
            title="Catégories"
            data-bs-toggle="dropdown"
            href='#'
            className="dropdown-toggle"
            aria-haspopup="true"
            ref={CateButton}
            onClick={openCategories}
          >
            Catégories
          </a>
          <ul ref={CateMenu} role="menu" className="dropdown-menu">
            {categories.length > 0 &&
              categories.map((category, index) => (
                <li
                  key={index}
                  onClick={() => hundelOpenMenu()}
                  id={`menu-item-${index}`}
                  className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-${index} dropdown-submenu`}
                >
                  <Link
                    title={category.name}
                    to={`/product-category/${category.name}`}
                  >
                    {category.name}
                  </Link>
                </li>
              ))}
          </ul>
        </li>

        <li
          id="menu-item-6524"
          onClick={() => hundelOpenMenu()}
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6524"
        >
          <Link title="Annonces &#038; Offres" to="/annonces-offres">
            Annonces &#038; Offres
          </Link>
        </li>

        <li
          id="menu-item-6956"
          onClick={() => hundelOpenMenu()}
          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6956"
        >
          <Link
            title="Trouver des Fournisseurs"
            to="/fournisseurs"
          >
            Trouver des Fournisseurs
          </Link>
        </li>
        
        <li
          id="menu-item-6533"
          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-6533 dropdown"
        >
          <Link
            title="Nos Packs"
            onClick={() => hundelOpenMenu()}
            to="/plans"
          >
            Services Premium
          </Link>
        </li>

        <li
          id="menu-item-6522"
          onClick={() => hundelOpenMenu()}
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6522"
        >
          <Link
            title="À propos de nous"
            to="/a-propos-de-nous/"
          >
            À propos de nous
          </Link>
        </li>

        <li
          onClick={() => hundelOpenMenu()}
          id="menu-item-6523"
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6523"
        >
          <Link title="Aide &#038; F.A.Q" to="/aide/">
            Aide &#038; F.A.Q
          </Link>
        </li>

        <li
          onClick={() => hundelOpenMenu()}
          id="menu-item-6526"
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6526"
        >
          <Link
            title="Blog et conseils d’affaires"
            to="/blog"
          >
            Blog et conseils d’affaires
          </Link>
        </li>

        <li
          onClick={() => hundelOpenMenu()}
          id="menu-item-6525"
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6525"
        >
          <Link
            title="Avantages de vendre sur B2B Maroc"
            to="/avantages-de-vendre-sur-b2b-maroc/"
          >
            Avantages de vendre sur B2B Maroc
          </Link>
        </li>

        <li
          onClick={() => hundelOpenMenu()}
          id="menu-item-6532"
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6532"
        >
          <Link title="Recrutement" to="/recrutement/">
            Recrutement
          </Link>
        </li>

        <li
          onClick={() => hundelOpenMenu()}
          id="menu-item-6527"
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6527"
        >
          <Link
            title="Contactez Nous"
            to="/aide/"
          >
            Contactez Nous
          </Link>
        </li>

        <li
          id="menu-item-6962"
          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-6962 dropdown"
        >
          <a
            title="Langues"
            href="#"
            data-bs-toggle="dropdown"
            className="dropdown-toggle"
            aria-haspopup="true"
          >
            Langues
          </a>
          <ul role="menu" className=" dropdown-menu">
            <li
              id="menu-item-6961"
              className="trp-language-switcher-container menu-item menu-item-type-post_type menu-item-object-language_switcher current-language-menu-item menu-item-6961"
            >
              <a title="Français" href="https://www.b2bmaroc.com/">
                <span data-no-translation>
                  <img
                    className="trp-flag-image"
                    src={FlagFrBg}
                    width="18"
                    height="12"
                    alt="fr_FR"
                    title="Français"
                  />
                  <span className="trp-ls-language-name">Français</span>
                </span>
              </a>
            </li>
            <li
              id="menu-item-6960"
              className="trp-language-switcher-container menu-item menu-item-type-post_type menu-item-object-language_switcher menu-item-6960"
            >
              <a title="English" href="https://www.b2bmaroc.com/en/">
                <span data-no-translation>
                  <img
                    className="trp-flag-image"
                    src={FlagUsBg}
                    width="18"
                    height="12"
                    alt="en_US"
                    title="English"
                  />
                  <span className="trp-ls-language-name">English</span>
                </span>
              </a>
            </li>
          </ul>
        </li>

      </ul>{" "}
    </div>
  );
};

function Header() {
  const [isVisible, setIsVisible] = useState(false);
  const [isBarIconVisible, setIsBarIconVisible] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      setIsBarIconVisible(window.innerWidth > 1200);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
}, []);

  const toggleVisibility = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const navigate = useNavigate();
  const { query } = useParams();
  const [TypeSearch, setTypeSearch] = useState("products");
  const [SearchQuery, setSearchQuery] = useState("");
  const [auth, setAuth] = useState(isLogged());

  const hundelSearch = (e) => {
    e.preventDefault();
    if (SearchQuery != "") {
      switch (TypeSearch) {
        case "products":
          navigate(`/search-page/annonces/${SearchQuery}`);
          break;

        case "companies":
          navigate(`/search-page/fournisseurs/${SearchQuery}`);
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    setSearchQuery(query ? query : "");
  }, [query]);

  useEffect(() => {
    setAuth(isLogged());
  }, []);

  const hundelOpenMenu = () => {

    const divPageId = document.querySelector("#page");
    const bodyElement = document.body;

    if (divPageId?.classList.contains("off-canvas-bg-opacity")) {
      bodyElement.classList.remove("off-canvas-active");
      divPageId.classList.remove("off-canvas-bg-opacity");

      document
        .querySelectorAll(".off-canvas-navigation-wrapper")
        ?.forEach((ele) => {
          ele.classList.remove("toggled");
          ele.querySelector("#default-oc-header").removeAttribute("style");
        });
    } else {
      bodyElement.classList.add("off-canvas-active");
      divPageId.classList.add("off-canvas-bg-opacity");

      document
        .querySelectorAll(".off-canvas-navigation-wrapper")
        ?.forEach((ele) => {
          ele.classList.add("toggled");
          ele.querySelector("#default-oc-header").style.top = 0;
        });
    }
  };

  const Logout = (e) => {
    e.preventDefault()
    logout(e)
  }

  return (
    <>
      <div
        style={{
          position: "fixed",
          display: `${isVisible ? "block" : "none"}`,
          bottom: "20px",
          right: "20px",
          zIndex : 10000
        }}
      >
        <a
          href="#page"
          className="btn btn-secondary shadows rounded-cricle d-flex align-items-center justify-content-center p-0 pe-auto position-sticky position-fixed back-to-top-link "
          aria-label="Scroll to Top"
        >
          <i className="fa fa-angle-up"></i>
        </a>
      </div>
      <header id="masthead" className="site-header header-v10">
        <div className="stick-this">
          <div className="container hidden-lg-down d-none d-xl-block">
            <div className="masthead row align-items-center">
              <div className="header-logo-area d-flex justify-content-between align-items-center">
                <Link to="/" className="d-none site-dark-logo header-logo-link">
                  <img
                    className="img-header-logo"
                    src={HeaderBg}
                    alt="B2B Maroc"
                  />
                </Link>

                <div className="header-site-branding">
                  <Link to="/" className="header-logo-link">
                    <img
                      src={HeaderBg}
                      alt="B2B Maroc"
                      className="img-header-logo"
                      width="0"
                      height="0"
                    />
                  </Link>
                </div>

                <div className="off-canvas-navigation-wrapper ">
                  <div className="off-canvas-navbar-toggle-buttons clearfix">
                    <button
                      className="navbar-toggler navbar-toggle-hamburger "
                      onClick={hundelOpenMenu}
                      type="button"
                    >
                    {!isBarIconVisible && <i className="ec ec-menu"></i>}
                    </button>
                    <button
                      className="navbar-toggler navbar-toggle-close "
                      type="button"
                    >
                      <i className="ec ec-close-remove"></i>
                    </button>
                  </div>

                  <div
                    className="off-canvas-navigation light"
                    id="default-oc-header"
                  >
                    <ul id="menu-categories" className="nav nav-inline yamm">
                      <li
                        id="menu-item-6521"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5164 current_page_item menu-item-6521 active"
                      >
                        <Link title="Accueil" to="/">
                          Accueil
                        </Link>
                      </li>
                      <li
                        id="menu-item-6539"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6539"
                      >
                        {
                          auth ? 
                          <Link onClick={(e) => redirectTo(e)}
                            title="Espace Client"
                            to="#"
                          >
                            Espace Client
                          </Link>
                          :
                          <Link onClick={() => hundelOpenMenu()}
                            title="Espace Client"
                            to="/auth/login"
                          >
                            Espace Client
                          </Link>
                        }
                      </li>
                      <li
                        id="menu-item-6642"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-6642 dropdown"
                      >
                        <a
                          title="Catégories"
                          href="#"
                          data-bs-toggle="dropdown"
                          className="dropdown-toggle"
                          aria-haspopup="true"
                        >
                          Catégories
                        </a>
                      </li>
                      <li
                        id="menu-item-6533"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-6533 dropdown"
                      >
                        <Link
                          title="Nos Packs"
                          to="/plans"
                          data-bs-toggle="dropdown"
                          className="dropdown-toggle"
                          aria-haspopup="true"
                        >
                          Services Premium
                        </Link>
                      </li>
                      <li
                        id="menu-item-6524"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6524"
                      >
                        <Link
                          title="Annonces &#038; Offres"
                          to="/annonces-offres"
                        >
                          Annonces &#038; Offres
                        </Link>
                      </li>
                      <li
                        id="menu-item-6956"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6956"
                      >
                        <Link
                          title="Trouver des Fournisseurs"
                          to="/fournisseurs/"
                        >
                          Trouver des Fournisseurs
                        </Link>
                      </li>
                      <li
                        id="menu-item-6522"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6522"
                      >
                        <Link
                          title="À propos de nous"
                          to="/a-propos-de-nous/"
                        >
                          À propos de nous
                        </Link>
                      </li>
                      <li
                        id="menu-item-6523"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6523"
                      >
                        <Link
                          title="Aide &#038; F.A.Q"
                          to="/aide/"
                        >
                          Aide &#038; F.A.Q
                        </Link>
                      </li>
                      <li
                        id="menu-item-6526"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6526"
                      >
                        <Link
                          title="Blog et conseils d’affaires"
                          to="/blog"
                        >
                          Blog et conseils d’affaires
                        </Link>
                      </li>
                      <li
                        id="menu-item-6525"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6525"
                      >
                        <Link
                          title="Avantages de vendre sur B2B Maroc"
                          to="/avantages-de-vendre-sur-b2b-maroc/"
                        >
                          Avantages de vendre sur B2B Maroc
                        </Link>
                      </li>
                      <li
                        id="menu-item-6532"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6532"
                      >
                        <Link
                          title="Recrutement"
                          to="/recrutement/"
                        >
                          Recrutement
                        </Link>
                      </li>
                      <li
                        id="menu-item-6527"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6527"
                      >
                        <Link
                          title="Contactez Nous"
                          to="/aide/"
                        >
                          Contactez Nous
                        </Link>
                      </li>
                      <li
                        id="menu-item-6962"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-6962 dropdown"
                      >
                        <a
                          title="Langues"
                          href="#"
                          data-bs-toggle="dropdown"
                          className="dropdown-toggle"
                          aria-haspopup="true"
                        >
                          Langues
                        </a>
                        <ul role="menu" className=" dropdown-menu">
                          <li
                            id="menu-item-6961"
                            className="trp-language-switcher-container menu-item menu-item-type-post_type menu-item-object-language_switcher current-language-menu-item menu-item-6961"
                          >
                            <a
                              title="Français"
                              href="https://www.b2bmaroc.com/"
                            >
                              <span data-no-translation>
                                <img
                                  className="trp-flag-image"
                                  src={FlagFrBg}
                                  width="18"
                                  height="12"
                                  alt="fr_FR"
                                  title="Français"
                                />
                                <span className="trp-ls-language-name">
                                  Français
                                </span>
                              </span>
                            </a>
                          </li>
                          <li
                            id="menu-item-6960"
                            className="trp-language-switcher-container menu-item menu-item-type-post_type menu-item-object-language_switcher menu-item-6960"
                          >
                            <a
                              title="English"
                              href="https://www.b2bmaroc.com/en/"
                            >
                              <span data-no-translation>
                                <img
                                  className="trp-flag-image"
                                  src={FlagUsBg}
                                  width="18"
                                  height="12"
                                  alt="en_US"
                                  title="English"
                                />
                                <span className="trp-ls-language-name">
                                  English
                                </span>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>{" "}
                  </div>
                </div>
              </div>
              <SearchBarLaptop
                hundelSearch={hundelSearch}
                setSearchQuery={setSearchQuery}
                setTypeSearch={setTypeSearch}
                SearchQuery={SearchQuery}
                TypeSearch={TypeSearch}
              />
              <div className="header-icons col-auto d-flex justify-content-end align-items-center">
                {" "}
                <div
                  className="header-icon secondary-nav"
                  data-bs-title="Langue"
                >
                  <ul className="secondary-nav yamm">
                    <li className="menu-item dropdown">
                      <a
                        title="Langues"
                        href="#"
                        className="dropdown-toggle"
                        aria-haspopup="true"
                        data-hover="dropdown"
                      >
                        <i
                          className="fas fa-globe"
                          style={{
                            color: "#1f384b",
                            lineHeight: "1em",
                            fontSize: "1.8em",
                          }}
                        ></i>
                      </a>
                      <ul role="menu" className="dropdown-menu">
                        <li className="menu-item">
                          <a title="Français" href="https://www.b2bmaroc.com/">
                            <span data-no-translation="">
                              <img
                                className="trp-flag-image"
                                src={FlagFrBg}
                                alt="fr_FR"
                                title="Français"
                                width="18"
                                height="12"
                              />
                              &nbsp;
                              <span className="trp-ls-language-name">
                                Français
                              </span>
                            </span>
                          </a>
                        </li>
                        <li className="menu-item">
                          <a title="English" href="https://www.b2bmaroc.com/en">
                            <span data-no-translation="">
                              <img
                                className="trp-flag-image"
                                src={FlagUsBg}
                                alt="en_US"
                                title="English"
                                width="18"
                                height="12"
                              />
                              &nbsp;
                              <span className="trp-ls-language-name">
                                English
                              </span>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      className="menu-item dropdown"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      data-bs-title="Wishlist"
                    >
                      <Link to="/wishlist">
                        <i
                          className="fas fa-heart"
                          style={{
                            color: "#1f384b",
                            lineHeight: "1em",
                            fontSize: "1.8em",
                          }}
                        ></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="header-icon"></div>
              </div>{" "}
            </div>

            <div className="secondary-nav-menu col electro-animate-dropdown position-relative hidden-lg-down d-none d-xl-block">
              <ul style={{
                margin : '0'
              }} id="menu-secondary-nav" className="secondary-nav yamm">
                <li
                  id="menu-item-5418"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-5418 dropdown"
                >
                  <a
                    title="Catégories"
                    href="#"
                    className="dropdown-toggle"
                    aria-haspopup="true"
                    data-hover="dropdown"
                  >
                    <i className="departments-menu-v2-icon fa fa-list-ul me-2"></i>
                    Catégories
                  </a>
                  <ul role="menu" className="categories-menu dropdown-menu">
                    {/* categories */}

                    {categories.length > 0 &&
                      categories.map((category, index) => (
                        <li
                          key={index}
                          id={`menu-item-${index}`}
                          className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-${index} dropdown-submenu`}
                        >
                          <Link
                            title={category.name}
                            to={`/product-category/${category.name}`}
                          >
                            <i className={category.icon}></i>
                            {category.name}
                          </Link>

                          <ul role="menu" className=" dropdown-menu">
                            {category.subcategories.map(
                              (subcategory, subIndex) => (
                                <li
                                  key={subIndex}
                                  id={`menu-item-${index}-${subIndex}`}
                                  className={`menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-${index}-${++subIndex}`}
                                >
                                  <Link
                                    title={subcategory.name}
                                    to={`/product-category/${category.name}/${subcategory.name}`}
                                  >
                                    {subcategory.name}
                                  </Link>
                                </li>
                              )
                            )}
                          </ul>
                        </li>
                      ))}
                  </ul>
                </li>
                {/* end of categories */}

                <li
                  id="menu-item-5419"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5419"
                >
                  <Link title="Annonces &amp; Offres" to="/annonces-offres">
                    <i className="me-2"></i>Annonces &#038; Offres
                  </Link>
                </li>
                <li
                  id="menu-item-5723"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5723"
                >
                  <Link
                    title="Trouver des Fournisseurs"
                    to="/fournisseurs"
                  >
                    Trouver des Fournisseurs
                  </Link>
                </li>
                <li
                  id="menu-item-5421"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5421"
                >
                  <Link
                    title="Vendre sur b2bmaroc"
                    to="/avantages-de-vendre-sur-b2b-maroc"
                  >
                    Vendre sur b2bmaroc
                  </Link>
                </li>
                <li
                  id="menu-item-5422"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5422"
                >
                    <Link
                    title="Blog"
                    to="/blog"
                  >
                    Blog
                  </Link>
                </li>
                <li
                  id="menu-item-5423"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5423"
                >
                  <Link title="Aide" to="/aide">
                    Aide
                  </Link>
                </li>
                <li id="menu-item-5429" className="pull-end fw-bold fw-gold menu-item menu-item-type-custom menu-item-object-custom menu-item-5429">
                  <Link to='/plans' title="Services Premium">
                    Services Premium
                  </Link>
                </li>
                <li
                  id="menu-item-6959"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-6959 dropdown"
                >
                  <a
                    title="Langues"
                    href="#"
                    className="dropdown-toggle"
                    aria-haspopup="true"
                    data-hover="dropdown"
                  >
                    Langues
                  </a>
                  <ul role="menu" className=" dropdown-menu">
                    <li
                      id="menu-item-6958"
                      className="trp-language-switcher-container menu-item menu-item-type-post_type menu-item-object-language_switcher current-language-menu-item menu-item-6958"
                    >
                      <a title="Français" href="https://www.b2bmaroc.com/">
                        <span data-no-translation>
                          <img
                            className="trp-flag-image"
                            src={FlagFrBg}
                            width="18"
                            height="12"
                            alt="fr_FR"
                            title="Français"
                          />
                          <span className="trp-ls-language-name">Français</span>
                        </span>
                      </a>
                    </li>
                    <li
                      id="menu-item-6957"
                      className="trp-language-switcher-container menu-item menu-item-type-post_type menu-item-object-language_switcher menu-item-6957"
                    >
                      <a title="English" href="https://www.b2bmaroc.com/en/">
                        <span data-no-translation>
                          <img
                            className="trp-flag-image"
                            src={FlagUsBg}
                            width="18"
                            height="12"
                            alt="en_US"
                            title="English"
                          />
                          <span className="trp-ls-language-name">English</span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>{" "}
            </div>
          </div>

         <div className="container hidden-xl-up d-xl-none">
            <div className="mobile-header-v1 row align-items-center handheld-stick-this">
              <div className="off-canvas-navigation-wrapper ">
                <div className="off-canvas-navbar-toggle-buttons clearfix">
                  <button
                    style={{
                      padding : '4px 0'
                    }}
                    className="navbar-toggler navbar-toggle-hamburger "
                    onClick={hundelOpenMenu}
                    type="button"
                  >
                    <i className="ec ec-menu"></i>
                  </button>
                  <button
                    className="navbar-toggler navbar-toggle-close "
                    type="button"
                  >
                    <i className="ec ec-close-remove"></i>
                  </button>
                </div>

                <PhoneMenu redirectTo={redirectTo} auth={auth} hundelOpenMenu={hundelOpenMenu} />
              </div>
              <Link
                to="/"
                className="d-none site-dark-logo header-logo-link header-logo"
              >
                <img
                  className="img-header-logo"
                  src={HeaderBg}
                  alt="B2B Maroc"
                />
              </Link>{" "}
              <div className="header-logo">
                <Link
                  to="/"
                  className="header-logo-link"
                >
                  <img
                    src={HeaderBg}
                    alt="B2B Maroc"
                    className="img-header-logo"
                    width="0"
                    height="0"
                  />
                </Link>
              </div>
              <div className="handheld-header-links">
                <ul className="columns-2">
                  <li className="search">
                    <a href="">Search</a>{" "}
                    <div className="site-search">
                      <div className="widget woocommerce widget_product_search">
                        <form
                          role="search"
                          method="get"
                          className="woocommerce-product-search"
                          action="https://www.b2bmaroc.com/"
                        >
                          <label
                            className="screen-reader-text"
                            for="woocommerce-product-search-field-0"
                          >
                            Recherche pour :
                          </label>
                          <input
                            type="search"
                            id="woocommerce-product-search-field-0"
                            className="search-field"
                            placeholder="Recherche de produits&hellip;"
                            value=""
                            name="s"
                          />
                          <button type="submit" value="Recherche" className="">
                            Recherche
                          </button>
                          <input
                            type="hidden"
                            name="post_type"
                            value="product"
                          />
                        </form>
                      </div>{" "}
                    </div>
                  </li>
                  
                  <li className="my-account">
                    <Link to={auth ? '#' : '/auth/login'}>
                      <i className="ec ec-user"></i>
                    </Link>

                    {
                      auth && (
                        <ul>
                          <li onClick={(e) => redirectTo(e)}><i className="fa-solid fa-gauge"></i> Tableau de board</li>
                          <li onClick={(e) => navigate('/wishlist')}><i className="fas fa-heart" /> Liste Souhait</li>
                          <li onClick={(e) => Logout(e)}><i className="fa-solid fa-right-from-bracket"></i> Déconnexion</li>
                        </ul>
                      )
                    }

                  </li>
                  
                </ul>
              </div>
              <div className="site-search">
                <div style={{
                  marginBottom : '15px'
                }} className="widget woocommerce">
                  <SearchBarLaptop
                    hundelSearch={hundelSearch}
                    setSearchQuery={setSearchQuery}
                    setTypeSearch={setTypeSearch}
                    SearchQuery={SearchQuery}
                    TypeSearch={TypeSearch}
                  />
                </div>{" "}
              </div>
            </div>
          </div>
         
        </div>
      </header>
    </>
  );
}

export default Header;
