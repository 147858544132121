import React from 'react';

const PriceFilter = ({
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    handleSubmit,
    hundelReset
}) => {

    const hundelChange = (e, action) => {

        if(action == 'max'){
            if(
              parseInt(e.target.value) ||
              e.target.value == 0
            ){
                setMaxPrice(e.target.value)
            }

        }

        if(action == 'min'){

          if(
            parseInt(e.target.value) ||
            e.target.value == 0
          ){
              setMinPrice(e.target.value)
          }

        }

    }

  return (
    <aside id="woocommerce_price_filter-1" className="widget woocommerce widget_price_filter">
      <h3 className="widget-title">Tarifs</h3>
      <form onSubmit={(e) => handleSubmit(e, 'price')}>
        <div className="price_slider_wrapper">
          <div style={{width:'100%'}} className="price_slider_amount" data-step="10">
            <label className="screen-reader-text" htmlFor="min_price">Prix min</label>
            <input
              type="text"
              id="min_price"
              name="min_price"
              value={minPrice}
              onChange={(e) => hundelChange(e, 'min')}
            />
            <label className="screen-reader-text" htmlFor="max_price">Prix max</label>
            <input
                type="text"
                    style={{
                        margin : '10px 0'
                    }}
                id="max_price"
                name="max_price"
                onChange={(e) => hundelChange(e, 'max')}
                value={maxPrice}
            />
            <div style={{
              display: 'flex',
              flexWrap : 'wrap',
              marginBottom : '5px',
              gap : '5px'
            }}>

              <button type="submit" className="button">Filtrer</button>
              <button onClick={(e) => hundelReset(e, 'price')} className="button">Reset</button>

            </div>
            <div className="price_label">
              Prix : <span className="from">{minPrice} DH</span> — <span className="to">{maxPrice} DH</span>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </form>
    </aside>
  );
};

export default PriceFilter;
