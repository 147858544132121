import React from 'react'

const BasicPack = () => {
  return (
    <section
  data-vc-full-width="true"
  data-vc-full-width-init="true"
  className="vc_section vc_custom_1717176634876 vc_section-has-fill"
  style={{
    position: 'relative',
    boxSizing: "border-box"
  }}
>
    <div>
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1717176711924">
            <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner">
                <div className="wpb_wrapper">
                <h2
                    style={{
                    fontSize: "36px",
                    color: "#434343",
                    textAlign: "left"
                    }}
                    className="vc_custom_heading contact-page-title"
                >
                    Pack Basique
                </h2>
                </div>
            </div>
            </div>
        </div>
        <div className="vc_row wpb_row vc_row-fluid inner-bottom-xs vc_custom_1717175111422 vc_row-has-fill">
            <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner vc_custom_1646912088974">
                <div className="wpb_wrapper">
                <div className="wpb_text_column wpb_content_element">
                    <div className="wpb_wrapper">
                    <p style={{ textAlign: "justify", fontSize: "14px" }}>
                        Services inclus :
                    </p>
                    <ul>
                        <li style={{ textAlign: "justify", fontSize: "14px" }}>
                        Catalogue de <strong>10</strong> produits et services
                        </li>
                        <li style={{ textAlign: "justify", fontSize: "14px" }}>
                        Référencement standard
                        </li>
                        <li style={{ textAlign: "justify", fontSize: "14px" }}>
                        Votre page entreprise visible uniquement par les membres{" "}
                        <span style={{ color: "#ff9900" }}>
                            <strong>Premium</strong>
                        </span>
                        </li>
                        <li style={{ textAlign: "justify", fontSize: "14px" }}>
                        Votre Fiche, Contact et site web visibles uniquement par les
                        membres <strong><span style={{ color: "#ff9900" }}>Premium</span></strong>
                        </li>
                        <li style={{ textAlign: "justify", fontSize: "14px" }}>
                        Contacter les membres <strong><span style={{ color: "#ff9900" }}>Premium</span></strong> uniquement
                        </li>
                        <li style={{ textAlign: "justify", fontSize: "13px" }}>
                        Assistance et aide à la configuration de votre page entreprise
                        </li>
                        <li style={{ textAlign: "justify", fontSize: "14px" }}>
                        Publier des annonces d’achat ou de vente limités visibles
                        uniquement par les membres <strong><span style={{ color: "#ff9900" }}>Premium</span></strong>
                        </li>
                        <li style={{ textAlign: "justify", fontSize: "14px" }}>
                        Création gratuite de votre compte et votre page
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</section>

  )
}

export default BasicPack