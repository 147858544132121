import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { AvantagesBg } from '../../constants/images'

const Button = ({text}) => {
    return (
        <div className='avantages-buttons' >
            <Link  
            to="/auth/login"
            title="Lien pour créer votre page"
            >
            {text}{" "}
            <i className="fa-solid fa-right-long"></i>
            </Link>
      </div>
    )
}

const Avantages = () => {

  useEffect(() => {
    document.body.removeAttribute("class");
    document.body.classList.add(
      "home",
      "page-template-default",
      "page-id-79",
      "page",
      "wp-custom-logo",
    );

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove(
        "home",
        "page",
        "page-template-default",
        "page",
        "page-id-79",
        "wp-custom-logo",
      );
    };
  }, []); 

  return (
    <div id="primary" className="content-area">
  <main id="main" className="site-main">
    <article
      id="post-10"
      className="post-10 page type-page status-publish has-post-thumbnail hentry"
    >
      <header
        className="entry-header header-with-cover-image"
        style={{
          backgroundImage:
            `url(${AvantagesBg})`,
        }}
      >
        <div className="caption">
          <h1 className="entry-title"> </h1>
        </div>
      </header>

      <div className="entry-content">
        <div style={{paddingTop:'100px'}} className="vc_row wpb_row vc_row-fluid vc_custom_1646837983055">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="wpb_text_column wpb_content_element">
                  <div className="wpb_wrapper">
                    <h2 className="contact-page-title" style={{ textAlign: "left" }}>
                      Les Avantages d’acheter et de vendre sur{" "}
                      <strong>B2B Maroc</strong>
                    </h2>
                  </div>
                </div>

                <div className="wpb_text_column wpb_content_element">
                  <div className="wpb_wrapper">
                    <p>
                      Premièrement! En s’inscrivant sur notre plateforme B2B
                      Maroc, plusieurs avantages d’acheter et de vendre s’offrent à vous.
                    </p>
                    <p>Vous pouvez :</p>
                    <ul>
                      <li>
                        Créer votre propre page d’entreprise avec une fiche de
                        contact, une présentation de votre entreprise et une liste de vos
                        produits ou services.
                      </li>
                      <li>
                        Publier votre annonce d’achat, de vente ou bien d’export.
                      </li>
                      <li>
                        Contacter des milliers de pages entreprises acheteurs et
                        fournisseurs au Maroc et à l’international.
                      </li>
                      <li>
                        Consulter les annonces et offres des autres fournisseurs et
                        acheteurs.
                      </li>
                      <li>
                        Demander un devis au fournisseurs présents sur la plateforme.
                      </li>
                      <li>
                        Filtrer vos recherches, choisir avec exactitude la nature de votre
                        activité ainsi que les catégories/sous-catégories dans lesquelles
                        vous souhaitez lister vos produits ou services et annonces.
                      </li>
                      <li>
                        Vous aurez accès à une page blog avec des conseils sur le
                        domaine des affaires au Maroc (vente, achat, export, investissement,
                        trouver des investisseurs, etc.) que vous trouverez en menu principal et en bas de chaque page.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="wpb_text_column wpb_content_element">
                  <div className="wpb_wrapper">
                    <p>
                      En optant pour les solutions PREMIUM de B2B Maroc, votre entreprise
                      aura un attrait considérable sur le net au niveau national et international.
                    </p>
                  </div>
                </div>

                <Button text='Créé votre page'/>

                <div className="wpb_text_column wpb_content_element">
                  <div className="wpb_wrapper">
                    <p>
                      En optant pour les solutions PREMIUM de B2B Maroc, votre
                      entreprise aura un attrait considérable sur le net au niveau national
                      et international.
                    </p>
                    <p>L’acquisition d’un pack PREMIUM vous donne accès à une panoplie d’avantages comme :</p>
                    <ul>
                      <li>
                        La valorisation de votre entreprise sur le net ainsi que la facilité
                        d’accès à votre fiche entreprise sur le site.
                      </li>
                      <li>Vos offres et annonces vont figurer en tête de la liste.</li>
                      <li>Votre page entreprise se situera en tête de la liste des entreprises.</li>
                      <li>
                        Votre nom d’entreprise en sous domaine du site exemple :{" "}
                        <i>votreentreprise.b2bmaroc.com/</i> pour un référencement optimal.
                      </li>
                      <li>
                        Votre page entreprise se situera en tête de la liste des
                        catégories/sous catégories.
                      </li>
                      <li>Une aide à la prospection est proposée pour vous en option.</li>
                      <li>
                        La mise en place d’une centaine de produits et services selon le
                        pack choisi.
                      </li>
                      <li>Voir qui a consulté votre page.</li>
                      <li>
                        Des packs spéciaux bon plan pour les entreprises Exportatrices, les
                        entreprises de recyclage et activités liées/Energies renouvelables.
                      </li>
                      <li>Un pack spécial Auto-entrepreneurs.</li>
                      <li>
                        Votre entreprise aura un badge{" "}
                        <a href="https://www.b2bmaroc.com/nos-packs-premium/">PREMIUM</a> et un badge Vérifié optionnel
                        selon le pack choisi ainsi que d’autres services.
                      </li>
                      <li>
                        Vous pouvez, si vous le souhaitez,{" "}
                        <a href="https://espace.b2bmaroc.com/connexion">publier</a> des
                        annonces confidentielles d’achat ou de vente pour protéger votre
                        anonymat face à la concurrence.
                      </li>
                    </ul>
                  </div>
                </div>

                <Button text='Voir nos packs PREMIUM' />

                <div className="wpb_text_column wpb_content_element">
                  <div className="wpb_wrapper">
                    <p>
                      Que vous soyez Grossiste, Détaillant, Fabricant, Producteur, Prestataire de
                      services, Exportateur ou Importateur. B2B Maroc est l’emplacement idéal
                      pour développer votre business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </main>
</div>

  )
}

export default Avantages