import React from 'react'
import { recrutement } from '../../constants/images'

const Recrutement = () => {
  return (
    <div id="primary" className="content-area">
  <main id="main" className="site-main">
    <article
      id="post-5608"
      className="post-5608 page type-page status-publish has-post-thumbnail hentry"
    >
      <header
        className="entry-header header-with-cover-image"
        style={{
          backgroundImage: `url(${recrutement})`,
        }}
      >
      </header>
      <div style={{marginTop:'40px'}} className="entry-content">
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1457506356430">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="wpb_text_column wpb_content_element vc_custom_1658136649017">
                  <div className="wpb_wrapper">
                    <h2 className="contact-page-title">Rejoignez nous</h2>
                    <p>
                      Nous sommes en constante recherche des jeunes talents.
                      <br />
                      Merci de nous faire parvenir votre CV format PDF à l’adresse email suivante:
                    </p>
                    <p style={{ textAlign: "left" }}>
                      <a href="mailto:recrutement@b2bmaroc.com">
                        <b>recrutement@b2bmaroc.com</b>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </main>
</div>

  )
}

export default Recrutement