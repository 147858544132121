import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { axiosClient } from "../../constants/axionsClient";
import { aide_bg } from "../../constants/images";

function ContactForm() {
  const [formData, setFormData] = useState({
    first_name: '',
    second_name: '',
    company_name: '',
    email: '',
    phone: '',
    content: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    setIsSubmitting(true)
    toast.loading("Envoi...")
    try {
      
      const res = await axiosClient.post('/contact-us', formData);

      if(res.status === 200){

        toast.dismiss()
        toast.success('Votre message a été envoyé avec succés')
        setFormData({
          first_name: '',
          second_name: '',
          company_name: '',
          email: '',
          phone: '',
          content: '',
        })

      }

    } catch (error) {
      
      toast.dismiss()
      toast.error('Votre message n\'a pas été envoyé avec succés')

      if(error.response.status === 422){

        setErrors(error.response.data)

      }

    }
    setIsSubmitting(false)

  };

  return (
    <section 
      data-vc-full-width="true" 
      data-vc-full-width-init="true" 
      className="vc_section contact-section-contain vc_custom_1649892477130 vc_section-has-fill" >
      <div className="vc_row contact-section wpb_row vc_row-fluid inner-bottom-xs">
        
        <div className=" contact-section-1 wpb_column vc_column_container vc_col-sm-6">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <h2 style={{ fontSize: '25px', color: '#434343', textAlign: 'left', fontFamily: 'Open Sans', fontWeight: 400, fontStyle: 'normal' }} className="vc_custom_heading contact-page-title vc_custom_1649892928514">
                Besoin d'aide ou d'informations ?
              </h2>
              <div className="wpb_text_column wpb_content_element">
                <div className="wpb_wrapper">
                  <p style={{ textAlign: 'left' }}>Nous sommes à votre disposition pour toutes vos questions commerciales et techniques, demande de devis, demande d’intervention ..</p>
                  <p>Avez vous des reclamations?</p>
                  <p>Voulez vous des informations sur notre mode de fonctionnement?</p>
                  <p>Avez vous des questions concernant nos packs Premium?</p>
                  <p>Avez vous des questions à propos de votre page entreprise ou vos annonces?</p>
                  <p>Avez vous remarqué une infraction ou un utilisateur suspect au sein du site ?</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className=" contact-section-2 wpb_column vc_column_container vc_col-sm-6 vc_col-has-fill">
          <div
          className="vc_column-inner vc_custom_1649892566099">
            <div className="wpb_wrapper">
              <div className="wpforms-container wpforms-container-full" id="wpforms-5345">
                <form id="wpforms-form-5345" className="wpforms-validate wpforms-form" onSubmit={handleSubmit}>
                  <noscript className="wpforms-error-noscript">Veuillez activer JavaScript dans votre navigateur pour remplir ce formulaire.</noscript>
                  <div className="wpforms-field-container">
                    <div id="wpforms-5345-field_0-container" className="wpforms-field wpforms-field-name" data-field-id="0">
                      <label className="wpforms-field-label" htmlFor="wpforms-5345-field_0">
                        Nom & Prénom <span className="wpforms-required-label">*</span>
                      </label>
                      <div className="wpforms-field-row wpforms-field-large">
                        <div className="wpforms-field-row-block wpforms-first wpforms-one-half">
                          <input
                            type="text"
                            id="wpforms-5345-field_0"
                            className="wpforms-field-name-first wpforms-field-required"
                            name="first_name"
                            style={{
                              marginBottom : !errors.first_name ? '10px' : '0'
                            }}
                            value={formData.first_name}
                            placeholder="Nom"
                            onChange={handleChange}
                            required
                          />
                          {errors.first_name && <p className="error-text">{errors.first_name}</p>}
                        </div>
                        <div className="wpforms-field-row-block wpforms-one-half">
                          <input
                            type="text"
                            id="wpforms-5345-field_0-last"
                            className="wpforms-field-name-last wpforms-field-required"
                            name="second_name"
                            value={formData.second_name}
                            placeholder="Prénom"
                            onChange={handleChange}
                            required
                          />
                          {errors.second_name && <p className="error-text">{errors.second_name}</p>}
                        </div>
                      </div>
                    </div>

                    <div id="wpforms-5345-field_4-container" className="wpforms-field wpforms-field-email" data-field-id="4">
                      <label className="wpforms-field-label" htmlFor="wpforms-5345-field_4">
                        Entreprise <span className="wpforms-required-label">*</span>
                      </label>
                      <input
                        type="text"
                        id="wpforms-5345-field_4"
                        className="wpforms-field-large wpforms-field-required"
                        name="company_name"
                        value={formData.company_name}
                        placeholder="Raison sociale"
                        onChange={handleChange}
                        required
                      />
                      {errors.company_name && <p className="error-text">{errors.company_name}</p>}
                    </div>

                    <div id="wpforms-5345-field_1-container" className="wpforms-field wpforms-field-email" data-field-id="1">
                      <label className="wpforms-field-label" htmlFor="wpforms-5345-field_1">
                        E-mail <span className="wpforms-required-label">*</span>
                      </label>
                      <input
                        type="email"
                        id="wpforms-5345-field_1"
                        className="wpforms-field-required wpforms-field-email-primary"
                        name="email"
                        value={formData.email}
                        placeholder="Entrer votre email"
                        onChange={handleChange}
                        required
                      />
                      {errors.email && <p className="error-text">{errors.email}</p>}
                    </div>

                    <div id="wpforms-5345-field_6-container" className="wpforms-field wpforms-field-email" data-field-id="6">
                      <label className="wpforms-field-label" htmlFor="wpforms-5345-field_6">
                        Téléphone
                      </label>
                      <input
                        type="text"
                        id="wpforms-5345-field_6"
                        className="wpforms-field-large"
                        name="phone"
                        value={formData.phone}
                        placeholder="Entrer votre téléphone"
                        onChange={handleChange}
                      />
                      {errors.phone && <p className="error-text">{errors.phone}</p>}
                    </div>

                    <div id="wpforms-5345-field_2-container" className="wpforms-field wpforms-field-textarea" data-field-id="2">
                      <label className="wpforms-field-label" htmlFor="wpforms-5345-field_2">
                        Votre Message <span className="wpforms-required-label">*</span>
                      </label>
                      <textarea
                        id="wpforms-5345-field_2"
                        className="wpforms-field-large wpforms-field-required"
                        name="content"
                        style={{
                          height : '150px'
                        }}
                        value={formData.content}
                        onChange={handleChange}
                        required
                      />
                      {errors.content && <p className="error-text">{errors.content}</p>}
                    </div>
                  </div>

                  <div className="wpforms-submit-container">
                    <button
                      type="submit"
                      id="wpforms-submit-5345"
                      className="wpforms-submit"
                      name="wpforms[submit]"
                      value="wpforms-submit"
                      disabled={isSubmitting}
                    >
                      Envoyer
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

const AidePage = () => {

  const openQuation = (e) => {

    // Check if the clicked element is an h4 inside a vc_toggle
    if (e.target.tagName === 'H4') {

      // Add 'test-2' class to the parent vc_toggle element
      e.target.closest('.vc_toggle').classList.toggle('vc_toggle_active');
      
      // Get the second child of the parent vc_toggle element
      const secondChild = e.target.closest('.vc_toggle').children[1]; // Access second child
      
      // If the second child exists, set its display to block
      if (secondChild) {

        if(secondChild.style.display == 'none'){
          secondChild.style.display = 'block';
        }else {
          secondChild.style.display = 'none';
        }

      }

    }

  }

  useEffect(() => {
    
    const vcToggles = document.querySelectorAll('.vc_toggle');

    // Add event listeners to each of them
    vcToggles.forEach((toggle) => {

      toggle.addEventListener('click', openQuation);

    });

    // Cleanup event listeners on component unmount
    return () => {
      vcToggles.forEach((toggle) => {
        toggle.removeEventListener('click', openQuation);
      });
    };
  }, []);

  useEffect(() => {
    document.body.removeAttribute("class");
    document.body.classList.add(
      "home",
      "page-template-default",
      "page-id-79",
      "page",
      "wp-custom-logo",
    );

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove(
        "home",
        "page",
        "page-template-default",
        "page",
        "page-id-79",
        "wp-custom-logo",
      );
    };
  }, []); 

  return (
    <div id="primary" className="content-area">
      <main id="main" className="site-main">
        <article
          id="post-2505"
          className="post-2505 page type-page status-publish has-post-thumbnail hentry"
        >
          <header
            className="entry-header header-with-cover-image"
            style={{
              backgroundImage:
                `url(${aide_bg})`,
            }}
          >
            <div className="caption">
              <h1 className="entry-title"></h1>
            </div>
          </header>

          <div className="entry-content">

            <div className="vc_row wpb_row vc_row-fluid vc_custom_1649892704813">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner">
                  <div style={{paddingTop:'50px'}} className="wpb_wrapper">
                    <h1
                      style={{
                        fontSize: "25px",
                        color: "#434343",
                        textAlign: "left",
                      }}
                      className="vc_custom_heading contact-page-title"
                    >
                      Foire Aux Questions (F.A.Q)
                    </h1>
                    <h1
                      style={{
                        fontSize: "25px",
                        color: "#434343",
                        textAlign: "left",
                      }}
                      className="vc_custom_heading contact-page-title hide"
                    >
                      B2B Maroc
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="vc_row wpb_row vc_row-fluid inner-top-xs">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">

                    <div
                      id="1459864577831-bdb5bc1a-4a98"
                      className="vc_toggle vc_toggle_square vc_toggle_color_default vc_toggle_size_sm"
                    >

                      <div  style={{cursor:'pointer'}}  className="vc_toggle_title">
                        <h4 disabled>Quel est le concept de la plateforme?</h4>
                        <i disabled className="vc_toggle_icon"></i>
                      </div>

                      <div style={{display : 'none'}} className="vc_toggle_content">
                        <p>
                          B2B Maroc est une plateforme B2B (Business to
                          Business) de mise en relation entre les professionnels
                          acheteurs et fournisseurs au Maroc (Grossistes,
                          Détaillants, Fabricants, Producteurs, Prestataires de
                          services, Exportateurs, Importateurs). B2B Maroc
                          appartient à l’entreprise Marocaine Organic Line SARL
                          AU.
                        </p>
                        <p>
                          Linkedin:{" "}
                          <a href={JSON.parse(localStorage.getItem("information-owner"))?.linkdin}>
                            {JSON.parse(localStorage.getItem("information-owner"))?.linkdin}
                          </a>
                        </p>
                      </div>

                    </div>

                    <div
                      id="1459864633996-9b7e4f16-199e"
                      className="vc_toggle vc_toggle_rounded vc_toggle_color_default vc_toggle_color_inverted vc_toggle_size_sm"
                    >

                      <div style={{cursor:'pointer'}}  className="vc_toggle_title">
                        <h4>Pourquoi s’inscrire et intégrer la plateforme ?</h4>
                        <i className="vc_toggle_icon"></i>
                      </div>

                      <div
                        className="vc_toggle_content"
                        style={{ display: "none" }}
                      >
                        <p>
                          <strong>B2B Maroc</strong> vous permet de créer une
                          page de votre entreprise avec une présentation de
                          votre activité, de vos produits et services. Cela
                          facilitera l’accès vers votre page, augmentera le
                          nombre de vos ventes et visibilité sur le net et
                          valorisera votre image de marque.
                          <br />
                          Vous pouvez également mettre des annonces d’achat, de
                          vente via la plateforme.
                        </p>
                      </div>

                    </div>

                    <div
                      id="1459864665894-b82f7067-8407"
                      className="vc_toggle vc_toggle_square vc_toggle_color_orange vc_toggle_size_sm"
                    >

                      <div style={{cursor:'pointer'}}  className="vc_toggle_title">
                        <h4>Comment s’inscrire sur la plateforme ?</h4>
                        <i className="vc_toggle_icon"></i>
                      </div>

                      <div style={{display : 'none'}} className="vc_toggle_content">
                        <p>
                          Vous pouvez vous inscrire gratuitement via le
                          formulaire d’inscription en indiquant vos
                          informations, activités, produits et autres.
                        </p>
                      </div>

                    </div>

                    <div
                      id="1459864700197-4c554837-f084"
                      className="vc_toggle vc_toggle_default vc_toggle_color_default vc_toggle_size_md fw-gold"
                    >

                      <div style={{cursor:'pointer'}}  className="vc_toggle_title">
                        <h4
                          style={{ textAlign: "left" }}
                          className="vc_custom_heading fw-gold"
                        >
                          Pourquoi opter pour les packs Premium?
                        </h4>
                        <i className="vc_toggle_icon"></i>
                      </div>

                      <div style={{display : 'none'}} className="vc_toggle_content">
                        <p>
                          Plusieurs avantages s’offrent aux entreprises qui
                          optent pour les packs Premium comme la mise de leurs
                          produits ou services en tête de la liste,
                          l’acquisition d’un statut vérifié en options, la
                          visibilité de leurs fiches clients, l’aide à la
                          prospection, la possibilité de poster un très grand
                          nombre de produits ou services et bien plus.
                        </p>
                        <ol>
                          <li>
                            Les packs sont différents selon votre budget, votre
                            activité et la taille de votre entreprise. Ainsi
                            vous trouverez surement le pack qu’il vous faut.
                          </li>
                          <li>
                            Veuillez bien consulter notre rubrique{" "}
                            <Link to="/plans">
                              <span style={{ color: "#ff9900" }}>
                                <strong>Premium</strong>
                              </span>
                            </Link>
                            pour plus de détails (
                            <Link to="/plans">
                              En savoir plus
                            </Link>
                            )
                          </li>
                        </ol>
                      </div>

                    </div>

                    <div
                      id="1459864738156-c9e9af2d-48c9"
                      className="vc_toggle vc_toggle_default vc_toggle_color_default vc_toggle_size_sm"
                    >

                      <div style={{cursor:'pointer'}}  className="vc_toggle_title">
                        <h4>
                          Quelles sont les modes de paiement des packs Premium
                          proposés ?
                        </h4>
                        <i className="vc_toggle_icon"></i>
                      </div>

                      <div style={{display : 'none'}} className="vc_toggle_content">
                        <p>
                          Vous pouvez effectuer vos payements des packs Premium
                          via plusieurs modes à savoir:
                        </p>
                        <ul>
                          <li>
                            <strong>Paiement par carte de crédit&nbsp;</strong>
                          </li>
                          <li>
                            <strong>Paiement cash à la livraison</strong>
                            <br />
                            Le paiement cash à la livraison est disponible, le
                            paiement est effectué après la configuration de
                            votre page premium par l’un de nos employés dans
                            notre local ou bien dans le vôtre.
                          </li>
                          <li>
                            <strong>Paiement par virement bancaire</strong>
                            <br />
                            Le paiement est effectué par un virement du montant
                            à payer sur notre compte bancaire, ou via l’une des
                            agences de transfert d’argent au nom convenu.
                            <br />
                            <strong>Rib :</strong> 230787302906122103020024
                          </li>
                        </ul>
                        <p>
                          Pour en savoir plus sur nos modes de paiement,&nbsp;
                          <Link to="/aide">
                            contactez nous
                          </Link>{" "}
                          par email ou par téléphone/Whatsapp.
                        </p>
                      </div>

                    </div>

                    <div id="6677" className="vc_toggle vc_toggle_default vc_toggle_color_default vc_toggle_size_md">
                      
                      <div style={{cursor:'pointer'}}  className="vc_toggle_title">
                        <h4>
                          Mes données sont-elles sécurisées au sein de la
                          plateforme ?
                        </h4>
                        <i className="vc_toggle_icon"></i>
                      </div>

                      <div style={{display : 'none'}} className="vc_toggle_content">
                        <p>
                          Vos données sont sécurisées dans notre base de
                          données. La navigation sur le site est hautement
                          sécurisée aussi, nous fournissant meilleurs
                          certificats et mises à jour de sécurité.
                        </p>
                      </div>

                    </div>

                    <div id="7788" className="vc_toggle vc_toggle_default vc_toggle_color_default vc_toggle_size_md">
                      
                      <div style={{cursor:'pointer'}}  className="vc_toggle_title">
                        <h4>
                          Quels types de professionnels sont acceptés sur la
                          plateforme?
                        </h4>
                        <i className="vc_toggle_icon"></i>
                      </div>

                      <div style={{display : 'none'}} className="vc_toggle_content">
                        <p>
                          Les types de professionnels autorisés à rejoindre la
                          plateforme sont :
                        </p>
                        <ul>
                          <li>Les grossistes</li>
                          <li>Les détaillants</li>
                          <li>Les fabricants</li>
                          <li>Les prestataires de services</li>
                          <li>Les exportateurs/importateurs</li>
                          <li>Les producteurs</li>
                        </ul>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>

            <ContactForm />

          </div>
        </article>
      </main>
    </div>
  );
};

export default AidePage;
