import image1 from "../images/Les-7-piliers-de-la-sagesse-pour-reussir-a-lexport-Internationaliser-et-exporter-870x460.jpg";
import image2 from "../images/Start-up-Linnovation-jouera-un-role-determinant-pour-la-reprise-economique.jpg";
import image3 from "../images/Startup-Program-Au-Maroc.jpg";
import image4 from "../images/Startups-10-questions-a-poser-a-un-entrepreneur-avant-dinvestir-870x460.jpg";
import image5 from "../images/Quelles-sont-les-cles-pour-devenir-un-bon-grossiste.jpg";
import image6 from "../images/Les-TPME-representent-93-des-entreprises-marocaines-en-2019.jpg";
import image7 from "../images/Les-7-piliers-de-la-sagesse-pour-reussir-a-lexport-Internationaliser-et-exporter-870x460.jpg";
import image8 from "../images/Comment-Trouver-un-Fabricant-ou-un-Grossiste-Pour-Votre-Produit.jpg";
import image9 from "../images/Hydrogene-vert-le-Maroc-futur-leader-mondial-affirme-lAgence-internationale-des-energies-renouvelables.jpg";
import image10 from "../images/6-conseils-incontournables-a-suivre-pour-devenir-un-Auto-Entrepreneur-a-succes.jpg";
import image11 from "../images/8-cles-pour-reussir-a-lexport.jpg";
import image12 from "../images/8-conseils-essentiels-pour-bien-debuter-en-Auto-Entreprise.jpg";
import image13 from "../images/image.jpg";
import image14 from "../images/Comment-devenir-un-Top-Vendeur.jpg";
import image15 from "../images/Comment-entretenir-de-bonnes-relations-avec-ses-fournisseurs.jpg";
import image16 from "../images/Comment-reussir-sa-prospection-commerciale-B2B-en-5-etapes.jpg";
import image17 from "../images/Comment-Trouver-des-fournisseurs-fiables-en-tant-quEntrepreneur.jpg";
import image18 from "../images/Comment-trouver-les-meilleurs-fournisseurs-pour-votre-projet-dentreprise.jpg";
import image19 from "../images/Ecommerce-Le-guide-le-plus-complet-pour-reussir-au-Maroc.jpg";

import './articlesData.css';

const articles = [
  {
    id: 1,
    title: "Internationalisation des entreprises marocaines, difficile de franchir le pas",
    category: "Export, Fournisseur",
    date:' décembre 5, 2022',
    description: "« L’export ne doit pas être vécu comme une simple recherche de débouchés, mais comme une véritable ambition de l’entreprise de devenir internationale », commence Frédéric Rossi, directeur général délégué export de Business France, structure qui accompagne chaque année 10.000 entreprises dans leurs démarches à l'export. Vous aurez donc intérêt à préparer, impliquer et engager le maximum de personnes possibles sur toute la chaîne de valeur de votre entreprise. Tous les salariés doivent se sentir investis et devenir des supporters de cette nouvelle ambition.",
    imageUrl: image1,
    content: `
      <p className="text-gray-600 text-justify">
        En tout premier lieu, Kiosque360. Accompagner les entreprises marocaines dans leur internationalisation n’est pas une mince affaire. Pourtant, certains acteurs accompagnent leurs clients marocains principalement en Afrique. Cet article est une revue de presse du quotidien Les Inspirations ECO.<br/><br/>
        
        L’encouragement de l’internationalisation des entreprises marocaines est en marche. Les récentes mesures mises en place (par exemple : relèvement à 200 millions de DH du plafond des fonds destinés à l’internationalisation de l’investissement ou l’extension à 1 million de DH au profit des investisseurs nationaux voulant opérer sur le marché international) en sont la preuve concrète.<br/><br/>
        
        Dans son édition du jour, Les Inspirations ÉCO relève toutefois que « cette entreprise n’est pas des plus aisées ». Au point de constater la naissance de plus en plus de cabinets de conseil pour leur faciliter la tâche. Leur mission : « accompagner les entreprises internationales dans l’étude des marchés africains et dans l’identification de cibles d’acquisition en Afrique ».<br/><br/>
        
        Le quotidien soutient que le continent recèle un potentiel énorme pour les entreprises marocaines. Or, il constate que ces cabinets n’accompagnent que peu de sociétés marocaines sur l’Afrique, faute d’être connues au Maroc. Il note également que sur les entreprises marocaines qui sont présentes ou qui y réalisent des transactions commerciales, peu sont des <b>PME</b>. « Même au niveau des grands groupes, lorsqu’on analyse les parts de marché à l’export détenues sur le continent africain (tous domaines confondus), on se rend compte que les entreprises marocaines exportent principalement sur Djibouti, le Sénégal, la Mauritanie et la Côte d’Ivoire », confirme-t-il.<br/><br/>
        
        D’ailleurs, « ces quatre pays représentent pratiquement 50% de leur volume à l’export en 2019 ». Le journal avance une méconnaissance du marché africain comme cause. Il soutient que « l’évolution du marché africain impose flexibilité et capacité d’adaptation aux changements ». D’où la nécessité pour les <span className='font-semibold'>PME</span> marocaines de se mettre à niveau pour pouvoir affronter ce marché.<br/><br/>
        
        Les Inspirations ÉCO rappelle les énormes opportunités de développement présentes sur les autres pays du continent dans un contexte où le commerce intra-africain croît à un rythme rapide. Le tout favorisé par la signature de l’accord commercial sur la zone franche continentale africaine qui engage les pays à supprimer les droits de douane sur 90% des marchandises.<br/><br/>
        
        Bref, parallèlement, il remarque un changement de comportement des consommateurs et un engouement pour les produits africains à l’extérieur. « Ce positionnement favorable peut être un bon levier pour les entreprises marocaines afin de saisir les nombreuses opportunités qu’offre le continent, notamment pour accéder à de nouveaux marchés, s’approvisionner en intrants à des coûts permettant une meilleure compétitivité de nos industries, et pour investir dans des écosystèmes complémentaires à nos métiers mondiaux (automobile, électricité et électronique, textile, agriculture et agroalimentaire) », conclut le quotidien.<br/><br/>
        
        Par Rachid Al Arbi<br/><br/>
        
        Crédit : <strong><a  href='https://fr.le360.ma/economie/internationalisation-des-entreprises-marocaines-difficile-de-franchir-le-pas-253057/#:~:text=Internationalisation%20des%20entreprises%20marocaines%3A%20difficile%20de%20franchir%20le%20pas,-Par%20Rachid%20Al&text=Kiosque360.,clients%20marocains%20principalement%20en%20Afrique.'>Le360</a></strong>
      </p>
    `,
    author: 'Rachid Al Arbi',
    link: 'https://fr.le360.ma/economie/internationalisation-des-entreprises-marocaines-difficile-de-franchir-le-pas-253057/#:~:text=Internationalisation%20des%20entreprises%20marocaines%3A%20difficile%20de%20franchir%20le%20pas,-Par%20Rachid%20Al&text=Kiosque360.,clients%20marocains%20principalement%20en%20Afrique.'
  },
  {
    id: 2,
    title: "Startups – 10 questions à poser à un entrepreneur avant d’investir",
    category: "Startup",
    date:'mars 27, 2022',
    description: "Dans l’optique de réduire le risque lié à vos investissements, il faut vous assurer de diversifier votre portefeuille, investir dans des entreprises de différents stades de maturité et appartenant à différents secteurs d’activité. D’autres éléments, inhérents à chaque projet, doivent également guider votre décision. Comment les déterminer ? Quelles sont les questions essentielles à poser à un dirigeant d’entreprise ? Comment évaluer sa capacité à développer son entreprise de façon pérenne ?",
    imageUrl: image2,
    content: `
    Startups, avant de réaliser un investissement, il est nécessaire d’évaluer de la manière la plus objective possible les entreprises qui vous intéressent ainsi que leurs chances de réussite avant de prendre une décision.<br/><br/>
    
    <strong>Une stratégie d’investissement qui vous correspond</strong><br/><br/>
    Dans l’optique de réduire le risque lié à vos investissements, il faut vous assurer de diversifier votre portefeuille, investir dans des entreprises dont les stades de maturité sont différents mais également appartenant à différents secteurs d’activité.<br/><br/>

    D’autres éléments, inhérents à chaque projet, doivent également guider votre décision. Comment les déterminer ? Quelles sont les questions essentielles à poser à un dirigeant d’entreprise ? Comment évaluer sa capacité à développer son entreprise de façon pérenne ? <br/><br/>

    <strong>10 questions clés à poser aux entrepreneurs</strong><br/><br/>

    À l’occasion de la finale du 15ème concours Petit Poucet dédié à l’entrepreneuriat étudiant, qui s’est déroulée dans les locaux d’Anaxago au mois de Janvier, 30 jeunes startups ont pitché devant des entrepreneurs à succès.<br/><br/>

    Le Jury du Concours est composé de nombreux entrepreneurs phares comme Sébastien Forest (fondateur d’Allo Resto) ou encore Julian Kabab (fondateur de Flashgap).<br/><br/>

    Découvrez ci-dessous, les 10 questions que vous pourriez poser aux entrepreneurs qui vous intéressent avant de considérer un investissement.<br/><br/>

    <strong>1. À qui adressez-vous votre produit ?</strong><br/><br/>
    Avoir une idée c’est bien, développer un prototype c’est encore mieux, mais encore faut-il savoir à qui l’entrepreneur souhaite vendre son produit innovant...<br/><br/>

    <strong>2. Quel est votre business modèle ?</strong><br/><br/>
    Le business model est l’un des aspects les plus déterminants de l’entreprise. D’où viendront les revenus de l’entreprise et quand va-t-elle atteindre son seuil de rentabilité ? ...<br/><br/>

    <strong>3. Quel est votre élément différenciant face à votre concurrence ?</strong><br/><br/>
    Lorsqu’un marché est identifié se dessinent alors les acteurs concurrents, une source d’information essentielle quant à la percée du marché par la startup en question...<br/><br/>

    <strong>4. Avez-vous un ou des associés ?</strong><br/><br/>
    La structuration de l’équipe fondatrice est très importante, il est avantageux que les fondateurs détiennent des compétences et des aptitudes différentes et complémentaires...<br/><br/>

    <strong>5. Quel est votre plan de développement ?</strong><br/><br/>
    La timeline de développement d’entreprise doit être très structurée au départ. L’entrepreneur va devoir gérer de nombreux aspects, qui vont déterminer l’existence de l’entreprise...<br/><br/>

    <strong>6. Qu’est-ce qui vous pousse à entreprendre ?</strong><br/><br/>
    Le choix de l’entrepreneuriat comme activité professionnelle est motivé par différentes raisons...<br/><br/>

    <strong>7. Pourquoi ne pas travailler dans l’une des entreprises leaders de votre marché ?</strong><br/><br/>
    Cette question est capitale, en réalité il faut pouvoir mesurer l’investissement de l’entrepreneur, sa capacité à gérer le challenge de l’entrepreneuriat...<br/><br/>

    <strong>8. Quels partenaires clés avez-vous réussi à convaincre pour encadrer et optimiser votre offre ?</strong><br/><br/>
    Réussir en tant qu’entrepreneur demande également de connaître parfaitement l’écosystème dans lequel on évolue...<br/><br/>

    <strong>9. Comment feriez-vous sortir d’éventuels investisseurs ?</strong><br/><br/>
    La projection future de l’entreprise selon la vision de l’entrepreneur est un élément-clé pour les investisseurs potentiels...<br/><br/>

    <strong>10. Est-ce que vous êtes l’Homme de la situation susceptible de réussir ce pari ?</strong><br/><br/>
    Plusieurs individus peuvent détenir la même idée et la développer au même moment, cependant tous n’auront pas les ressources nécessaires ou les compétences suffisantes...<br/><br/>

    <i><strong>Crédit : Anaxago</strong></i><br/><br/>
    https://www.anaxago.com/aller-plus-loin/academy/strategies-investissement/10-questions-a-poser-a-un-entrepreneur-avant-dinvestir-dans-sa-startup">
  `
  },  
  {
    id: 3,
    title: "Programme Startup Maroc",
    category: "Startup",
    date:'mars 27, 2022',
    description: "Le projet vise à accélérer le développement de l'écosystème des start-ups au Maroc et à mettre en place une vitrine du secteur à l'international, en fédérant les différentes parties prenantes autour d'une plateforme digitale accompagnant les start-ups tout au long de leur cycle de vie, avec des mesures concrètes en faveur de leur développement.",
    imageUrl: image3,
    content: `
    Programme Startup,<strong>Consistance/périmètre du projet</strong><br/><br/>
    Le projet vise l’accélération du développement de l’écosystème des start-ups au Maroc et la mise en place d’une vitrine du secteur à l’international, en fédérant les différentes parties prenantes autour d’une plateforme digitale accompagnant les start-ups tout au long de leur cycle de vie, en y intégrant des mesures concrètes en faveur de leur développement.<br/><br/>

    Le projet se matérialise par la mise en place de la plateforme Labélisation des Jeunes Entreprises Innovantes (JEI) permettant à l’entreprise de bénéficier des mesures suivantes:<br/><br/>

    <ul class="blog-list">
      <li class="blog-list-li">Régler les importations de services liés à leurs activités par cartes de paiement internationales au titre du « Commerce Electronique », à hauteur de 1.000.000 dirhams par année civile;</li>
      <li class="blog-list-li">Réaliser des investissements à l’étranger selon les dispositions de l’article 169 bis de l’Instruction Générale des Opérations de Change.</li>
    </ul>
    
    Depuis son lancement, plus de 251 entreprises ont été labelisées JEI.<br/><br/>

    De la plateforme Startuphub maroc visant à répondre, de manière progressive, aux doléances exprimées par les startups marocaines. L’objectif de ladite plateforme est également de valoriser et d’augmenter la visibilité de l’ensemble des initiatives portées par les acteurs de l’Ecosystème, telles que l’accès aux mesures d’accompagnement et d’appui proposés par les institutions des secteurs publics, privés et associatifs.<br/><br/>

    <strong>Bénéfices attendus</strong><br/><br/>
    <ul class="blog-list">
      <li class="blog-list-li">Accès à un dispositif structuré de labélisation des start-ups, leur référencement, ainsi que l’accès au nouveau dispositif de l’Office des Changes permettant de bénéficier d’un ensemble de mesures;</li>
      <li class="blog-list-li">Augmentation de la visibilité et de la portée des initiatives individuelles prises par les acteurs et évaluation de leurs positionnements respectifs pour consolider le parcours proposé aux start-ups;</li>
      <li class="blog-list-li">Simplification de l’accès au marché en mettant en relation l’offre (les start-ups) et la demande (les entreprises et institutions publiques);</li>
      <li class="blog-list-li">Animation de l’écosystème de start-ups en permettant une communication directe, ciblée et centralisée (réglementation, nouveaux inscrits, networking, événements…).</li>
    </ul><br/><br/>

    <i><strong>Crédit : Agence de Développement Digital</strong></i><br/><br/>
    Lien:<a href="https://www.add.gov.ma/startup-program">https://www.add.gov.ma/startup-program</a>
  `
  },  
  {
    id: 4,
    title: "Start-up et innovation jouera un rôle déterminant pour la reprise économique",
    category: "Fournisseur",
    date:'mars 27, 2022',
    description: "Salma Kabbaj : L’écosystème start-up a effectivement subi de plein fouet la crise de la Covid-19, qui a amplifié les difficultés habituelles d’accès au marché. En effet, dans un contexte d’incertitude, beaucoup d’entreprises ont mis en stand-by leurs programmes d’innovation et ont adopté une posture plus frileuse par rapport à la mise en place de nouveaux partenariats, en particulier avec les start-up.",
    imageUrl: image4,
    content: `
    <p>Le Maroc se situe en 8ème position en termes de fonds levés par les start-up en 2020, avec 11 millions de dollars.</p>
    <p>Il faut que les grandes entreprises et les institutions publiques s’ouvrent à des partenariats avec des start-up pour aborder la reprise avec une compétitivité renforcée.</p>
    <p>Entretien avec Salma Kabbaj, co-fondatrice d’Impact Lab.</p>
    <p>Propos recueillis par B. Chaou.</p>

    <strong>Finances News Hebdo : Par quoi s’est matérialisé l’impact de la crise de la Covid-19 sur les start-up marocaines ?</strong><br/><br/>
    <strong>Salma Kabbaj : </strong>L’écosystème start-up a effectivement subi de plein fouet la crise de la Covid-19, qui a amplifié les difficultés habituelles d’accès au marché. En effet, dans un contexte d’incertitude, beaucoup d’entreprises ont mis en stand-by leurs programmes d’innovation et ont adopté une posture plus frileuse par rapport à la mise en place de nouveaux partenariats, en particulier avec les start-up. S’est ajouté à cela un allongement des délais de paiement, amenant près de 70% des start-up à faire face à des difficultés de trésorerie majeures en 2020. Mais cette crise a également accéléré certains changements de comportements, comme l’augmentation des achats sur Internet ou l’accélération de la digitalisation des entreprises, qui ont offert de nouvelles opportunités de développement aux start-up. Certaines start-up marocaines ont même eu un rôle clé pour assurer la continuité des activités pendant la période de confinement, que ce soit dans la santé, l’éducation ou la logistique.<br/><br/>

    <i><strong>F.N.H. : A l’aune de la crise actuelle, comment l’innovation peut-elle être un moyen de relance économique ?</strong></i><br/><br/>
    <strong>S. K. :</strong> Le contexte dans lequel nous vivons depuis plus d’un an est caractérisé par des niveaux d’incertitudes inégalés liés au contexte sanitaire, ainsi qu’à l’accélération des changements dans les comportements des consommateurs. A titre d’exemple, le nombre de transactions réalisées par cartes bancaires au Maroc a augmenté de 43% en 2020. Dans ce contexte, il est devenu encore plus nécessaire pour les entreprises de revoir leurs organisations et leur processus internes pour être plus agiles, plus à l’écoute de l’évolution de leurs parties prenantes clés, qu’elles soient internes ou externes.<br/><br/>

    <i><strong>F.N.H. : Étant des acteurs du tissu économique, comment les start-up peuvent-elles contribuer à la reprise ?</strong></i><br/><br/>
    <strong>S. K.</strong> : Dans le contexte actuel, les start-up sont plus que jamais des partenaires clés pour les grandes entreprises. Elles ont l’agilité qui leur permet d’évoluer rapidement dans un contexte en changement permanent. Elles sont proches de la réalité de leurs utilisateurs et savent s’y adapter facilement. Elles maîtrisent les nouvelles technologies et savent capitaliser sur la data pour adresser des enjeux opérationnels clés. Les start-up marocaines ont d’ailleurs brillamment démontré leur impact potentiel tout au long de cette dernière année en déployant des solutions innovantes permettant par exemple aux entreprises de continuer à opérer à distance, aux individus d’accéder à des services de santé ou d’éducation à distance, ou aux autorités locales d’assurer le respect des règles de confinement. Il faut maintenant que les grandes entreprises et les institutions publiques s’ouvrent activement à ces partenariats qui leur permettront d’aborder la reprise avec une compétitivité renforcée.<br/><br/>

    <i><strong>F.N.H. : Quel rôle les start-up doivent-elles jouer dans la recherche et l’innovation ? Comment les encourager dans ce sens ?</strong></i><br/><br/>
    <strong>S. K.</strong> : Les start-up sont un maillon clé entre le monde de la recherche et le marché. Ce sont elles qui permettent de transformer des inventions en innovations, c’est-à-dire en produits ou services innovants qui adressent un besoin réel et pour lesquels des clients sont prêts à payer. Aujourd’hui, il existe au Maroc très peu de ponts entre les acteurs de la recherche et l’écosystème start-up. Or, les synergies sont importantes de part et d’autre. D’un côté, les chercheurs pourraient assurer un débouché commercial à leurs travaux à travers des collaborations avec les start-up.<br/><br/>

    De l’autre côté, les start-up pourraient bénéficier des expertises et infrastructures des centres de recherche pour renforcer la compétitivité de leurs solutions. De manière générale, il est essentiel de créer des espaces d’échange et d’encourager la collaboration entre universités, centres de recherche, start-up, grandes entreprises, institutions publiques, etc. Cela peut passer par des mécanismes tels que les crédits d’impôts recherche permettant aux entreprises de déduire de leurs impôts les dépenses engagées dans des démarches collaboratives de recherche et développement.<br/><br/>

    <i><strong>F.N.H. : Pourquoi les start-up marocaines ont-elles beaucoup de mal à se développer ?</strong></i><br/><br/>
    <strong>S. K.</strong> : L’écosystème marocain des start-up a connu une belle dynamique sur les cinq dernières années. Il suscite d’ailleurs un intérêt croissant, que ce soit de la part des institutions publiques, des organisations internationales ou des entreprises. Cependant, les start-up marocaines font toujours face à des défis qui rendent l’émergence de success stories difficile. Ces défis incluent en premier lieu la difficulté d’accéder aux marchés : décrocher un contrat pour une start-up au Maroc reste un processus long et complexe, sans parler des délais de paiement qui suivent. On peut également citer d’autres défis, comme le manque de cadre réglementaire et fiscal adapté, le coût élevé des ressources humaines, la difficulté de lever du financement, etc. L’écosystème start-up marocain a d’ailleurs pris du retard, même par rapport aux autres écosystèmes africains. Le Maroc se situe ainsi en 8ème position en termes de fonds levés par les start-up en 2020, avec 11 millions de dollars vs 269 millions de dollars pour l’Egypte par exemple.<br/><br/>

    <i><strong>F.N.H. : Quels sont les moyens qui les aideraient à émerger davantage ?</strong></i><br/><br/>
    <strong>S. K.</strong> : Je pense qu’aujourd’hui, il est temps pour le Maroc d’exprimer une ambition claire de se positionner parmi les hubs d’innovation africains, et de se doter d’une stratégie start-up volontariste et ambitieuse qui reflète le positionnement économique de notre pays sur le continent. Les initiatives mises en place historiquement au Maroc se sont focalisées principalement sur la mise à disposition de financement pour les start-up. Mais le décollage de l’écosystème start-up marocain ne se fera qu’en déverrouillant en parallèle les différents facteurs clés, du cadre réglementaire agile à la disponibilité de financement, en passant par un accès facilité au marché et un écosystème d’accompagnement renforcé. Les acteurs de l’accompagnement jouent un rôle fondamental pour accélérer la montée en puissance de l’écosystème start-up marocain.<br/><br/>

    Selon une étude conduite par BCG aux Etats-Unis, l’accompagnement augmente les chances de survie d’une start-up de 50%. C’est ce qui explique que la France ait injecté 200 millions de dollars au niveau des incubateurs et accélérateurs pour permettre au pays de se positionner sur la scène start-up internationale. Depuis maintenant 2 ans, les acteurs marocains de l’accompagnement de start-up se sont regroupés dans le cadre d’une Fédération dédiée, MSEC, qui œuvre activement pour renforcer le positionnement de l’écosystème start-up marocain à l’échelle continentale.<br/><br/>

    <i><strong>F.N.H. : Comment, au niveau d’Impact Lab, accompagnez-vous les start-up ? Et qui sont vos partenaires ?</strong></i><br/><br/>
    <strong>S. K.</strong> : Nous avons accompagné jusqu’à présent plus de 170 start-up, basées au Maroc, mais aussi dans 17 autres pays africains. Nous offrons aux start-up un accompagnement opérationnel pour accélérer leur mise sur le marché et leur croissance. Nos programmes reposent fortement sur du coaching personnalisé, délivré par des experts internes d’Impact Lab avec plus de 17 ans d’expérience professionnelle chacun. Nous donnons également accès à des formations aux méthodologies de l’innovation, à des réseaux d’experts et à un espace de co-working gratuit situé en plein cœur de Casablanca.<br/><br/>

    Impact Lab est labellisé par la Caisse centrale de garantie dans le cadre du programme Innov Invest, ce qui nous permet d’octroyer aux start-up des financements allant jusqu’à 700.000 DH par projet. En parallèle, nous accompagnons les entreprises et les institutions publiques, telles qu’inwi, l’OCP ou Coca-Cola, dans l’accélération de leurs dynamiques de transformation interne ou de leurs collaborations avec des start-up. A titre d’exemple, notre partenariat avec inwi a permis de lancer 2 appels à projets à destination des start-up, dont le dernier portait sur les solutions innovantes pour la digitalisation du secteur agricole au Maroc.<br/><br/>
    <i><strong>F.N.H. : Quels sont les secteurs dans lesquels vous êtes le plus présents ? Pourquoi ces secteurs-là ?</strong></i><br/><br/>

<strong>S. K.</strong> : Même si nous n’affichons pas pour l’instant de spécialisation sectorielle, nos différents programmes nous ont permis de développer une expérience forte et des réseaux de start-up à haut potentiel dans quelques secteurs clés, notamment la fintech, l’agritech, la mobilité et l’inclusion.  Ces secteurs partagent un point commun : ils offrent des opportunités majeures d’impact social ou environnemental à l’échelle africaine, qui s’inscrivent dans la vision d’Impact Lab d’accompagner la transformation des modèles socioéconomiques existants. Ce sont également des secteurs sur lesquels le Maroc dispose d’atouts qui lui permettraient de s’imposer en tant que hub d’innovation à l’échelle africaine.<br/><br/>

<i><strong>Crédit : Finances News Hebdo</strong></i><br/><br/>

Lien : <a href="https://fnh.ma/article/actualite-economique/start-up-l-innovation-jouera-un-role-determinant-pour-la-reprise-economique">https://fnh.ma/article/actualite-economique/start-up-l-innovation-jouera-un-role-determinant-pour-la-reprise-economique</a>
  `
    
  },
  {
    id: 5,
    title: "Quelles sont les clés pour devenir un bon grossiste",
    category: "Fournisseur",
    date:'mars 27, 2022',
    description: "On ne devient pas un bon grossiste sur un coup de tête. En effet, pour réussir dans le secteur, certaines qualités sont indispensables. Mais il est également important de mettre en place des stratégies efficaces. Mais quelles sont les solutions pour points clés pour réussir ?",
    imageUrl: image5,
    content:`Grossiste, on ne devient pas un bon grossiste sur un coup de tête. En effet, pour réussir dans le secteur, certaines qualités sont indispensables. Mais il est également important de mettre en place des stratégies efficaces. Mais quelles sont les solutions pour points clés pour réussir ?<br/><br/>

    <strong>Les qualités d’un bon grossiste</strong><br/><br/>
    
    Vous voulez devenir grossiste ? Vous devez être un commercial de haut niveau. Pourquoi ? Parce que vous n’allez pas négocier avec des particuliers, mais avec des investisseurs. Et la somme en jeu est très importante. Donc, si vous perdez un client, vous perdez quelques milliers d’euros… Ce serait vraiment dommage !!! De même, avant d’entrer dans le secteur, vous devez faire une prospection et une étude du marché pour avoir une petite idée de vos clients potentiels. Un bon niveau en marketing est donc un atout considérable.<br/><br/>
    
    En outre, le site doit se situer à un endroit proche des locaux des cibles. Cela les incitera à vous choisir plutôt qu’un autre grossiste parce que les frais de déplacement seront moindres et ils gagneront du temps. En outre, il faudrait également être un bon négociateur pour convaincre les prospects. Et enfin, pour montrer sa crédibilité, le respect des engagements est essentiel que ce soit en termes de délais, de prix ou de quantité.<br/><br/>
    
    <strong>Le tarif et le délai de livraison</strong><br/><br/>
    
    Vous envisagez de devenir grossiste et vous voulez percer le marché ? Il faudrait que vos prix soient très attractifs. De la sorte, limitez les intermédiaires. Donc si possible, commandez vos articles à la source. Cela vous permettra de bénéficier des tarifs les moins chers sur le marché et d’obtenir une marge plus importante. D’ailleurs, de temps en temps, pour fidéliser vos clients, vous pourrez leur offrir des remises exceptionnelles.<br/><br/>
    
    Pour ceux qui souhaitent <strong>devenir revendeur en ligne</strong>, ils peuvent réduire les coûts en limitant les stocks. Ils peuvent donc se passer des entrepôts qui requièrent souvent un grand budget.<br/><br/>
    
    Par ailleurs, pour convaincre à coup sûr vos clients potentiels, proposez un délai de livraison relativement court. Cependant, assurez-vous que vous pourrez honorer vos engagements sinon c’est votre crédibilité qui sera en jeu.<br/><br/>
    
    <strong>L’internet pour développer les activités !</strong><br/><br/>
    
    Internet est une vraie mine d’or pour trouver de nouveaux clients. Alors, n’hésitez pas à ouvrir votre boutique en ligne. Devenir revendeur en ligne vous permettra d’être plus proche de vos cibles et de gagner leur confiance. De plus, vous pourriez mettre à jour toutes les informations concernant votre entreprise ainsi que la liste des produits disponibles. Ne ratez pas cette occasion pour agrandir votre part de marché. En tout cas, en choisissant de devenir grossiste, vous pourrez espérer bénéficier d’un meilleur rendement.<br/><br/>
    
    <i><strong>Crédit : Import Grossiste</strong></i><br/><br/>
    
    “Quelles sont les clés pour devenir un bon grossiste ?<br/><br/>
    
    <a href="https://www.importgrossiste.fr/quelles-sont-les-cles-pour-devenir-un-bon-grossiste">https://www.importgrossiste.fr/quelles-sont-les-cles-pour-devenir-un-bon-grossiste</a><br/><br/>`
  },
  {
    id: 6,
    title: "Les TPME représentent 93% des entreprises marocaines en 2019",
    category: "Fournisseur",
    date:'mars 27, 2022',
    description: "La structure des entreprises au Maroc est à 93% constituée de très petites et moyennes entreprises (TPME), contre 7% de grandes entreprises (GE), selon le Haut-commissariat au Plan (HCP). Les TPME se répartissent entre les très petites entreprises (64%) et les petites et moyennes entreprises (29%), selon les résultats de l'enquête nationale menée par le HCP auprès des entreprises en 2019.",
    imageUrl: image6,
    content:`Entreprises marocaines, la structure des entreprises au Maroc est à 93% constituée de très petites et moyennes entreprises (TPME), contre 7% de grandes entreprises (GE), selon le Haut-commissariat au Plan (HCP).<br/><br/>

    Les TPME se répartissent entre les très petites entreprises (64%) et les petites et moyennes entreprises (29%), selon les résultats de l’enquête nationale menée par le HCP auprès des entreprises en 2019.<br/><br/>
    Cette enquête, qui s’est déroulée entre janvier et juillet, fait également ressortir que près des deux-tiers (63%) des entreprises sont concentrées dans l’espace régional Casablanca-Tanger (39% implantées à Casablanca-Settat, 15% à Rabat-Salé-Kenitra et 9% à Tanger-Tétouan-Al Hoceima).<br/><br/>
    
    En outre, le HCP indique que la répartition par secteur d’activité montre que près de 42% des entreprises opèrent dans le secteur des services, 27% dans le commerce, 21% dans la construction et 10% dans l’industrie.<br/><br/>
    
    Les TPE (44%) et les PME (42%) sont fortement concentrées dans les services et faiblement présentes dans l’industrie avec respectivement un poids de 8% et 11%, relève la même source, notant qu’en revanche, les grandes entreprises sont quasi-équitablement réparties entre les secteurs de l’industrie (26%), de la construction (23%), du commerce (27%) et des services (24%).<br/><br/>
    
    Au niveau régional, poursuit le HCP, les services sont concentrés dans les régions de Casablanca-Settat (39% des entreprises) et Marrakech-Safi (11%).<br/><br/>
    
    Dans le secteur du commerce, plus de la moitié (58%) des entreprises sont implantées à Casablanca-Settat (44%) et Rabat-Salé-Kénitra (14%). Le secteur de l’industrie est, quant à lui, fortement concentré dans la région Casablanca-Settat (47%). Dans la région Tanger-Tétouan-Al-Hoceima, il représente 12%.<br/><br/>
    
    S’agissant de la construction, elle est moins polarisée que le reste des secteurs d’activité, avec 29% des unités implantées dans la région Casablanca-Settat, 16% à Rabat-salé-Kénitra et 11% à Fés-Meknès.<br/><br/>
    
    L’enquête du HCP couvre l’ensemble du territoire national et cible toutes les entreprises organisées qui détiennent une comptabilité formelle. Elle concerne, en effet, les entreprises opérant dans les secteurs de l’industrie, de la construction, du commerce et des services et exclut de ce champ les secteurs financier, de l’agriculture et de l’informel.<br/><br/>
    
    Les entreprises ciblées ont été classées selon le double critère du chiffre d’affaires (CA) et des effectifs employés par référence aux textes juridiques qui en fixent les limites au Maroc. Ainsi dans cette enquête, les TPME sont celles dont le CA est inférieur à 75 millions de dirhams (MDH) et des effectifs inférieurs à 200 employés. Les TPE sont définies comme étant les unités ayant un CA de moins de 3 MDH et un effectif inférieur à 10 employés, tandis que les GE sont celles dont le CA est supérieur à 75 MDH et des effectifs dépassant 200 personnes.<br/><br/>
    
    L’échantillon de l’enquête a été conçu selon un sondage aléatoire stratifié basé sur le critère des effectifs et celui de l’activité comme variables de stratification. De par sa taille, il assure la représentativité requise par secteur d’activité et par catégorie d’entreprises.<br/><br/>
    
    Cet échantillon est tiré à partir du répertoire statistique d’entreprises géré par le HCP. L’enquête s’est ainsi adressée à de 2.101 unités, 769 représentant l’industrie, 371 la construction, 294 le commerce et 667 les services marchands non financiers.<br/><br/>
    
    <i><b>Crédit : Finances News Hebdo Maroc</i> &nbsp<i><strong><a href="https://fnh.ma/article/actualite-economique/les-tpme-representent-93-des-entreprises-marocaines-en-2019">https://fnh.ma/article/actualite-economique/les-tpme-representent-93-des-entreprises-marocaines-en-2019</strong></a></i>`
  },
  {
    id: 7,
    title: "Les 7 piliers de la sagesse pour réussir à l’export",
    category: "Expert",
    date:'mars 27, 2022',
    description: "« L’export ne doit pas être vécu comme une simple recherche de débouchés, mais comme une véritable ambition de l’entreprise de devenir internationale », commence Frédéric Rossi, directeur général délégué export de Business France, structure qui accompagne chaque année 10.000 entreprises dans leurs démarches à l'export. Vous aurez donc intérêt à préparer, impliquer et engager le maximum de personnes possibles sur toute la chaîne de valeur de votre entreprise. Tous les salariés doivent se sentir investis et devenir des supporters de cette nouvelle ambition.",
    imageUrl: image7,
    content:`L’export :<br/><br/>

<strong>1. Motivez vos troupes ! L’international est une ambition commune</strong><br/><br/>

« L’export ne doit pas être vécu comme une simple recherche de débouchés, mais comme une véritable ambition de l’entreprise de devenir internationale », commence Frédéric Rossi, directeur général délégué export de Business France, structure qui accompagne chaque année 10.000 entreprises dans leurs démarches à l’export. Vous aurez donc intérêt à préparer, impliquer et engager le maximum de personnes possibles sur toute la chaîne de valeur de votre entreprise. Tous les salariés doivent se sentir investis et devenir des supporters de cette nouvelle ambition. « C’est une démarche globale. L’exportation doit être intégrée dans la stratégie de l’entreprise. »<br/><br/>

<strong>2. Flairez les opportunités ! L’export doit allier business et géographie</strong><br/><br/>
« Sur le papier, beaucoup de pays paraissent alléchants mais leur accessibilité peut être problématique », assure le spécialiste de l’export. Faites donc une cartographie des marchés potentiels et envisagez les à l’aune de plusieurs critères : barrières tarifaires à l’entrée, proximité géographique, environnement concurrentiel, normes et régulations, etc. Enfin, soyez attentif au « place to market » comme il peut y avoir un « time to market ». « Votre produit doit être adapté à la maturité du marché visé, au pouvoir d’achat des populations et au contexte réglementaire. »<br/><br/>

<strong>3. Vendez la différence ! L’avantage concurrentiel détermine le succès</strong><br/><br/>

« Si votre produit est déjà vendu sur le marché visé, le vôtre sera forcément moins concurrentiel et moins adapté ». Vérifiez au préalable que votre offre se différencie de la concurrence locale dans sa forme, ses fonctionnalités ou son service. « Il suffit d’un facteur de différenciation quelconque, son mode de distribution, son accessibilité, sa connectivité, etc. » Pour apprécier le potentiel de votre offre, testez votre produit ou service auprès d’un panel de consommateurs ou auprès d’experts pays. Vous devez également vous poser la question de savoir si elle est exportable telle quelle, ou si vous devez l’adapter au marché que vous prospectez.<br/><br/>

<strong>4. Soyez patient ! Une stratégie export se construit étape par étape</strong><br/><br/>

« Le temps moyen pour avoir un business pérenne avec des flux réguliers est de deux ans », prévient l’expert. Il faut d’abord prendre le temps de nouer des relations personnelles avec des partenaires locaux, et vous rendre plusieurs fois sur place. N’allez pas trop vite mais au rythme de vos possibilités financières et humaines. « Les start-ups qui fonctionnent souvent en effectifs restreints, sont emportées par un tourbillon lorsqu’elles se lancent à l’international. » Pour pouvoir garder le cap sur le développement de l’entreprise, sur la R&D et sur le financement, entourez-vous au maximum et sous-traitez ce qui n’est pas votre coeur de métier, ou que d’autres savent mieux faire que vous. Et plutôt que de vous lancer sur tous les marchés à la fois, mieux vaut séquencer votre approche.<br/><br/>

<strong>5. Adoptez le « lean export » ! Un objectif se révise en fonction de la réalité du terrain</strong><br/><br/>

Un business plan export, établi sur deux à trois ans, permet de chiffrer les investissements et les coûts nécessaires, et d’estimer les revenus potentiels. N’imaginez pas pouvoir copier coller ! Il doit y avoir autant de business plans que de pays ou de marchés. Pour structurer votre démarche, commencez par lister toutes les dépenses nécessaires pour approcher, puis pénétrer votre marché cible : participation à des salons, adaptation de l’offre, traduction de son site internet, recrutement de partenaires ou de salariés, frais juridiques…<br/><br/>

Pour réussir, il faut à la fois bien appréhender le développement, les caractéristiques culturelles et de business, le réseau de distribution, la réglementation en vigueur, etc. et actualiser en permanence ses différents business plans « en fonction des opportunités à saisir, des évolutions des marchés, de la rapidité du développement… »<br/><br/>

<strong>6. Twittez, chattez, textez ! Les canaux de communication préférés diffèrent selon les pays</strong><br/><br/>

« En Indonésie par exemple, envoyez des SMS pour fixer des rendez-vous alors qu’en Chine, utilisez Wechat », recommande Frédéric Rossi, directeur général délégué export de Business France. Selon les pays, utilisez les technologies les plus courantes pour échanger avec vos interlocuteurs : téléphone, réseaux sociaux ou messagerie instantanée… mais surtout adaptez-vous à eux, pas l’inverse. Soyez versatile quant à vos moyens de communication dans chaque pays. Chacun a ses codes et spécificités, ses us et ses coutumes locales. Ce petit conseil trivial vous fera gagner un temps précieux en communication.<br/><br/>

<strong>7. Montrez-vous ! Les VC rôdent dans les allées des salons internationaux</strong><br/><br/>

Dans le secteur de la Tech par exemple, pour attirer les venture capitalists (investisseurs), rien de tel que les salons, c’est là que l’on retrouve tous les acteurs mondiaux. Les salons sont à la fois utiles pour se faire remarquer, avoir une visibilité mondiale et être financé.<br/><br/>

<i><strong>Crédit : Les Echos Entrepreneurs</i></strong><br/><br/>

<a href="https://business.lesechos.fr/entrepreneurs/internationaliser-exporter/les-7-piliers-de-la-sagesse-pour-reussir-a-l-export-307204.php">https://business.lesechos.fr/entrepreneurs/internationaliser-exporter/les-7-piliers-de-la-sagesse-pour-reussir-a-l-export-307204.php</a>`
  },
  {
    id: 8,
    title: "Comment Trouver un Fabricant ou un Grossiste Pour votre Produit",
    category: "Expert",
    date:'mars 27, 2022',
    description: "Si vous avez lu nos articles précédents sur comment trouver des idées de niches et comment évaluer des produits pour la vente en ligne, il se pourrait que vous ayez déjà réussi à trouver quelques idées innovantes de produits. Il pourrait s'agir d'un moment intéressant pour un entrepreneur ; en effet, l’excitation peut vous servir d’élan et vous motiver à considérer plus sérieusement votre idée de produit.",
    imageUrl: image8,
    content:`Grossiste, Fabricant ?<br/><br/>

    Si vous avez lu nos articles précédents sur comment trouver des idées de niches et comment évaluer des produits pour la vente en ligne, il se pourrait que vous ayez déjà réussi à trouver quelques idées innovantes de produits. Il pourrait s’agir d’un moment intéressant pour un entrepreneur ; en effet, l’excitation peut vous servir d’élan et vous motiver à considérer plus sérieusement votre idée de produit.<br/><br/>
    
    Cependant, très souvent, plusieurs entrepreneurs se découragent et perdent leur élan à l’heure de devoir obtenir ou « sourcer » leurs produits. Que ce soit pour fabriquer votre propre produit ou trouver des fournisseurs auprès desquels acheter en gros, la tâche n’est souvent pas très facile.<br/><br/>
    
    Dans cet article, nous allons examiner les fondamentaux du « sourcing » de fournisseurs, de grossistes et de fabricants pour votre prochain projet. Nous allons examiner quelques endroits où vous pouvez faire vos recherches, la manière d’approcher les fournisseurs, et ce que vous devez leur demander.<br/><br/>
    
    <strong>Les Fondamentaux — Que Recherchez-Vous ?</strong><br/><br/>
    
    Dans le cadre de cet article, lorsque nous utilisons le mot fournisseur, nous faisons référence à toute personne qui possède la capacité de vous fournir des produits et du stock. Cela englobe les fabricants, les grossistes et les distributeurs.<br/><br/>
    
    Il existe des tonnes de ressources utiles que vous pouvez trouver en ligne simplement en faisant des recherches sur Google. Cependant, avant de commencer, il y a de petites choses que vous devez connaître.<br/><br/>
    
    D’abord, vous devez déterminer quel type de fournisseur vous recherchez. Cela vous aidera à déterminer la terminologie que vous avez besoin d’utiliser pour votre recherche.<br/><br/>
    
    Il y a plusieurs options, les plus courantes étant :<br/><br/>
    
    Un fabricant pour produire un article qui vous intéresse ou donner vie à votre propre idée de produit.
    Un fournisseur, qui pourrait aussi être un fabricant, un grossiste ou un distributeur pour acheter des marques et des produits qui existent déjà.<br/><br/>
    Un dropshipper pour fournir des produits et gérer les commandes de produits et de marques qui existent déjà.<br/><br/>
    <strong>Fournisseurs Nationaux ou Étrangers ?</strong><br/><br/>
    
    Une question courante qui se pose à l’heure de rechercher des fournisseurs, si vous comptez faire fabriquer votre produit ou l’acheter en gros pour le revendre, est de savoir si vous voulez vous approvisionner dans votre pays ou à l’étranger. L’étranger peut faire référence à tout autre endroit dans le monde, mais généralement, et dans le cadre de cet article, fera référence aux pays asiatiques comme la Chine, l’Inde et Taiwan.<br/><br/>
    
    Vous savez probablement déjà qu’il est toujours moins cher d’obtenir vos produits de l’étranger, mais mis à part l’investissement initial et le coût par unité, il y a beaucoup plus de choses à considérer pour prendre votre décision.<br/><br/>
    
    Les deux types de sourcing, au niveau national et à l‘étranger, présentent des avantages et inconvénients, que nous allons examiner ci-dessous :<br/><br/>
    
    <strong>Sourcing ou Approvisionnement au Niveau National</strong><br/><br/>
    
    Avantages<br/><br/>
    <ul class="blog-list">
    <li class="blog-list-li">Meilleurs standards de travail et meilleure qualité de fabrication</li><br/>
    <li class="blog-list-li">Une communication plus facile sans barrière linguistique</li><br/>
    <li class="blog-list-li">Avantage marketing pour la vente du produit, lié au fait qu’il soit fabriqué sur le territoire national</li><br/>
    <li class="blog-list-li">Plus facile de vérifier la réputation des fabricants</li><br/>
    <li class="blog-list-li">Délais de livraison moins longs</li><br/>
    <li class="blog-list-li">Meilleure protection des droits de propriété intellectuelle</li><br/>
    <li class="blog-list-li">Meilleurs sécurité de paiement et recours</li><br/>
    </ul><br/>
    Inconvénients<br/><br/>
    <ul class="blog-list">
    <li class="blog-list-li">Coûts de fabrication plus élevés</li><br/>
    <li class="blog-list-li">Moins de choix de produits (il y a plusieurs articles qui ne sont plus du tout fabriqués en Europe ou en Amérique du Nord)</li><br/>
    <li class="blog-list-li">Sourcing ou Approvisionnement à l’Étranger</li><br/>
    </ul><br/>
    Avantages<br/><br/>
    <ul class="blog-list">
    <li class="blog-list-li">Coûts de fabrication plus bas</li><br/>
    <li class="blog-list-li">Plusieurs fabricants parmi lesquels choisir</li><br/>
    <li class="blog-list-li">Des services simples d’utilisation comme Alibaba ont facilité la recherche de fournisseurs</li><br/>
    </ul>
    <br/>
    Inconvénients<br/><br/>
    <ul class="blog-list">
    <li class="blog-list-li">Diminution de la valeur perçue du point de vue des consommateurs</li><br/>
    <li class="blog-list-li">(Généralement) De bas standards de travail et de fabrication</li><br/>
    <li class="blog-list-li">Peu de protection de la propriété intellectuelle</li><br/>
    <li class="blog-list-li">Difficultés relatives à la langue et à la barrière de communication</li><br/>
    <li class="blog-list-li">Délais de livraison plus longs</li><br/>
    <li class="blog-list-li">Différences culturelles dans le domaine des pratiques d’entreprise</li><br/>
    <li class="blog-list-li">Procédures liées à l’importation de produits et au dédouanement</li><br/>
    <li class="blog-list-li">Moins de sécurité de paiement et de recours</li><br/>
    </ul>
    <br/><br/>
    <strong>Par Où Commencer Votre Recherche ?</strong><br/><br/>
    Une fois que vous aurez une idée plus précise de ce que vous recherchez exactement, et aussi des avantages et inconvénients du « sourcing » au niveau national et à l’étranger, par où allez-vous commencer votre recherche ? Naturellement, Internet est le meilleur endroit pour démarrer, mais il y a quelques endroits en particulier qui peuvent vous aider à avancer plus rapidement dans votre recherche.<br/><br/>
    
    <strong>Annuaires</strong><br/><br/>
    
    Les annuaires gratuits de fournisseurs en ligne peuvent constituer certaines des meilleures ressources. Ces annuaires peuvent contenir des profils de centaines ou milliers de fabricants, de grossistes et de fournisseurs. Ci-dessous, nous avons listé quelques-uns des annuaires les plus populaires regroupant des fournisseurs nationaux et étrangers.<br/><br/>
    
    <strong>Les Profondeurs de Google</strong><br/><br/>
    
    Au cours de la dernière poignée d’années, nous nous sommes habitués à pouvoir facilement effectuer des recherches sur Google et à trouver ce que nous recherchions au niveau des premiers résultats de recherche. Cependant, plusieurs fournisseurs n’ont pas suivi le rythme d’Internet, et en particulier, les changements de l’algorithme de Google. Leurs sites web sont généralement vieux, contiennent très peu d’informations, et sont très certainement mal optimisés pour le SEO.<br/><br/>
    
    Donc, comment allez-vous trouver des fournisseurs sur Google ? Pour la première fois dans l’histoire, vous allez devoir explorer la page dix des résultats de recherche de Google, et même au-delà. Vous allez devoir utiliser plusieurs termes de recherche. Par exemple, des requêtes de recherche comme « vente en gros », « grossiste » et « distributeur » pourraient être utilisées de manière interchangeable ; donc, vous devrez faire des recherches pour chacune d’entre elles.<br/><br/>
    
    Il serait astucieux de vous familiariser avec les raccourcis de recherche de Google (Google’s search shortcuts) pour améliorer la qualité des recherches, et donc les résultats.<br/><br/>
    
    <strong>Librairie Locale</strong><br/><br/>
    
    Vous devriez aussi penser à dépoussiérer votre carte de librairie et vous rendre à votre librairie locale. Plusieurs librairies paient des frais mensuels d’abonnement aux annuaires en ligne d’entreprises et de fournisseurs, comme le Scotts Online Business Directory ; que vous ne pourriez normalement pas consulter, à moins de payer de grandes sommes d’argent. Ces annuaires contiennent les profils de plusieurs fabricants, grossistes et distributeurs situés dans différentes régions du monde ; en fonction de l’annuaire en question.<br/><br/>
    
    Assurez-vous d’effectuer un appel à votre librairie locale à l’avance, et de demander si vous pouvez avoir accès à ces types d’annuaires privés. Pour les librairies plus grandes, vous pourriez avoir besoin de discuter avec le service commercial et le département informatique.<br/><br/>
    
    <strong>Recommandations</strong><br/><br/>
    
    Certains des meilleurs prospects peuvent provenir de recommandations de la part d’amis et de membres de famille. Les réseaux sociaux permettent de faciliter la diffusion de votre message, donc, assurez-vous d’utiliser ces canaux.<br/><br/>
    
    Alors que vous commencez à découvrir des fournisseurs, même s’ils ne correspondent pas exactement à ceux que vous recherchez, assurez-vous de leur demander s’ils peuvent vous orienter vers la bonne direction. Le fait qu’ils fassent partie de l’industrie en question signifie qu’ils auront sûrement de bons contacts, et plusieurs d’entre eux seront plus qu’heureux de vous recommander quelqu’un qui vous conviendrait mieux.<br/><br/>
    
    <strong>Autres Conseils De Recherche</strong><br/><br/>
    
    Une autre manière de rechercher des fournisseurs de produits est de rechercher des produits par leur code NACE.<br/><br/>
    
    NACE fait référence à la Nomenclature statistique des Activités économiques dans la Communauté Européenne, et il est presque certain que chaque industrie et chaque produit auxquels vous pourriez penser sont rattachés à un code NACE qui leur est propre. Quelquefois, les fabricants et fournisseurs pourraient lister leurs produits par le biais de leur code NACE ; ce qui pourrait rendre vos fabricants de produits et fournisseurs plus faciles à trouver, surtout si vous utilisez les annuaires professionnels.<br/><br/>
    
    En ce qui concerne l’Amérique du Nord et le Canada, on parle de code NAICS, qui fait référence au système de classification des industries d’Amérique du Nord.<br/><br/>
    
    Vous pouvez par exemple trouver l’annuaire NAICS sur Internet :<br/><br/>
    
    USA — NAICS Code<br/><br/>

    Canada — NAICS Code<br/><br/>
    
    <strong>Demander Un Devis</strong><br/><br/>
    
    Une fois que vous aurez trouvé un fournisseur approprié, comment allez-vous l’approcher ?<br/><br/>
    
    La question la plus importante que vous aurez est « combien ? ». Mais, avant de vous empresser d’envoyer au fournisseur votre demande de devis (ou RFQ en anglais [Request for a Quote]), prenez quelques minutes pour réfléchir aux sujets dont vous voudriez discuter, et aux questions que vous devriez poser. Le fait de planifier votre e-mail augmentera vos chances de recevoir une réponse, ainsi que les bonnes informations.<br/><br/>
    
    Voici quelques questions importantes à considérer pour l’envoi de votre e-mail aux fournisseurs :<br/><br/>
    <ul class="blog-list">
    <li class="blog-list-li">Quelle est votre quantité minimale de commande ? (Aussi connue sous le nom de MOQ en anglais, « minimum order quantity ».) Vous devez vous assurer que vous pouvez gérer leurs minimums et que vous pouvez vous les permettre. Cette quantité de commande minimum peut varier considérablement en fonction du produit et du fournisseur ; il est donc important d’aborder la question à l’avance.<br/><br/></li>
    <li class="blog-list-li">Quel est votre prix d’échantillon ? Vous voudrez probablement inspecter des échantillons avant de passer une commande complète. Le prix des échantillons varie, en fonction du produit et du fournisseur. Quelques fournisseurs qui reçoivent beaucoup de demandes pourraient changer le prix de vente au public et vous envoyer des échantillons à un prix réduit, alors que d’autres vous enverront des échantillons gratuitement.</li><br/><br/>
    <li class="blog-list-li">Quel est votre prix de production ? L’une des questions les plus importantes à poser est : « combien va coûter la fabrication du produit ? ». Vous devriez probablement leur demander le prix pour plusieurs quantités, afin de vous faire une idée de leur tarification, et pour savoir comment ils s’y prennent pour effectuer des réductions sur volume pour les grosses quantités.
    Quel est votre délai de fabrication ? Connaître le délai nécessaire pour produire votre commande est une considération importante, et en fonction de votre domaine d’activité, le temps peut être critique.</li><br/><br/>
    <li class="blog-list-li">Quelles sont vos conditions de paiement ? Plusieurs fournisseurs vont exiger des nouvelles entreprises de payer la commande totale à l’avance. Cela est important à savoir puisque le stock représente un coût majeur pour les startups e-commerce. Vous devriez aussi leur demander s’ils offrent des facilités de paiement pour les commandes futures.</li><br/><br/>
    <li class="blog-list-li">Les fournisseurs sont toujours submergés d’e-mails de demande de devis de la part d’acheteurs curieux qui ne sont pas très sérieux ; donc, il n’est pas inhabituel de voir plusieurs fournisseurs ne pas répondre à chaque demande. Les nouveaux entrepreneurs e-commerce se plaignent souvent du manque de réponses de la part des fournisseurs.</li><br/><br/>
    </ul>
    Donc, comment faire pour que l’on ne vous ignore pas ? Il y a quelques petites choses à éviter au moment de contacter des fournisseurs pour la première fois :<br/><br/>
    <ul class="blog-list">
    <li class="blog-list-li">E-mails trop longs : le premier e-mail que vous envoyez à un fabricant doit être clair et précis. Évitez d’en dire trop sur votre histoire et votre expérience. Le premier e-mail devrait être purement destiné à évaluer s’il existe une forte compatibilité entre vous et le fournisseur. Concentrez-vous sur ce qui intéresse vraiment les fournisseurs, comme les détails du produit que vous essayez de « sourcer ».</li><br/><br/>
    <li class="blog-list-li">Demander trop d’informations : les demandes ne sont pas toujours faciles à traiter pour le fournisseur. Il est important de demander quelques prix pour plusieurs quantités, mais évitez de demander trop d’informations. Posez vos questions de manière à connaître les détails essentiels, pour pouvoir évaluer s’il existe une compatibilité entre vous et le fournisseur.</li><br/><br/>
    <li class="blog-list-li">Mentionner une quantité de commande trop faible : si vous demandez un devis qui est bien en dessous de la commande minimale, vous risquez de ne pas recevoir de réponses de la part des fournisseurs. Si vous ne savez pas vraiment si votre commande est trop petite, envisagez de les appeler rapidement ou de leur envoyer une simple question par e-mail pour connaître d’abord leur commande minimale.</li><br/><br/>
    Finalement, si vous contactez des fournisseurs situés à l’étranger, gardez à l’esprit que dans plusieurs cas, ils pourraient bien se servir de programmes de traduction pour traduire votre e-mail, ainsi que leur réponse. Le fait de faire en sorte que vos e-mails soient courts, concis, bien formatés et sans erreurs d’orthographe pourra non seulement aider les fabricants, mais aussi vous permettre de recevoir de meilleures réponses. Aussi, lorsque vous posez vos questions, il est préférable de les numéroter, pour qu’ils puissent facilement répondre à chaque numéro. Cela vous permettra de faire en sorte que la communication soit claire et organisée.<br/><br/>
    </ul>
    Voici un exemple d’e-mail pour prendre contact avec vos fournisseurs :<br/><br/>
    
    <i>Bonjour,</i><br/><br/>
    
    Mon nom est Richard et je représente l’entreprise WYZ.<br/>
    
    Je suis intéressé par passer une commande pour le PRODUIT A. J’aimerais juste poser quelques questions au préalable :<br/><br/>
    
    1.Quelle est votre quantité minimale de commande ?<br/><br/>
    2.Quel est votre coût par unité pour la commande minimale, et celui pour une commande 3 fois plus importante que la commande minimale ?<br/><br/>
    3.Quelles sont les conditions de paiement pour vos nouveaux clients ?<br/><br/>
    <i>Je voudrais aussi commander un échantillon du PRODUIT A pour vérifier la qualité. Pouvez-vous s’il vous plaît m’envoyer le coût pour l’échantillon en incluant l’envoi à l’adresse suivante :</i><br/><br/>
    
    <i>Richard Lazazzera</i><br/>
    <i>80 Avenue Spadina, Étage 4</i><br/>
    <i>Toronto, ON, Canada</i><br/>
    <i>M5V 2J4</i><br/>
    
    <i>Merci,</i><br/>
    <i>Richard</i><br/><br/>
    
    Comme vous pouvez le voir à partir du modèle ci-dessus, c’est court, concis et le but est de s’assurer qu’il existe une forte compatibilité entre nous. J’ai aussi fait en sorte de commander directement un échantillon, au cas où il existerait en effet une compatibilité. Une fois que j’aurais reçu les échantillons, et s’ils me plaisent, je pourrais à ce moment-là aborder plus de détails ; ils sauront ainsi que je ne suis pas en train de gaspiller leur temps ni le mien.<br/><br/>
    
    <strong>Négocier Les Minimums</strong><br/><br/>
    
    Si c’est la première fois que vous recherchez un fournisseur, vous allez rapidement devoir vous familiariser avec les « quantités de commande minimales », aussi appelées « quantités de commande minimum ». Il n’est pas inhabituel pour un fabricant d’exiger un engagement de votre part, afin que vous achetiez des centaines ou même des milliers d’unités pour votre première commande ; selon le produit et le fabricant en question.<br/><br/>
    
    Les quantités de commande minimum sont difficiles à gérer si vous n’avez pas beaucoup d’argent à investir, ou si vous préférez être plus prudent et commencer doucement pour tester d’abord le marché, avant de passer de plus grosses commandes. La bonne nouvelle est que les quantités de commande minimum sont presque toujours négociables.<br/><br/>
    
    Avant de commencer à négocier, la première étape est de comprendre pourquoi le fournisseur a imposé un minimum. Est-ce parce qu’il y a beaucoup de travail à réaliser à l‘avance ? Ou peut-être que le fournisseur préfère travailler avec les gros acheteurs ? Le fait de connaître les raisons qui se cachent derrière le minimum vous aidera à mieux comprendre sa position, et vous permettra de mieux négocier ; et de proposer la meilleure contre-offre possible.<br/><br/>
    
    Une fois que vous aurez une meilleure compréhension de la position des fournisseurs, vous pourrez offrir une quantité de commande inférieure. Les compromis peuvent inclure : donner au fournisseur une avance pour une commande plus importante, produire de petites quantités à la fois, ou payer un prix plus élevé par unité.<br/><br/>
    
    <strong>Avez-vous Trouvé Votre Fournisseur ?</strong><br/><br/>
    
    Le sourcing de fournisseurs et de fabricants peut être un processus simple, et pour plusieurs personnes, une nouvelle expérience. Trouver les bons fournisseurs est une étape importante pour votre nouvelle entreprise, et ce n’est pas toujours facile à faire. Il est très facile de se décourager si les choses n’avancent pas comme prévu. Mais, dans la plupart des cas, vous devrez simplement faire preuve d’un peu plus de patience et de persévérance pour trouver le partenaire idéal pour votre nouvelle entreprise.<br/><br/>
    
    <i><strong>Crédit : Shopify France</strong></i><br/><br/>
    
    <a href="https://www.shopify.fr/Blogs/52714885-sourcing-comment-trouver-un-grossiste-un-fabricant-ou-un-fournisseur-pour-votre-idee-de-produit#:~:text=Vous%20allez%20devoir%20utiliser%20plusieurs,pour%20chacune%20d’entre%20elles.">https://www.shopify.fr/Blogs/52714885-sourcing-comment-trouver-un-grossiste-un-fabricant-ou-un-fournisseur-pour-votre-idee-de-produit#:~:text=Vous%20allez%20devoir%20utiliser%20plusieurs,pour%20chacune%20d’entre%20elles.</a>`
  },
  {
    id: 9,
    title: "Hydrogène vert : le Maroc, futur leader mondial",
    category: "Energies renouvelables et recyclage",
    date:'mars 27, 2022',
    description: "Dans un rapport consacré aux perspectives du marché mondial de l’hydrogène vert, l’Agence internationale des énergies renouvelables (IRENA) classe le Maroc parmi les cinq pays les mieux placés qui seront à même d'être les principaux producteurs d'hydrogène propre, aux côtés des Etats-Unis, du Chili, de l’Arabie Saoudite et de l’Australie.",
    imageUrl: image9,
    content:`Hydrogène vert, Dans un rapport consacré aux perspectives du marché mondial de l’hydrogène vert, l’Agence internationale des énergies renouvelables (IRENA) classe le Maroc parmi les cinq pays les mieux placés qui seront à même d’être les principaux producteurs d’hydrogène propre, aux côtés des Etats-Unis, du Chili, de l’Arabie Saoudite et de l’Australie.<br/><br/>

    Pour décarboner l’industrie et réduire les émissions de gaz à effet de serre, l’hydrogène vert est devenu une priorité dans les stratégies énergétiques de nombreux pays, dont les plus développés. Dans un récent rapport, «Géopolitique de la transformation énergétique: le facteur hydrogène», l’IRENA estime que d’ici 2050, l’utilisation de l’hydrogène pourrait atteindre jusqu’à 12 % de la consommation mondiale d’énergie.<br/><br/>
    
    L’émergence de cette nouvelle énergie bas carbone devrait également, selon ce document, modifier la géographie du commerce de l’énergie, alors que «le commerce traditionnel du pétrole et du gaz est en déclin». L’hydrogène vert est produit à partir d’électricité renouvelable (éolienne et solaire) par un processus d’électrolyse de l’eau (un procédé qui décompose l’eau en dioxygène et d’hydrogène gazeux). Il se distingue de l’hydrogène «gris», qui est produit à partir de sources d’énergie fossiles.<br/><br/>
    
    Le Maroc est à cet égard bien placé pour devenir l’un des principaux pays producteurs et exportateurs d’hydrogène vert au monde, aux côtés des Etats-Unis, du Chili, de l’Arabie Saoudite et de l’Australie, à en croire l’IRENA.<br/><br/>
    
    Le Royaume, qui s’est positionné assez tôt sur cette filière, fait partie des pays susceptibles de produire de l’hydrogène vert à faible coût, estime l’agence internationale, en capitalisant sur le potentiel considérable en énergies renouvelables, particulièrement solaire et éolienne, dont il dispose. Le Maroc devrait ainsi passer du statut d’importateur net d’énergie à celui d’exportateur, grâce au développement de l’hydrogène vert.<br/><br/>
    
    Le rapport souligne aussi que le Maroc a œuvré à mettre en place un environnement réglementaire favorable au développement de l’hydrogène vert. Une Commission nationale ad hoc a ainsi été créée dès 2019, et une feuille de route dédiée au développement de cette filière a été rendue publique en janvier 2021.<br/><br/>
    
    D’ici 2030, prévoit le document, le Maroc envisage un marché local de l’hydrogène de 4 térawattheures (TWh) et un marché à l’export de 10 TWh qui, cumulés, nécessiteraient la construction de 6 GW de nouvelles capacités renouvelables et soutenir la création de plus de 15.000 emplois indirects.<br/><br/>
    
    Grâce à ces atouts, et à sa proximité géographique avec l’Europe, le Maroc est naturellement courtisé par les géants du Vieux Continent qui cherchent à sécuriser leur approvisionnement futur en hydrogène vert. L’IRENA rappelle ainsi que l’Allemagne a conclu des accords bilatéraux sur l’hydrogène vert avec plusieurs fournisseurs potentiels, dont le Maroc.<br/><br/>
    
    Ces accords bilatéraux entre Etats fournisseurs et Etats importateurs vont se multiplier à l’avenir, contribuant à redessiner la carte de la géopolitique énergétique, telle que nous la connaissons actuellement.<br/><br/>
    
    «Les pays qui réussissent à devenir de grands exportateurs d’hydrogène vert et de carburants dérivés devraient également gagner en importance géostratégique», résume l’IRENA dans son rapport.<br/><br/>
    
    En septembre dernier, le cabinet McKinsey avait estimé dans un rapport que le Maroc pourrait capter jusqu’à 4% de la demande mondiale en molécules vertes.<br/><br/>
    
    Par <strong>Amine Kadiri</strong><br/><br/>
    
    <strong>Crédit : Le360</strong><br/><br/>
    
    <strong><a href="https://fr.le360.ma/economie/hydrogene-vert-le-maroc-futur-leader-mondial-affirme-lagence-internationale-des-energies-253233">https://fr.le360.ma/economie/hydrogene-vert-le-maroc-futur-leader-mondial-affirme-lagence-internationale-des-energies-253233</a></strong>`
  },
  {
    id: 10,
    title: "6 CONSEILS INCONTOURNABLES POUR DEVENIR UN AUTO-ENTREPRENEUR À SUCCÈS",
    category: "Auto-Entrepreneur",
    date:' mars 4, 2016',
    description: "Les auto-entrepreneurs qui ont réussi dans la vie ne sont pas nés talentueux, ils le sont devenus en développant leurs compétences dans des domaines clefs qui leur ont permis de réussir.",
    imageUrl: image10,
    content:`<strong>UN AUTO-ENTREPRENEUR À SUCCÈS</strong><br/><br/>

    Les auto-entrepreneurs qui ont réussi dans la vie ne sont pas nés talentueux, ils le sont devenus en développant leurs compétences dans des domaines clefs qui leur ont permis de réussir.<br/><br/>
    
    Voici une liste des principales choses que vous devez savoir si vous voulez réussir dans l’auto-entrepreneuriat, vous comprendrez vite que tout est lié.<br/><br/>
    
     
    
    <strong>DÉVELOPPEZ VOTRE ESPRIT D’ANALYSE ET VOTRE CAPACITÉ À PRENDRE LES BONNES DÉCISIONS</strong><br/><br/>
    Qu’il s’agisse du marketing, de la conception d’un produit ou de la mise en place d’un service, de sa publicité ou de sa vente, vous devez être capable de prendre les bonnes décisions seul, car engager des spécialistes pour vous conseiller sur les bases va vous coûter très cher, et il existe peu d’auto-entrepreneurs qui se lancent en ayant déjà des fonds.  Pour économiser un maximum de temps et d’argent, il vous faut les bases dans divers domaines de compétences, ce qui nous amène au point 2.<br/><br/>
    
     
    
    <strong>AUGMENTEZ VOS CAPACITÉS ET VOS CONNAISSANCES POUR POUVOIR FAIRE UN MAXIMUM VOUS-MÊME, SANS DEVOIR PAYER UN EXPERT</strong><br/><br/>
    
    Vous devez disposer des compétences de base en marketing ou en communication,  quelle que soit votre activité. Premièrement, vous ne pourrez pas payer un expert pour le faire à votre place. Ensuite, les bases peuvent s’acquérir très rapidement en lisant 1 ou 2 des best-sellers d’Amazon. Cela vous coûtera environ 20 euros en livres et quelques jours de lecture, et vous permettra d’éviter les pièges et les erreurs les plus fatales pour votre auto-entreprise. Votre produit ou service peut être le meilleur au monde, si vous ne savez pas le vendre, il ne se vendra pas.<br/><br/>
    
     
    
    <strong>DÉVELOPPEZ VOTRE RÉSEAU DE CONTACTS ET VOS RELATIONS AMICALES POUR OBTENIR L’AIDE D’AMIS EXPERTS DANS LEUR DOMAINE</strong><br/><br/>
    
    Il y a des connaissances qui vous prendront trop de temps à acquérir, ou qui tout simplement peuvent ne pas vous intéresser du tout. Avoir un réseau de contacts bien entretenu vous ouvrira bien des portes. Si vous rendez des services à vos amis, ils vous en rendront en retour. C’est comme ça qu’un ami à qui vous avez rendu service pensera à vous un jour si sa boîte recherche les service de quelqu’un qui a vos compétences. Peut-être même qu’il vous recommandera à ses amis qui recherchent quelqu’un proposant votre produit ou vos services. C’est aussi comme ça que vos amis ingénieurs au chômage peuvent accepter de développer pour vous un site ou une application le temps de trouver un emploi. Je donne ici des exemples classiques mais cela va bien au delà de ça, je sais que sans certaines personnes de mon réseau je n’aurai jamais pu lancer mon site d’informations sur le crédit. Ce qui est important pour bien entretenir son réseau de contact, c’est de s’intéresser aux gens que vous rencontrez et de garder un bon contact avec eux, même si vous ne les fréquentez pas directement. Pour faire ça efficacement, il vous faudra devenir quelqu’un possédant un minimum de charisme et de compétences sociales…<br/><br/>
    
     
    
    <strong>SOYEZ CHARISMATIQUE</strong><br/><br/>
    
    Ce trait de caractère qui veut à la fois tout et rien dire est une qualité qui vous servira dans absolument tous les domaines, que ce soit professionnels ou relationnels dans la vie de tous les jours. Dans la société, ce ne sont pas les personnes gentilles, aimables, polies qui se distinguent le plus, mais celles qui sont sûres d’elles, prêtes à aller de l’avant, et qui n’hésitent pas à faire les choses. Si vous êtes timide et renfermé, vous pourrez difficilement vous lancer à votre compte, même si vous êtes très talentueux, car les gens auront peu confiance en vous et penseront que vous échouerez. Ou ils vous prendront pour un pigeon qu’ils peuvent arnaquer, ce qui n’est pas mieux. Notez que l’on peut être charismatique tout en étant gentil, aimable et poli, ce qui n’empêche pas de savoir dire « non » lorsque c’est nécessaire et de ne pas se laisser marcher sur les pieds. Dans ces conditions-là, vous échouerez peut-être, mais ce ne sera que pour vous relever et reprendre votre périple.<br/><br/>
    
     
    
    <strong>AYEZ UNE VOLONTÉ DE FER, SOYEZ TENACE, MAIS APPRENEZ AUSSI À LAISSER TOMBER QUAND LA SITUATION L’IMPOSE</strong><br/><br/>
    
    Quand un projet n’a pas le succès escompté, ne vous dites pas simplement qu’il finira bien par marcher : il ne faut pas foncer tête baissée. De même, ne laissez pas tomber dès que le moindre problème survient. Soyez tenace, cherchez les erreurs, corrigez les, et repartez de plus belle. Utilisez vos capacités d’analyse et vos compétences (point 1 et 2) pour revoir vos estimations. S’il devient clair que votre projet ne peut être adapté ou sauvé, mettez le de côté le temps de trouver de l’inspiration ou des moyens, ou laissez tomber. Si vous vous acharnez à continuer alors que vous savez très bien au fond de vous que ça ne marchera pas, alors vous échouerez. N’espérez pas que les choses changent par magie. Un excellent proverbe dit :  » Si vous faites toujours la même chose, attendez-vous à obtenir ce que vous avez toujours obtenu ». Le changement (positif) s’amorce, se crée, il ne subvient pas comme par magie. Si vous avancez en ligne droite dans un projet qui ne marche pas, vous foncez droit au mur. Ce qui nous mène au point 6…<br/><br/>
    
     
    
    <strong>APPRENEZ À VOUS ADAPTER EN PERMANENCE, TESTEZ LES LIMITES DE VOTRE PROJET À CHAQUE ÉTAPE ET NON À LA FIN</strong><br/><br/>
    
    Parfois, l’hypothèse de base à l’origine de votre projet est bancale, et vous ne vous en rendez compte que par la suite. Cela ne veut pas dire que votre projet perdu pour autant. Si vous vous rendez compte que le public ciblé ne veut pas de votre produit ou n’en a pas besoin, commencez par voir si vous ne pouvez pas adapter votre produit, ou changer de public cible. Cela demande de réfléchir à votre produit ou service en continu, et de s’adapter à chaque tournant. Si vous allouez un budget à votre projet et que vous allez jusqu’au bout pour vous rendre compte à la fin qu’aucun client n’en veut, vous aurez tout perdu. Imaginez que êtes fabriquant d’ours en peluche rose. Si vous en créez une quantité énorme, et que vous constatez après coup que la demande était en fait pour les ours en peluche blancs car elle a changé le temps que vous montiez votre projet, vous aurez l’air malin… Si vous commencez à concevoir vos ours en peluche et que vous vous rendez compte de l’évolution de la demande, vous pourrez vous adapter. Il suffit de peu des fois pour transformer un échec en une réussite.<br/><br/>
    
     
    
    <strong>Crédit : Le Journal du Portage Salarial par Ayoub El Amrani</strong><br/><br/>
    <i><strong><a href="https://www.leportagesalarial.com/conseils-devenir-auto-entrepreneur/">https://www.leportagesalarial.com/conseils-devenir-auto-entrepreneur/</a></strong></i>`
    
  },
  {
    id: 11,
    title: "8 clés pour réussir à l'export",
    category: "Export",
    date:'mars 3, 2016',
    description: "Le copier-coller est la solution de facilité, mais ce n'est pas forcément la meilleure chose à faire lorsque l’on veut se développer à l’étranger. Un produit/service qui a bien fonctionné sur le territoire français n’aura pas le même succès dans un pays voisin, et encore moins sur un autre continent.",
    imageUrl: image11,
    content:`Export, Les 8 étapes incontournables pour bien gérer un projet<br/><br/>

    <strong>1 – Avoir une stratégie claire</strong><br/><br/>
    
    Pour cela, il vous faudra avoir une bonne connaissance de :<br/><br/>
    
    <ul class="blog-list"><li class="blog-list-li">L’écosystème :</li></ul><br/><br/>
    – Le marché ciblé et ses points d’accès<br/><br/>
    
    – Les concurrents potentiels<br/><br/>
    
    – Les acteurs clés<br/><br/>
    
    – Les événements dignes d’intérêt<br/><br/>
    
    <ul class="blog-list"><li class="blog-list-li">Votre entreprise :</li></ul><br/><br/>
    – Dans quel marché vais-je me lancer ?<br/><br/>
    
    – Qu’apporte mon produit/service au marché ?<br/><br/>
    
    – Quel positionnement-prix adopter ?<br/><br/>
    
    – Quels sont les moyens logistiques, financiers et humains… à réunir ?<br/><br/>
    
    Il vous faudra diagnostiquer vos forces et faiblesses, cela vous aidera à détecter les opportunités et les risques.<br/><br/>
    
    <strong>2 – Adaptez votre offre au marché</strong><br/><br/>
    
    Le « copier-coller » est la solution de facilité, mais ce n’est pas forcément la meilleure chose à faire lorsque l’on veut se développer à l’étranger.<br/><br/>
    
    Un produit/service qui a bien fonctionné sur le territoire français n’aura pas le même succès dans un pays voisin, et encore moins sur un autre continent.<br/><br/>
    
    Posez-vous les questions suivantes :<br/><br/>
    <ul class="blog-list">
    <li class="blog-list-li">Quelle valeur ajoutée apporte mon produit au marché ?</li><br/><br/>
    <li class="blog-list-li">Quels sont les bénéfices et gains de performance pour mon client ?</li><br/><br/>
    <li class="blog-list-li">Quelles adaptations vais-je devoir apporter à mon produit (couleur, goût, packaging, etc.), et quelles décisions marketing prendre (site internet traduit, plus rapide et ergonomique) ?</li><br/><br/>
    <li class="blog-list-li">Les grandes enseignes l’ont bien compris et font justement des réajustements. Vous allez donc devoir porter une attention particulière à la culture locale, aux usages et habitudes des consommateurs, car l’étranger… c’est vous !</li><br/><br/>
    </ul>
    
    <strong>3 – Choisissez le bon mode de représentation</strong><br/><br/>
    
    Pour débuter sur un marché étranger, vous avez tout intérêt à :<br/><br/>
    <ul class="blog-list">
    <li class="blog-list-li">raccourcir et simplifier les circuits de ventes,</li><br/><br/>
    <li class="blog-list-li">être en contact direct avec le client, et commencer avec une structure légère (bureau de liaison, succursale, agent commercial, etc.)</li><br/><br/>
    <li class="blog-list-li">Cela vous permettra d’anticiper les changements de gammes et les attentes techniques de la clientèle. Vous pourrez ainsi tester le marché avant d’envisager une implantation commerciale plus lourde.</li><br/><br/>
    </ul>
    
    <strong>4 – Optimisez vos ressources financières</strong><br/><br/>
    
    S’internationaliser est un investissement et ça ne s’improvise pas ! Vous devrez dépenser – parfois beaucoup – et donc vérifier l’état de votre trésorerie avant de vous engager dans cette démarche.<br/><br/>
    
    Voici quelques aspects de votre stratégie qui vont occasionner des coûts :<br/><br/>
    <ul class="blog-list">
    <li class="blog-list-li">un plan de communication,</li><br/><br/>
    <li class="blog-list-li">des frais de déplacements,</li><br/><br/>
    <li class="blog-list-li">des frais de prospection (présence salons),</li><br/><br/>
    <li class="blog-list-li">des problématiques de propriétés intellectuelles,</li><br/><br/>
    <li class="blog-list-li">la création de structures ad hoc ou filiale,</li><br/><br/>
    <li class="blog-list-li">le recours à du conseil juridique,</li><br/><br/>
    <li class="blog-list-li">le recrutement de personnel local (peut-être une expatriation, un VIE…), Etc.</li><br/><br/>
    </ul>
    
    Ces coûts sont évalués et provisionnés au plus juste et en général amortis qu’au bout de 3 à 4 années.<br/><br/>
    
    <strong>5 – Sélectionnez votre personnel pour ce projet</strong><br/><br/>
    
    Avez-vous identifié des personnes motivées et aptes à travailler dans un environnement international ?<br/><br/>
    
    Dans un premier temps, vous devrez faire l’inventaire des langues parlées dans l’entreprise ainsi que des collaborateurs ayant une sensibilité pour l’international et qui constitueront de futurs relais pour votre projet.<br/><br/>
    
    Puis, vous devrez identifier les personnes qui ont une solide formation sur les techniques de l’exportation (incoterms, facturation, transport, sujets douaniers, accords de libre-échange, etc.). Si ce n’est pas le cas, songez à former vos équipes.<br/><br/>
    
    Progressivement, votre entreprise toute entière devra se synchroniser avec votre projet d’exportation.<br/><br/>
    
    <strong>6 – Activez vos réseaux</strong><br/><br/>
    
    Ca y est vous avez choisi le pays de prédilection ?<br/><br/>
    
    N’oubliez pas de regarder dans vos réseaux si vous avez des contacts intéressants qui peuvent vous aider dans votre démarche de développement ? Et si oui, peuvent-ils vous recommander des interlocuteurs utiles ?<br/><br/>
    
    Il existe une multitude de clubs pour réseauter, que ce soit dans le pays cible (ambassade, chambres de commerce, salons professionnels, clubs et réseaux, etc.) ou bien même en France. Renseignez-vous, rendez-vous dans les lieux où les gens se rencontrent.<br/><br/>
    
    Le développement en solo est très compliqué, mais l’union fait la force !<br/><br/>
    
    <strong>7 – Appréhendez les différences culturelles</strong><br/><br/>
    
    Le business est avant tout une rencontre humaine entre des personnes de langues et de cultures différentes. Très souvent, c’est le manque de compréhension de l’aspect culturel qui fait chavirer vos projets.<br/><br/>
    
    La connaissance des codes locaux, des us et coutumes et des comportements ne s’apprend pas dans les manuels scolaires. En effet, la couleur « rouge » par exemple n’a pas la même signification d’un pays à un autre, et peut-être que votre nom commercial désigne quelque chose de particulier, après traduction.<br/><br/>
    
    Vous avez plusieurs possibilités pour votre entreprise :<br/><br/>
    
    vous appuyer sur des personnes connaissant parfaitement les codes locaux,<br/><br/>
    
    avoir en interne une personne biculturelle formée à la culture d’entreprise, à vos produits et connaissant parfaitement le marché local,<br/><br/>
    
    envoyer une personne sur place (ou y aller soi-même) pour s’imprégner et comprendre les attentes du marché.<br/><br/>
    
    <strong>8 – Faites-vous accompagner</strong><br/><br/>
    
    Pensez à vous faire accompagner dans votre projet d’export, que ce soit au niveau d’une demande de financement ou sur l’accompagnement dans le développement de votre société (prospection, organisation, communication, etc.).<br/><br/>
    
    Il y existe différents dispositifs d’appuis pour les exportateurs. Soyez proche des équipes de l’export en France (auprès des Chambres de Commerce) et des équipes à l’étranger (organismes privés, CCI à l’étranger, Business France, etc.).<br/><br/>
    
    Vous ne pourrez pas tout faire en même temps, alors ne vous éparpillez pas et cibler vos priorités. Transformez votre stratégie en un véritable business plan !<br/><br/>
    
    Mars 2022<br/><br/>
    
    <strong>Crédit : Bip France Création</strong><br/><br/>
    
    <i>Lien :</i><a href="https://bpifrance-creation.fr/moment-de-vie/8-cles-reussir-a-lexport">https://bpifrance-creation.fr/moment-de-vie/8-cles-reussir-a-lexport /a><br/><br/>`
  },
  {
    id: 12,
    title: "8 conseils essentiels pour bien débuter en Auto-Entreprise",
    category: "Auto-Entrepreneur",
    date:'mars 3, 2016',
    description: "Au lancement de votre activité, vous pouvez avoir tendance à accepter tous les contrats, pour construire votre réputation et fidéliser vos nouveaux clients. Votre carnet de commandes gonfle et vous repoussez la question des délais, convaincu que vous honorerez toutes ces missions.",
    imageUrl: image12,
    content:`<strong>Auto Entreprise, 8 conseils essentiels pour bien débuter</strong><br/><br/>

    <strong>1 – Privilégiez la qualité à la quantité</strong><br/><br/>
    
    Au lancement de votre activité, vous pouvez avoir tendance à accepter tous les contrats, pour construire votre réputation et fidéliser vos nouveaux clients. Votre carnet de commandes gonfle et vous repoussez la question des délais, convaincu que vous honorerez toutes ces missions. Mais face à une charge de travail devenue trop importante, vous risquez d’accumuler du stress et d’être contraint de bâcler votre travail. Vous aurez donc travaillé sans plaisir et vos clients risquent d’être déçus du résultat.<br/><br/>
    
    Même si vous gagnez plus d’argent à court terme, le fait de privilégier la quantité à la qualité doit donc rester une situation exceptionnelle. Cela ne peut pas devenir une option durable pour votre activité. Pour cela, ne surestimez pas vos capacités.<br/><br/>
    
    <strong>2 – Apprenez à dire non</strong><br/><br/>
    
    La création d’une entreprise est souvent accompagnée de bons sentiments : plein d’énergie, vous travaillez beaucoup dans le but de générer rapidement un chiffre d’affaires correct. Et pour cela, vous êtes parfois prêts à dire « oui » à tout et à tout le monde…<br/><br/>
    
    Mais en agissant ainsi, vous prenez des risques, qui peuvent à la longue devenir anxiogènes :<br/><br/>
    <ul class="blog-list">
    <li class="blog-list-li">vous ne pouvez pas honorer vos missions ou commandes par manque de temps et/ou parce que vous avez surestimé vos compétences</li><br/><br/>
    <li class="blog-list-li">vous nouez des partenariats qui ne sont pas en accord avec vos valeurs</li><br/><br/>
    <li class="blog-list-li">vous travaillez pour des clients qui ne vous plaisent pas</li><br/><br/>
    <li class="blog-list-li">vous acceptez des missions sous-payées voire gratuites en « dépannage »</li><br/><br/>
    <li class="blog-list-li">vous travaillez avec des fournisseurs qui impactent négativement sur la qualité de vos produits.</li><br/><br/>
    </ul>
    Pensez donc à prendre le temps de bien réfléchir et de peser le « pour et le contre » de ce que l’on vous propose.<br/><br/>
    
    <strong>3 – Investissez dans votre activité</strong><br/><br/>
    
    Même si l’auto-entreprise permet une entrée plus aisée dans le monde de l’indépendance, vous devrez endosser le rôle de chef d’entreprise à part entière, et donc investir pour le lancement et le bon développement de votre activité.<br/><br/>
    
    L’exemple le plus fréquent est de confier la réalisation de son identité visuelle ou la construction du site internet à des non-professionnels, souvent des amis ou connaissances dont ça n’est pas le métier.<br/><br/>
    
    C’est une bien souvent illusion de croire que ces choix donnent de bons résultats. Au contraire, procéder ainsi peut rapidement vous faire passer pour un amateur. Vous ne devez pas négliger votre image et votre communication car elles sont indispensables pour augmenter votre visibilité et trouver vos premiers clients.<br/><br/>
    
    Si vous souhaitez développer réellement votre activité, il vous faudra considérer votre auto-entreprise comme un véritable business. Pour cela, pensez à prévoir un budget de départ et continuez à investir au fur et à mesure que votre activité grandit : publicité sur les réseaux sociaux, achat de matériel plus performant, création d’un site internet, achat de logiciels, etc.<br/><br/>
    
    Pour en savoir plus : comment créer son site internet ?<br/><br/>
    
    <strong>4 – Entourez-vous</strong><br/><br/>
    
    L’une des erreurs les plus fréquentes chez les auto-entrepreneurs est de rester seul pendant (et après) la création de leur activité. Si vous restez dans votre coin, il sera compliqué de gagner en visibilité et trouver vos premiers clients.<br/><br/>
    
    Plusieurs situations peuvent amener à cet isolement :<br/><br/>
    <ul class="blog-list">
    <li class="blog-list-li">vous ne savez pas vers qui vous tourner pour poser vos questions</li><br/><br/>
    <li class="blog-list-li">vous pensez être capable de tout faire seul</li><br/><br/>
    <li class="blog-list-li">vous êtes timide et avez du mal à aller vers les autres</li><br/><br/>
    <li class="blog-list-li">vous considérez les autres uniquement comme des concurrents</li><br/><br/>
    </ul>
    Bon à savoir<br/><br/>
    
    N’hésitez pas à rejoindre des associations, à vous faire accompagner par des structures type CMA et CCI et à parler de votre projet autour de vous.<br/><br/>
    
    <strong>5 – Continuez à apprendre</strong><br/><br/>
    
    Par manque de temps ou d’intérêt, les auto-entrepreneurs débutants font parfois l’erreur de stopper tout apprentissage une fois leur activité lancée. D’autres pensent que leur diplôme suffit pour gérer tous les aspects de leur activité.<br/><br/>
    
    Pourtant, la formation continue est essentielle quand on est chef d’entreprise. En effet, tous les secteurs sont concernés par les mutations de l’environnement : nouvelles réglementations et tendances, nouvelles méthodes, matériels plus performants, etc. En parallèle, les stratégies liées au développement entrepreneurial évoluent vite, surtout si vous utilisez le web.<br/><br/>
    
    Pour être plus performant et ne pas être dépassé par vos concurrents, restez curieux. Vous avez besoin de continuer à apprendre après la création de votre auto-entreprise. Inscrivez-vous aux ateliers organisés dans votre ville, participez aux séminaires liés à votre secteur ou formez-vous en ligne.<br/><br/>
    
    Participer à des formations est également un moyen d’échanger avec des professionnels, et surtout, de solidifier les fondements de votre projet.<br/><br/>
    
    N’oubliez pas qu’en dehors du cœur de votre activité, vous devez également gérer votre auto-entreprise au quotidien (comptabilité, déclarations, aides disponibles, etc.). Ces compétences ne sont pas innées : des formations dans ce domaine existent. Au Portail Auto-Entrepreneur, nous sommes conscients de ces difficultés, c’est pourquoi nous avons décidé de lancer notre propre formation destinée aux auto-entrepreneurs avec Educademy. Bonne nouvelle : elle est finançable par l’État grâce à votre CPF.<br/><br/>
    
    Pour en savoir plus : les droits à la formation<br/><br/>
    
    <strong>6 – Listez vos charges</strong><br/><br/>
    
    La confusion entre chiffre d’affaires et salaire peut amener à des situations financières délicates.<br/><br/>
    
    Ainsi, si vous facturez un total de 2 000 € sur un mois, gardez en tête que ce montant correspond à un chiffre d’affaires, et non un revenu net comme cela est le cas d’un salarié. De ce chiffre d’affaires, vous devrez en effet obligatoirement déduire des charges, et ce même si vous bénéficiez de la franchise en base de TVA : taxes et cotisations, achat de matière premières, fournitures, assurances, supports de communication…sont autant de frais qui viendront imputer cette somme de départ.<br/><br/>
    
    Bon à savoir<br/><br/>
    
    Prenez le temps de lister toutes les charges liées à l’exercice de votre activité et calculer le montant nécessaire à leur règlement. Pensez également à mettre de côté pour les imprévus (matériel en panne).<br/><br/>
    
    <strong>7 – Vendez au prix juste</strong><br/><br/>
    
    L’erreur classique de l’auto-entrepreneur qui débute est de pratiquer des tarifs peu élevés pour attirer les clients.<br/><br/>
    
    En réalité, rien ne sert de brader le travail effectué :<br/><br/>
    <ul class="blog-list">
    <li class="blog-list-li">dans l’esprit des consommateurs, de petits prix riment souvent avec une mauvaise qualité des produits ou des prestations bâclées,</li><br/><br/>
    <li class="blog-list-li">il y aura toujours des clients qui refuseront de payer même si vos prix sont très bas.</li><br/><br/>
    </ul>
    Dans ce cadre, même si vous débutez et/ou que vous évoluez dans un secteur concurrentiel, assumez vos tarifs et mettez en avant ce qui les justifie. Si vous avez des compétences très recherchées ou que vos produits sont d’excellente qualité, il est normal de les vendre plus cher.<br/><br/>
    
    Pour en savoir plus : comment fixer ses prix ?<br/><br/>
    
    <strong> 8 – Prospectez !</strong><br/><br/>
    
    Certains auto-entrepreneurs ont du mal à prospecter car ils se sentent mal à l’aise ou ne savent pas comment faire. Un autre cas, notamment pour les activités de services, consiste à arrêter la prospection après avoir trouvé un ou deux clients dont les missions suffisent à se dégager un salaire.<br/><br/>
    
    En restant dans une telle situation, les conséquences sont prévisibles :<br/><br/>
    <ul class="blog-list">
    <li class="blog-list-li">si vous ne trouvez aucun client pour vendre vos produits, votre activité va s’arrêter</li><br/><br/>
    <li class="blog-list-li">si vous misez tout sur un ou deux clients et que la collaboration cesse, vous risquez de vous retrouver dans une situation financière inconfortable.</li><br/><br/>
    </ul>
    Bon à savoir<br/><br/>
    
    Si vous souhaitez vivre réellement de votre auto-entreprise, la prospection doit être au centre de vos préoccupations.<br/><br/>
    
    En effet, avant que votre réseau professionnel ne s’active et que le bouche-à-oreille ne s’enclenche, pensez à vous dégager régulièrement du temps pour trouver de nouveaux clients et vous faire connaître.<br/><br/>
    
    <strong>9 – Centralisez votre gestion en ligne</strong><br/><br/>
    
    Être professionnel indépendant implique de s’occuper de diverses tâches relatives à la gestion d’entreprise : comptabilité, déclarations fiscales, constituer sa clientèle, etc.<br/><br/>
    
    Pour éviter le stress inutile et les tableaux Excel remplis à la main, gérez vos formalités et votre comptabilité en un seul et même endroit.<br/><br/>
    
    <strong>Crédit : Portail Auto-Entrepreneur</strong><br/><br/>
    
    <i><strong><a href="https://www.portail-autoentrepreneur.fr/academie/developpement/conseils/conseils-pour-bien-debuter">https://www.portail-autoentrepreneur.fr/academie/developpement/conseils/conseils-pour-bien-debuter</a></strong></i>`
  },
  {
    id: 13,
    title: "Les règles d’or pour réussir à l’export",
    category: "Export",
    date:'mars 2, 2016',
    description: "Quelle que soit sa taille, toute entreprise est capable de relever le défi de l’exportation.  Il existe une multitude de bonnes raisons d’exporter : Tout d’abord, l’objectif d’augmenter son chiffre d’affaires, d’élargir son marché ou d’aller au-delà du marché belge très vite saturé.  Les entreprises désirent prospecter des marchés moins concurrentiels, varier leur clientèle et se rendre ainsi moins dépendantes de leurs clients locaux. ",
    imageUrl: image13,
    content:`Quelle que soit sa taille, toute entreprise est capable de relever le défi de l’exportation.  Il existe une multitude de bonnes raisons d’exporter : Tout d’abord, l’objectif d’augmenter son chiffre d’affaires, d’élargir son marché ou d’aller au-delà du marché belge très vite saturé.  Les entreprises désirent prospecter des marchés moins concurrentiels, varier leur clientèle et se rendre ainsi moins dépendantes de leurs clients locaux.  Et enfin, l’export leur permet de prolonger le cycle de vie des produits, d’optimiser l’outil de production et de réaliser des économies d’échelle.<br/><br/>

    Il n’est donc pas étonnant que l’export tente de nombreuses entreprises.  Pour augmenter vos chances de succès, voici quelques conseils élémentaires à suivre :<br/><br/>
    <ul class="blog-list">
    <li class="blog-list-li">N’envisagez l’exportation que lorsque vous êtes déjà bien implanté dans votre pays.  Rodez ici vos produits et services avant de les lancer à l’étranger. Les erreurs de jeunesse sont, en principe, plus faciles à corriger chez soi. Avec le temps, l’expérience consolide la fiabilité de vos produits et augmente votre crédibilité sur les marchés étrangers.</li><br/><br/>
    <li class="blog-list-li">Réalisez un diagnostic de votre entreprise et préparez-vous à répondre à la demande à l’étranger.  Votre capacité de production de vos produits ou d’exécution de vos services est-elle suffisante ? Vos sous-traitants et fournisseurs peuvent-ils vous suivre ? Vos produits ou services sont-ils prêts et fiables ? Etes-vous apte à offrir un suivi commercial et un service après-vente adéquat pour les clients étrangers ? Disposez-vous de personnes multilingues et disponibles pour voyager ?  Bénéficiez-vous de moyens pour financer le développement et l’adaptation des produits et la prospection à l’export (études de marché, déplacements à l’étranger, salons professionnels, supports marketing multilingues, certifications locales, protection des droits intellectuels, …).</li><br/><br/>
    <li class="blog-list-li">Choisissez le bon pays et ciblez la bonne clientèle.  La sélection dépendra de l’importance de la demande et des opportunités à l’étranger, de la concurrence, des obstacles (règlementations, adaptations, droits de douanes, …) et du pouvoir d’achat des clients.</li><br/><br/>
    <li class="blog-list-li">Les informations proviennent  principalement des prospects eux-mêmes, des rencontres lors des salons professionnels, des bases de données professionnelles (ex. Kompass), d’autres entreprises offrant des produits complémentaires et visant la même clientèle, d’Internet, d’organismes d’aide à l’export ou autres institutions tels que l’AWEX, Brussels Invest & Export, FIT, Enterprise Europe Network, CCI, etc..</li><br/><br/>
    <li class="blog-list-li">Privilégiez les « niches », c’est-à-dire les segments de clientèle ou de produits où la concurrence est la moins dense et féroce.  Il vaut mieux exceller dans un petit marché que d’être écrasé dans un gros.</li><br/><br/>
    <li class="blog-list-li">Envisagez votre expansion de façon progressive.  Concentrez-vous sur un marché à la fois et ne passez au suivant que lorsque vous avez mis le premier bien en place (réseau de distribution établi, logistique opérationnelle, etc.).</li><br/><br/>
    <li class="blog-list-li">Prévoyez les ressources nécessaires pour prospecter les marchés étrangers.  Vendre, ce n’est pas attendre la réaction du client, mais c’est le pousser à acheter.  Pour cela, il faut du temps et des ressources pour prospecter activement et de façon très professionnelle.  Il est souvent nécessaire d’aller sur place pour se rendre compte des réalités du terrain, de réaliser des études de marché, de recenser les prospects et de distributeurs, de rechercher les informations sur la règlementation locale, de maintenir une veille concurrentielle, etc.</li><br/><br/>
    <li class="blog-list-li">Adaptez vos produits et vos services en tenant compte des besoins spécifiques de la clientèle cible, des exigences légales et/ou administratives, des contraintes imposées par certaines certifications, des contraintes techniques locales (électricité, dimensions, etc.).</li><br/><br/>
    <li class="blog-list-li">Rendez vos produits ou services attractifs par rapport aux concurrents locaux. Ayez une longueur d’avance sur eux. Innovez. Confortez vos avantages concurrentiels. Choisissez le bon positionnement, même s’il est différent que sur votre marché domestique.</li><br/><br/>
    <li class="blog-list-li">Choisissez les bons canaux de distribution.  Les options qui s’offrent aux exportateurs sont nombreuses.  Citons comme exemple la vente en direct auprès des clients étrangers, l’appel à des intermédiaires commerciaux (agents, apporteurs d’affaires), la distribution au travers d’un réseau d’importateurs exclusifs, la conclusion de partenariats commerciaux, le transfert de savoir-faire dans le cadre d’une licence, l’établissement de filiales, de succursales ou de joint-venture, etc.
    Il n’existe pas de solution meilleure qu’une autre. Le choix du canal dépendra du type de produits ou services exportés, de l’importance du marché cible, de la nécessité ou non de fournir un service après-vente (maintenance, etc.), de la valeur des produits et des marges réalisées, de vos forces et des faiblesses et de votre diagnostic export.<br/><br/>
    Des organismes d’aide à l’export comme l’AWEX, Brussels Invest & Export ou le FIT peuvent vous aider notamment à réfléchir sur cet aspect et à trouver des partenaires à l’étranger.</li><br/><br/>
    <li class="blog-list-li">Ne vous laissez pas séduire par le premier venu.  Avant de sélectionner votre partenaire commercial local, essayez de rencontrer tous les candidats possibles.  Le premier venu, bien que paraissant souvent très motivé, ne s’avère pas toujours le plus adéquat.</li><br/><br/>
    <li class="blog-list-li">Restez rentables.  Les prix à l’export doivent tenir compte du marché, de la concurrence, du réseau de distribution et des intermédiaires, de la stratégie de l’entreprise (politique commerciale agressive ou non) et de tous les surcoûts et frais liés à l’export (prospection, salons, voyages, études de marché, adaptations des produits, transport selon l’incoterm, etc.).  Il faut rester attractif sur le marché tout en maintenant l’activité bénéficiaire.</li><br/><br/>
    <li class="blog-list-li">Vendre, c’est bien.  Etre payé, c’est mieux.  Plus on vend loin, plus les risques de ne pas être payés s’accroissent.  Aussi, est-il important d’exiger des paiements à l’avance ou des paiements garantis comme le Credoc, et si c’est opportun, de couvrir la transaction par une assurance-crédit pour certaines causes de non-paiement.</li><br/><br/>
    <li class="blog-list-li">Méfiez-vous de la contrefaçon. Elle guète toutes les entreprises, aussi méconnues soient-elles.  Protégez vos droits de propriété intellectuelle par des dépôts de modèles ou des brevets.  Si ce n’est pas possible ou si c’est inopportun ou trop onéreux, restez vigilants.  limitez autant que possible la divulgation de votre savoir-faire et de vos créations.</li><br/><br/>
    <li class="blog-list-li">Adaptez vos comportements à la culture de vos interlocuteurs.  Le commerce repose sur la sympathie et la confiance qu’ont les interlocuteurs l’un dans l’autre.  Evitez les impairs, sachez vous faire apprécier et essayer de comprendre tout ce qui crée l’incompréhension et la discordante, pour assurer une relation commerciale stable sur le long terme.</li><br/><br/>
    </ul>
    Christine Destexhe, International Trade & Legal Adviser<br/><br/>
    
    <strong>Crédit : Awex Export <a href="https://wallonia.be/fr">(Wallonia.be)</a></strong><br/><br/>
    
    <i><strong><a href="https://www.awex-export.be/fr/guide-a-l-export/prealables-a-l-export/10-premiers-conseils-a-l-export">https://www.awex-export.be/fr/guide-a-l-export/prealables-a-l-export/10-premiers-conseils-a-l-export</a></strong></i>`
  },
  {
    id: 14,
    title: "Comment devenir un Top Vendeur",
    category: "Fournisseur",
    date:'mars 1, 2016',
    description: "Certains vendeurs sont exceptionnels ! Comment font-ils pour exceller commercialement ? Quelles sont leurs techniques et méthodes de vente ? Et surtout, comment devenir un bon vendeur à votre tour ? Quels enseignements en tirer pour booster votre performance commerciale ? Voici 6 techniques et qualités pour devenir un très bon vendeur, c'est-à-dire vendre professionnellement, efficacement et avec régularité !",
    imageUrl: image14,
    content: `<strong>Comment devenir un Top Vendeur</strong><br/><br/>
    Top Vendeur, certains vendeurs sont exceptionnels ! Comment font-ils pour exceller commercialement ? Quelles sont leurs techniques et méthodes de vente ? Et surtout, comment devenir un bon vendeur à votre tour ? Quels enseignements en tirer pour booster votre performance commerciale ?<br/><br/>
    
    Voici 6 techniques et qualités pour devenir un très bon vendeur, c’est-à-dire vendre professionnellement, efficacement et avec régularité !<br/><br/>
    
    <strong>Top Vendeur : 6 Qualités pour Réussir</strong><br/><br/>
    Être un bon vendeur ou ne pas être… telle est la question ! Il n’est pas rare de voir un seul bon vendeur faire autant de chiffres d’affaires que le reste de son équipe commerciale réunie. C’est en général celui qui fait littéralement trembler la concurrence !<br/><br/>
    
    Mais soyons francs, cela reste assez rare dans une force de vente. Il arrive bien sûr que pendant de courtes périodes, certains vendeurs arrivent « par hasard » à réaliser quelques jolis coups avec plusieurs ventes successives. Mais ils cessent ensuite de vendre avec cette même efficacité commerciale.<br/><br/>
    
    C’est un peu comme la « chance du débutant » aux cartes ou au poker. Les performances commerciales du vendeur ne sont absolument pas régulières… et son directeur des ventes s’arrachent les cheveux !<br/><br/>
    
    <strong>La différence entre le bon et le mauvais vendeur</strong><br/><br/>
    Vous connaissez sans doute la différence entre le bon et le mauvais chasseur si vous êtes adepte du « sketch des inconnus » 😉<br/><br/>
    
    Mais quelle est la différence entre le vendeur professionnel et le commercial débutant / l’amateur ? Le grand professionnel de la vente arrive à reproduire les performances avec régularité, et ce peu importe la conjoncture économique, la concurrence et les influences externes.<br/><br/>
    
    Mais comme cela reste rare dans les équipes commerciales, ce vendeur d’élite que personne n’arrive à égaler est souvent classé dans la catégorie « exceptionnel » et ses compétences commerciales sont dites « non-reproductibles ». Les autres vendeurs appellent ça un « don ». Comme ils n’obtiennent pas les mêmes résultats, c’est forcément quelque chose d’inné.<br/><br/>
    
    Ils arrivent à s’auto convaincre qu’on naît Grand Vendeur ou bien on ne l’est pas !<br/><br/>
    
    C’est totalement faux ! La bonne nouvelle, c’est qu’on peut le devenir… vous pouvez devenir vendeur et réussir dans la fonction commerciale peu importe votre niveau actuel…<br/><br/>
    
    Ce qui caractérise le top vendeur qui fait 5 à 10 fois le chiffre de ses concurrents en externe, mais également en interne au sein de son entreprise, ce sont en fait des techniques et des méthodes de vente bien précises. Il n’y a rien de miraculeux dans la vente !<br/><br/>
    
    J’ai résumé pour vous ces techniques de vente afin de vous permettre d’avoir une vision très précise de comment être un vendeur hors pair !<br/><br/>
    
    <strong>1. Le bon commercial a de l’empathie</strong><br/><br/>
    Zig Ziglar disait « Plus vous aidez les gens à obtenir ce qu’ils désirent et plus vous aurez de succès » et c’est bien vrai ! Intéressez-vous au client et placez-le au centre du processus de vente :<br/><br/>
    
    <ul class="blog-list">
        <li class="blog-list-li">De quoi a-t-il besoin, de plus, de mieux ?<br/><br/></li>
        <li class="blog-list-li">Pourquoi désire-t-il changer quelque chose à sa situation actuelle ?<br/><br/></li>
        <li class="blog-list-li">Quels sont ses objectifs, ses envies, les problématiques qu’il rencontre ?<br/><br/></li>
    </ul>
    
    Comprenez sa situation en profondeur, mettez-vous sincèrement à sa place et aidez-le grâce à votre expertise métier / commerciale à faire le meilleur choix possible. Et puis élevez votre niveau de service au-delà des standards habituels.
    Différenciez-vous immédiatement de la concurrence en optimisant votre gestion de la relation client pour susciter la préférence !<br/><br/>
    <strong>2. Le bon vendeur est authentique</strong>
    <br/><br/>
    Soyez honnête, soyez vrai et ne jouez pas un rôle avec vos clients ! Pourquoi ?<br/><br/>
    
    Tout simplement parce que la confiance, ça se gagne ! Elle met parfois du temps à s’installer dans une véritable relation commerciale, alors soyez digne de cette confiance qui vous est accordée. Ne succombez pas au profit immédiat en arrangeant la vérité sur les performances ou la qualité de votre produit ou service. Tôt ou tard, cela se retournerait contre vous.<br/><br/>
    
    Et les statistiques parlent d’elles même en termes de satisfaction client et de fidélisation client :<br/><br/>
    
      1.Un client satisfait en parle à 3 personnes dans son entourage, relation commerciale<br/><br/>
      2.Un client insatisfait en parle à 10 personnes !<br/><br/>
    Alors, soyez congruent, faites ce que vous avez promis et prenez soin de la relation avec vos clients ! Une expérience client réussie jouera toujours en votre faveur.<br/><br/>
    <strong>3. La volonté de performer et de réussir sa carrière commerciale</strong><br/><br/>
    Dans n’importe quel domaine, pour être le meilleur, il faut le vouloir ! Mais dans la vente, être simplement bon ne suffit plus… devenir un vendeur d’élite, cela implique de :<br/><br/>
    
    <ul class="blog-list">
      <li class="blog-list-li">Travailler sur soi</li><br/><br/>
      <li class="blog-list-li">Se remettre en question</li><br/><br/>
      <li class="blog-list-li">Avoir une détermination à toute épreuve</li><br/><br/>
      <li class="blog-list-li">Apprendre en permanence et se former</li><br/><br/>
      <li class="blog-list-li">Analyser et modéliser les meilleures pratiques commerciales</li><br/><br/>
      <li class="blog-list-li">Les tester sur le terrain (en clientèle), se les approprier et les faire siennes, pour qu’elles résonnent vraiment !</li><br/><br/>
    </ul>
    <strong>Sans oublier la répétition, car comme le disait Bruce Lee :</strong>
    « je n’ai pas peur de celui qui connaît mille coups et qui les a pratiqué une seule fois, je me méfie par contre de celui qui connaît un seul coup en l’ayant pratiqué 1000 fois. »<br/><br/>
    
    <strong>4. L’expertise commerciale</strong><br/><br/>
    À l’heure d’internet, du digital (marketing de contenu, social selling…) et du mobile, pour l’acheteur, l’information est facilement accessible aujourd’hui ! C’est pourquoi, encore une fois, il faut viser l’excellence commerciale !<br/><br/>
    
    Il y a les vendeurs distributeurs qui se contentent de fournir au client ce qu’il demande, et les vendeurs professionnels qui prennent le temps d’analyser et de comprendre, afin d’aiguiller le client vers le meilleur choix possible avec des produits et services complémentaires pour améliorer son expérience d’achat.<br/><br/>
    
    Bref, vous voulez savoir comment devenir un bon vendeur ? Vous devez être incollable sur :<br/><br/>
    
    <ul class="blog-list">
      <li class="blog-list-li">Votre marché</li><br/><br/>
      <li class="blog-list-li">Votre entreprise</li><br/><br/>
      <li class="blog-list-li">La réglementation</li><br/><br/>
      <li class="blog-list-li">Les données techniques de vos produits et services</li><br/><br/>
      <li class="blog-list-li">Les nouvelles tendances</li><br/><br/>
      <li class="blog-list-li">etc.</li><br/><br/>
    </ul>
    
    C’est tout cela qui permet d’apporter de la valeur ajoutée aux prospects et clients qui croisent votre chemin. Si vous vous contentez d’apporter une information qu’ils peuvent trouver en 2 clics sur un smartphone, alors ils peuvent se passer de vous ! Les vendeurs « preneurs de commandes » et les vendeurs distributeurs sont voués à disparaître au vu de ce qui se profile.<br/><br/>
    
    <strong>5. L’intelligence commerciale situationnelle</strong><br/><br/>
    La technique de vente s’apprend ! Les étapes de la vente, les accélérateurs de vente, le traitement des objections, le closing…<br/><br/>
    
    Mais il y a une chose qui ne tient qu’à vous : c’est l’application, la mise en pratique de toutes ces techniques de vente dans votre quotidien commercial !<br/><br/>
    
    Encore une fois, le travail et la persévérance vous conduiront au stade de la maîtrise / la compétence inconsciente qui permet de faire face instantanément à tout type de situation en face d’un client, de telle façon que cela passe pour un « don » 😉<br/><br/>
    
    Ce fameux don que possède le Top Vendeur, mais qui en réalité n’en est pas un ! Cela se cultive, l’intelligence commerciale situationnelle est le résultat de votre apprentissage, de votre travail au quotidien et de votre degré de persévérance.<br/><br/>
    
    Le meilleur des professeurs dans la vente reste le client ! Si vous voulez savoir comment devenir un vendeur hors pair, gravez cette phrase dans votre esprit…<br/><br/>
    
    <strong>6. L’amélioration continue du processus de vente</strong>
    Cela fait parfaitement le lien avec le point précédent. Vous avez déjà entendu parler de la boucle PDCA ? Plan > Do > Check > Act !<br/><br/>
    
         1.Planifiez les choses : préparez vos entretiens de vente / vos actions commerciales aux petits oignons: du pitch commercial jusqu’au traitement des objections et du closing. Tout s’anticipe, il n’y a pas de place pour l’improvisation dans la vente !
          2.Exécutez : déroulez votre plan de vente en entretien commercial face au client.<br/><br/>
          3.Contrôlez les résultats et les indicateurs commerciaux ! Débriefer ou faites vous débriefer pour analyser avec précision ce qui fonctionne commercialement (ce sur quoi vous devez capitaliser) et ce qui doit être amélioré lors de vos prochaines ventes.
         3.Agissez ! N’attendez pas, mettez en place immédiatement le plan d’action commercial correctif dès le prochain entretien de vente. C’est véritablement la clé du succès qui fait la différence entre un grand champion et un amateur. Un Mohammed Ali de la vente ou bien un touriste qui s’est reconverti et qui regarde ses performances dégringoler en se disant que les autres ont de la chance.<br/><br/>
    Peu importe d’où vous partez et quel est votre niveau commercial actuel : l’amélioration continue est votre meilleur allié si vous souhaitez savoir comment devenir un vendeur exceptionnel !<br/><br/>
    
    Michael Jordan a dit :<br/><br/>
    
    « J’ai raté 9000 tirs dans ma carrière. J’ai perdu presque 300 matchs. 26 fois, on m’a fait confiance pour prendre le tir de la victoire et j’ai raté.  J’ai échoué encore et encore et encore dans ma vie. Et c’est pourquoi je réussis. »<br/><br/>
    
    Il n’y a pas de chance dans le métier de la vente, je vous le répète.<br/><br/>
    
    <strong>Suivez ces facteurs clés de succès commerciaux :</strong><br/><br/>
       1.L’empathie et le désir de s’intéresser aux autres<br/><br/>
       2.L’authenticité<br/><br/>
       3.La volonté de performer<br/><br/>
       4.L’expertise<br/><br/>
       5.L’intelligence commerciale situationnelle<br/><br/>
       6.L’amélioration continue<br/><br/>
       7.Combinez-les aux qualités commerciales avec les techniques de vente et vous deviendrez sans aucun doute leTop    Vendeur de votre marché !<br/><br/>
    
    <i><strong>Crédit : Uptoo</strong></i><br/><br/>
    
    <i><strong><a href="https://uptoo.fr/Blogs/comment-devenir-un-top-vendeur/">https://uptoo.fr/Blogs/comment-devenir-un-top-vendeur/</a><strong></i>`
  },
  {
    id: 15,
    title: "Comment entretenir de bonnes relations avec ses fournisseurs",
    category: "Fournisseur",
    date:'mars 1, 2016',
    description: "Soigner ses relations fournisseurs est capital pour toute entreprise. Tous secteurs confondus, la manière dont une entreprise interagit avec ses fournisseurs peut faire la différence entre son succès ou sa faillite : bénéficier de rapports privilégiés avec des prestataires de qualité est un avantage concurrentiel en soi !",
    imageUrl: image15,
    content:`<strong>Fournisseurs, Comment entretenir de bonnes relations ?</strong><br/><br/>
    Fournisseurs, Comment entretenir de bonnes relations ? Soigner ses relations fournisseurs est capital pour toute entreprise. Tous secteurs confondus, la manière dont une entreprise interagit avec ses fournisseurs peut faire la différence entre son succès ou sa faillite : bénéficier de rapports privilégiés avec des prestataires de qualité est un avantage concurrentiel en soi ! Qui sont-ils et comment se portent vos relations avec eux ? Quels sont les aspects à améliorer ! Cet article est là pour vous aider à entretenir de bonnes relations avec vos fournisseurs et garantir une coopération mutuellement avantageuse, dans les meilleures conditions possibles.<br/><br/>
    
    Partez sur de bonnes bases : le sourcing fournisseurs<br/><br/>
    
    <strong>Menez un sourcing efficace</strong>Menez un sourcing efficace<br/><br/>
    
    Pour garantir des relations optimales avec les fournisseurs de votre entreprise, il faut commencer… et bien, par le commencement ! Afin de partir sur des bases convenables, votre travail doit partir de la phase de prospection durant laquelle vous allez identifier des structures avec lesquelles vous comptez potentiellement vous associer : le sourcing fournisseurs. Pour faire simple, cette démarche consiste à dresser une cartographie des partenaires potentiels au cour d’un travail de recherche et de documentation. Mettre à plat leurs caractéristiques, c’est être en mesure d’en choisir un (ou plusieurs) qui vous correspond le mieux.<br/><br/>
    
    <strong>Définissez une stratégie en amont</strong><br/><br/>
    
    Comme beaucoup de décisions concernant la gestion d’entreprise, votre sourcing fournisseurs doit être fait en fonction d’une stratégie cohérente. Cette démarche doit déboucher sur un choix parmis plusieurs possibilités, toutes présentant des avantages et des inconvénients distincts.<br/><br/>
    
    Qualité des produits, délais de production et de livraison, distance géographique, compétitivité des prix… Ces éléments sont centraux car ils touchent au nerf de la guerre : le produit (ou service) que l’entreprise peut vous fournir. C’est à vous de décider lesquels vous voulez privilégier et lesquels sont les plus importants pour votre activité. Un travail d’analyse comparative vous facilitera la tâche pour identifier le prestataire qui vous convient le mieux. Un partenaire X va être en mesure de vous livrer rapidement mais la qualité de ses produits est inférieure à ceux de Y, qui en revanche exigent un délai de paiement plus court qui peut vous causer des difficultés de trésorerie ? En tant que dirigeant, c’est à vous de trancher.<br/><br/>
    
    <strong>Renseignez-vous sur vos partenaires</strong><br/><br/>
    
    Vous êtes chef d’entreprise ? Alors la pratique qui consiste à se renseigner sur la solvabilité d’un client avant de travailler et signer un contrat avec lui vous est familière ! Il faut procéder de la même manière avec votre fournisseur potentiel. Comme avec un client, vous allez sûrement être amené à travailler avec un prestataire sur le long terme. Dans cette optique, s’informer sur sa capacité à maintenir son activité va se révéler décisif : signer un contrat avantageux et prometteur ne sert à rien si le fournisseur en question fait faillite six mois plus tard et vous pose par les suite des problèmes de stocks … Interrogez votre entourage, contactez les clients déjà existants ; tous les moyens sont bons pour confirmer ou infirmer votre décision en la matière.<br/><br/>
    
    Notons par ailleurs un élément non décisif en soi mais qui peut revêtir une certaine importance dans le cas d’une relation professionnelle de longue durée : ne négligez pas le volet humain et relationnel de votre sourcing fournisseur ! Si vos recherches se concrétisent, vous allez être amené à contacter régulièrement votre interlocuteur. Prenez donc le temps d’apprendre à le connaître ; cerner son caractère facilitera vos relations et pourra même vous apprendre quels leviers actionner pour négocier avec lui.<br/><br/>
    
    <strong>Renforcez vos relations : les contrats en cours</strong><br/><br/>
    
    <strong>Instaurez une confiance mutuelle</strong><br/><br/>
    
    Une entreprise est liée à son fournisseur à travers un contrat de partenariat commercial, qui définit, entre autres, la durée du partenariat ainsi que les prix et les délais de paiement convenus entre les deux parties. Une relation fournisseur, pour être idéale, doit être mutuellement avantageuse et comporter certaines concessions, pour que chaque signataire puisse en tirer un bénéfice équitable. Il est cependant important de garder en tête qu’un contrat n’est jamais gravé dans le marbre et que ses conditions peuvent être amenées à évoluer. C’est ici un élément essentiel car à partir du moment où l’une des deux parties sent que le contrat n’est plus équilibré, une renégociation peut être demandée.<br/><br/>
    
    Un contrat stable n’est pas le seul garant d’un lien solide entre un client et son fournisseur, et leurs échanges ne sont pas uniquement limités à passer des commandes et à régler des factures. Une bonne relation permet de faire profiter à l’autre de son réseau, de son influence et de sa réputation. Exemple : la prochaine fois qu’un dirigeant vous demande des conseils pour l’aiguiller dans le choix de ses futurs fournisseurs, n’hésitez pas à recommander un des vôtres en qui vous avez confiance. Si jamais le contrat vient à se concrétiser, vous pouvez contacter votre fournisseur et tenter d’obtenir un contrat plus avantageux en fonction du nombre de clients que vous dirigez vers lui ! Tout le monde y gagne.<br/><br/>
    
    Attention cependant : si le fait de négocier des remises et des prix intéressants auprès de ses partenaires est monnaie courante, prenez garde à ne pas trop tirer sur la corde ! Exiger des tarifs trop bas risque en effet de faire fuir votre interlocuteur, qui ne verra peut-être pas d’intérêt à continuer à travailler avec vous s’il ne s’y retrouve pas financièrement.<br/><br/>
    
    <strong>Centralisez les relations fournisseurs</strong><br/><br/>
    
    Privilégiez un dialogue constant et transparent avec vos fournisseurs. A cette fin, il est judicieux de nommer une personne de votre entreprise comme responsable des relations fournisseurs, en fonction des ressources et moyens humains dont vous disposez, bien entendu. Le fait de nommer un interlocuteur fixe fluidifie les relations. En effet, à chaque fois qu’un de vos fournisseurs va chercher à contacter votre société, il a l’assurance qu’il sera toujours mis en relation avec la même personne, avec qui il a déjà travaillé dans le passé.<br/><br/>
    
    <strong>Menez une veille constante</strong><br/><br/>
    
    Même si vos relations avec vos fournisseurs sont optimales, un bon état d’esprit à adopter consiste à mettre en place une veille constante : tenez-vous au courant ! Nouvelles technologies, innovations, nouveaux entrants, évolution des processus, diminution des tarifs … Toujours en accord avec votre stratégie fournisseurs, penchez-vous sur la façon dont votre entreprise se fournit aujourd’hui, et comment vous aimeriez qu’elle se fournisse demain.<br/><br/>
    
    Cette démarche a deux facettes distinctes : il est essentiel de se renseigner sur le marché dans sa globalité pour détecter des nouveaux partenariats éventuels, mais également de continuer à se tenir informé sur vos partenaires actuels ! Prêter une attention particulière à leur santé financière et leur capacité à répondre à vos commandes est indispensable pour anticiper des problèmes d’approvisionnement qui peuvent mettre à mal votre entreprise.<br/><br/>
    
    Concentrez-vous sur vos partenaires privilégiés : les fournisseurs-clés<br/><br/>
    
    <strong>Identifiez-les clairement</strong><br/><br/>
    
    En tant que dirigeant, votre temps est précieux et doit être dépensé de façon pertinente : il peut donc être judicieux de concentrer vos efforts sur vos partenaires les plus importants. Posez-vous la question “quelles seront les conséquences si tel ou tel fournisseur cesse de travailler avec mon entreprise ?” Si la réponse vous angoisse, c’est qu’il s’agit d’un fournisseur-clé dont l’activité est essentielle pour le bon fonctionnement de votre société et dont vous ne pouvez pas vous passer. Pas de panique ! Selon la taille de l’entreprise en question, il y a de grandes chances que ce constat soit réciproque et qu’il se retrouve dans la même impasse si vous décidez de mettre un terme à vos relations.<br/><br/>
    
    Cherchez d’abord à comprendre pourquoi il revêt autant d’importance pour votre activité. Peut-être est-il le seul en mesure de vous fournir un produit en particulier, indispensable à votre métier ? Ou alors est-il le seul à pouvoir vous livrer dans des délais qui ne mettent pas à mal votre cycle de production ? Est-il le seul à proposer des quantités qui vous conviennent ? L’essentiel est de comprendre pourquoi la relation avec ce fournisseur est si importante, et d’en tirer des leçons par rapport aux liens que vous avez tissé avec lui.<br/><br/>
    
    Une fois ce constat établi, chercher activement à renforcer votre rapport avec ce partenaire doit être une priorité. Il est essentiel de comprendre qu’une relation privilégiée avec ses fournisseurs est un véritable avantage concurrentiel sur lequel il faut vous appuyer et communiquer ! La fiabilité des prestataires d’une entreprise est un gage de confiance qui peut faire la différence pour votre clientèle.<br/><br/>
    
    <strong>Conservez votre indépendance</strong><br/><br/>
    
    Réaliser que l’activité de votre entreprise est fortement liée à celle d’un fournisseur en particulier doit également vous interpeller : c’est là une situation potentiellement risquée en fonction de l’importance du fournisseur. Comme expliqué précédemment, si son entreprise est semblable à la vôtre en termes de moyens financiers et humains, il est probable qu’il soit autant dépendant de vous que vous l’êtes de lui. En revanche, une structure plus large qui réalise un chiffre d’affaires annuel important et compte un grand nombre de clients ne valorise probablement pas votre relation de la même façon que vous.<br/><br/>
    
    Il n’est jamais bon de se retrouver dans ce type de situation : une dépendance trop forte à un prestataire n’est pas souhaitable, peu importe le secteur. Il peut donc être judicieux d’engager une nouvelle phase de sourcing fournisseurs. Nouer de nouveaux contrats avec d’autres partenaires, c’est jouer la carte de la sûreté. Si jamais l’un des contrats vient à prendre fin abruptement, l’entreprise ne se retrouvera pas dans l’impasse et pourra compter sur d’autres structures et maintenir son activité.<br/><br/>
    
    Il s’agit à nouveau de définir les priorités de l’entreprise à ce sujet : allez-vous préférer travailler avec une multitude de fournisseurs, peut-être plus chers mais très fiables, plutôt que compter sur un seul prestataire moins onéreux mais qui n’a pas spécialement besoin de votre activité pour subsister ? Ce choix découle directement de votre stratégie fournisseurs.<br/><br/>
    
    <strong>Assurez vos arrières : en cas d’imprévus</strong><br/><br/>
    
    Entretenir une bonne relation avec vos fournisseurs, c’est aussi appréhender et réagir de manière appropriée en cas de soucis ! En effet, il n’est pas impossible que l’une des deux parties viennent à commettre une erreur par rapport aux termes du contrat initiaux : personne n’est à l’abri d’un retard de paiement, d’une erreur de commande ou d’un produit qui ne correspond pas aux attentes initiales. Accorder le bénéfice du doute est très important dans ce genre de situation, et privilégier le dialogue au conflit fera la différence entre une bonne et une mauvaise relation. Cherchez à comprendre d’où vient l’erreur, à quel niveau elle se situe : des explications rationnelles sont toujours appréciables et il est plus facile d’en tirer des leçons pour mieux avancer par la suite.<br/><br/>
    
    Prenons un exemple : votre fournisseur vous fait parvenir votre commande. Seul problème, la marchandise est défectueuse et inutilisable en l’état. Même si cela pose des problèmes par rapport à votre entreprise, la situation peut être un aubaine pour renforcer votre relation avec lui. Contactez-le et exposez-lui la situation. En fonction de sa réaction, vous allez pouvoir obtenir de sa part un geste commercial pour compenser les pertes causées par son erreur : ristourne sur la prochaine commande, remboursement partiel ou total, facilités de paiement, etc.<br/><br/>
    
    Le but ici n’est pas d’exploiter une situation pour votre profit personnel, mais plutôt de faire comprendre à votre interlocuteur que dans une situation inverse, il va pouvoir compter sur votre coopération de la même manière. Agir ainsi peut même l’amener à vous devancer et vous proposer ce genre de compromis avant même que vous ne le demandiez !<br/><br/>
    
    Pour conclure<br/><br/>
    
    <ul class="blog-list">
      <li class="blog-list-li">Instaurez un climat d’honnêteté et de transparence avec vos fournisseurs : le jeu en vaut la chandelle !</li><br/><br/>
      <li class="blog-list-li">Un bon contrat fournisseur est un contrat mutuellement bénéfique : les deux parties doivent pouvoir tirer le maximum de cette relation, gardez cela en tête au moment de signer.</li><br/><br/>
      <li class="blog-list-li">Les termes d’un contrat peuvent être amenés à évoluer : ce qui vous satisfait aujourd’hui ne vous conviendra peut-être plus dans 6 mois, 1 ou 2 ans ! Négociez, faites des concessions, soyez flexible.</li><br/><br/>
      <li class="blog-list-li">Sachez privilégier les bons partenaires, de manière judicieuse : ne vous rendez pas trop dépendant d’un seul prestataire au risque de vous retrouver dans une impasse s’il vient à mettre fin à votre relation.</li><br/><br/>
    </ul>
    
    <strong>Crédit : AGICAP</strong><br/><br/>
    
    <a href="https://agicap.com/fr/article/entretenir-de-bonnes-relations-fourniss">https://agicap.com/fr/article/entretenir-de-bonnes-relations-fourniss</a>`
  },
  {
    id: 16,
    title: "Comment réussir sa prospection commerciale B2B en 5 étapes",
    category: "Auto-Entrepreneur , Fournisseur",
    date:'mars 1, 2016',
    description: "L’objectif de réussir sa prospection commerciale B2B est partagé pour toutes les entreprises. Derrière cet objectif se cache en réalité de nombreuses stratégies marketing devant sans cesse être adaptées à l’état du marché, aux nouveaux modes de publicité et aux besoins des prospects.",
    imageUrl: image16,
    content:`B2B, l’objectif de réussir sa prospection commerciale B2B est partagé pour toutes les entreprises. Derrière cet objectif se cache en réalité de nombreuses stratégies marketing devant sans cesse être adaptées à l’état du marché, aux nouveaux modes de publicité et aux besoins des prospects.<br/><br/>

    Certaines stratégies ont déjà fait leurs preuves au sein des plus grands groupes, en augmentant considérablement la conversion des prospects en clients et en fidélisant les clients. Quelles actions concrètes planifier ? Comment maîtriser les nouveaux outils et les nouveaux canaux de prospection commerciale en B2B ? Pour tout savoir sur la prospection commerciale B2B, voici 5 étapes essentielles à insérer dans votre stratégie marketing pour augmenter vos ventes.<br/><br/>
    
    Les actions les plus efficaces en prospection commerciale B2B<br/><br/>
    La prospection commerciale représente des heures de travail pour votre équipe de commerciaux et un budget plus ou moins conséquent selon les canaux de prospection que vous privilégiez. Dès lors, peu d’entreprises peuvent se permettre de tester ici et là plusieurs actions de prospection sans réelle vue d’ensemble sur le retour sur investissement (ROI) espéré. Par conséquent, la stratégie d’opter pour les meilleures pratiques de la prospection commerciale B2B déjà testées par les autres entreprises présentant un ROI élevé semble être l’option la plus rentable pour votre entreprise.<br/><br/>
    
    Les pratiques de prospection commerciale évoluent au fur et à mesure de l’émergence de nouveaux supports de communication et de la sensibilité des prospects face à une démarche commerciale. Sans réelle surprise, le démarchage téléphonique impersonnel qui autrefois affichait de beaux résultats de vente s’essouffle de plus en plus face au démarchage personnalisé et moins intrusif. La prospection commerciale en B2B n’échappe pas à ce phénomène dans une moindre mesure.<br/><br/>
    
    Le démarchage par internet est donc déjà depuis plusieurs années sur le haut du classement en termes de ROI. Que ce soit par une campagne emailing ou par une stratégie d’Inboung Marketing, le marketing digital s’impose dans tous les secteurs d’activités.<br/><br/>
    
    La prospection digitale vient dès lors en complément du démarchage sur le terrain, afin de pouvoir atteindre tous les profils de prospects, qu’ils soient sensibles à l’attractivité du numérique ou à la négociation commerciale en présentiel.<br/><br/>
    
    L’organisation : la clé de voûte de toutes les actions commerciales rentables
    Face à la concurrence de plus en plus présente et au taux de conversion de clics en ventes toujours plus rapide, en tant qu’entreprise vous êtes amené à organiser :<br/><br/>
    
    des réunions préalables avec vos commerciaux afin d’établir des objectifs de ventes ;<br/><br/>
    des actions concrètes de prospection commerciale sur différents canaux ;<br/><br/>
    des rendez-vous avec vos prospects et vos clients ;<br/><br/>
    des debriefings réguliers avec votre équipe marketing et vos commerciaux ;<br/><br/>
    des actions concrètes de fidélisation de vos clients.<br/><br/>
    Comment organiser sa prospection commerciale B2B ? De nombreux outils sont devenus indispensables pour programmer au mieux les différentes actions de démarchage. Le CRM notamment est bien connu des entreprises. Il permet de centraliser dans un même outil les informations importantes concernant l’offre de produits ou de prestations de service que vous proposez à vos clients et à vos prospects. Le CRM peut également contenir différentes informations relatives à vos clients <br/><br/>comme :<br/><br/>
    
    la régularité des commandes effectuées ;<br/><br/>
    la gamme de produits ou de services régulièrement commandée ;<br/><br/>
    les avis des clients sur vos produits ou prestations de service ;<br/><br/>
    etc.<br/><br/>
    Vos commerciaux pourront dès lors adapter leur discours selon chaque profil de client ou de prospect tout en ayant en visuel leurs objectifs de vente.<br/><br/>
    
    Un CRM performant permet également de faciliter la communication d’informations importantes au sein de votre entreprise, pour notamment fluidifier la répartition des tâches entre chaque collaborateur.<br/><br/>
    
    Les outils d’optimisation pour cibler les prospects et fidéliser les clients<br/><br/>
    
     
    
    Afin de ne pas faire perdre du temps à vos commerciaux et de l’argent à votre entreprise dans des campagnes de prospection peu fructueuses, cibler les prospects qui présentent un réel intérêt pour vos produits ou services s’avère indispensable.<br/><br/>
    
    Comment optimiser sa prospection commerciale B2B ? Centraliser ses actions de prospection sur une cible prédéfinie s’avère essentiel. Pour cela, vous devez préalablement définir le profil  » parfait  » d’un prospect selon votre activité commerciale. Cette étape permettra ensuite à votre équipe marketing et à vos commerciaux d’adapter le contenu marketing et d’orienter la prospection vers les prospects les plus intéressés par vos produits ou services.<br/><br/>
    
    Pour vous aider dans cette tâche de qualification d’un prospect idéal, vous pouvez notamment prendre en compte l’historique de vos différentes ventes afin de regrouper les critères les plus représentés chez vos clients.<br/><br/>
    
    Là encore, l’outil à privilégier est un CRM qui vous permettra d’analyser avec efficacité toutes les données que votre entreprise possède concernant vos ventes.<br/><br/>
    
    En complément d’un CRM, vous pouvez également utiliser un outil d’optimisation de campagne emailing. Cet outil d’optimisation permettra à vos commerciaux d’adapter facilement les mails de démarchage selon chaque prospect. Vos commerciaux pourront ainsi être guidés dans la prospection par email en adaptant notamment :<br/><br/>
    
    l’identité du prospect ;<br/><br/>
    la qualification du besoin identifié du prospect ;<br/><br/>
    la réponse au besoin du prospect avec une offre commerciale adaptée.<br/><br/>
    Ces outils performants de campagne emailing vous offrent aussi un visuel sur le taux d’ouverture de vos mails envoyés ainsi que sur le taux de clics dans vos mails pouvant amener sur la page d’accueil de votre site internet, sur un formulaire de prise de contact, etc.<br/><br/>
    
    Vous pourrez également facilement adapter votre campagne emailing en programmant des relances automatiques de mails. Ces relances automatiques s’avèrent très efficaces pour les prospects les plus sensibles à l’envoi de vos mails de prospection.<br/><br/>
    
    Les points importants à connaître pour réussir sa négociation commerciale<br/><br/>
    
     
    
    La prospection commerciale peut également aboutir sur une négociation commerciale. La négociation commerciale s’avère être un exercice qui ne s’improvise pas. Pour réussir sa négociation commerciale, il est essentiel que les commerciaux faisant partie de votre équipe puissent avoir accès à toutes les informations nécessaires concernant les produits ou services proposés par votre entreprise et le profil du client à convaincre.<br/><br/>
    
    Pour cela, les informations concernant le produit ou la prestation de service que vous proposez doivent être connues et si besoin accessibles au moment de la négociation par vos commerciaux. Les principales informations à maîtriser lors de la négociation sont notamment :<br/><br/>
    
    les différentes gammes de produits ou de prestations de service proposées à vos prospects ;<br/><br/>
    les prix pratiqués ;<br/><br/>
    les caractéristiques du produit ou de la prestation de service ;<br/><br/>
    les éventuelles offres commerciales de bienvenue ;<br/><br/>
    etc.<br/><br/>
    Un CRM performant et fonctionnel vous permettra de synthétiser toutes les informations indispensables à une bonne négociation commerciale. Ce logiciel pourra dès lors comprendre les informations de base relatives à votre activité économique ainsi que plusieurs autres informations indispensables concernant le profil du prospect à convaincre.<br/><br/>
    
    Ainsi, le commercial en charge de la négociation commerciale pourra identifier les besoins du lead et adapter ses arguments pour convaincre ce prospect de faire confiance à vos produits ou services.<br/><br/>
    
    De plus, s’il s’agit d’une renégociation commerciale, l’historique des précédentes ventes et des précédentes négociations permettra à votre commerciale d’adapter son offre. La maîtrise de la renégociation commerciale permettra notamment de préserver le lien de fidélité et de confiance qui unit votre client à votre entreprise.<br/><br/>
    
    L’Account Based Marketing ou l’art de se recentrer sur les prospects stratégiques<br/><br/>
    
    L’Account Based Marketing est une technique de prospection commerciale de plus en plus adoptée pour des ventes en B2B. Cette technique de prospection se démarque des autres méthodes classiques basées sur une publicité répandue d’un produit ou d’une prestation de services.<br/><br/>
    
    Avec l’Account Based Marketing, l’objectif premier n’est pas de séduire le plus grand nombre de prospects. Il s’agit au contraire de privilégier une prospection auprès des leads représentant le plus fort potentiel à devenir client de votre entreprise.<br/><br/>
    
    Cette méthode de prospection répond notamment aux problèmes du faible pourcentage de ventes par rapport au nombre de leads impactés par la prospection commerciale classique. Afin de maximiser son ROI, l’Account Based Marketing nécessite d’adapter l’offre à une cible bien spécifique de potentiels clients.<br/><br/>
    
    Pour bien prospecter avec l’Account Based Marketing, le CRM s’avère encore une fois un précieux outil de gestion des données clients. En effet, les données stockées dans le CRM de votre entreprise permettent d’avoir toutes les informations nécessaires pour recentrer la prospection commerciale autour des quelques leads présentant un fort potentiel à devenir client. Les informations contenues dans votre CRM permettront dès lors de créer une offre personnalisée à chaque profil de potentiel client.<br/><br/>
    
    Nous vous recommandons ces autres pages :<br/><br/>
    
     
    
    Qu’est-ce que la prospection commerciale B2B ?<br/><br/>
    Pourquoi faire une prospection commerciale B2B ?<br/><br/>
    Comment réussir sa prospection commerciale par téléphone ?<br/><br/>
    La prospection B2B par email<br/><br/>
    Quelle solution de prospection commerciale B2B choisir ?<br/><br/>
    Crédit : Nomination<br/><br/>
    
    <a href='https://www.nomination.fr/prospection-commerciale-b2b/comment-reussir-sa-prospection-commerciale-b2b'>https://www.nomination.fr/prospection-commerciale-b2b/comment-reussir-sa-prospection-commerciale-b2b</a><br/><br/>`
  },
  {
    id: 17,
    title: "Comment trouver des fournisseurs fiables en tant qu'Entrepreneur",
    category: "Fournisseur",
    date:'mars 1, 2016',
    description: "Que vous proposiez des services ou des produits, vous êtes appelé(e), en tant qu’indépendant, à travailler avec au moins un fournisseur. Mais où chercher le partenaire idéal ? Votre fournisseur détermine indirectement la qualité et le prix de vos produits et services. Il est dès lors essentiel de le choisir avec soin.",
    imageUrl: image17,
  },
  {
    id: 18,
    title: "Comment trouver les meilleurs fournisseurs pour votre projet d'entreprise",
    category: "Fournisseur",
    date:'mars 1, 2016',
    description: "Votre fournisseur va déterminer indirectement la qualité et le prix de vos produits. Un mauvais fournisseur peut détruire votre crédibilité. A l’inverse, un bon fournisseur augmentera la valeur de votre business. Il est donc essentiel de le choisir avec soin.",
    imageUrl: image18,
    content:`Meilleurs fournisseurs comment trouver pour votre projet
    Meilleurs fournisseurs comment trouver pour votre projet ? Votre fournisseur va déterminer indirectement la qualité et le prix de vos produits. Un mauvais fournisseur peut détruire votre crédibilité. A l’inverse, un bon fournisseur augmentera la valeur de votre business. Il est donc essentiel de le choisir avec soin.<br/><br/>
    
    QUE RECHERCHEZ-VOUS ?<br/><br/>
    
    Vous avez détecté une bonne opportunité ou vous souhaitez faire fabriquer un produit de A à Z. Il est important, avant de vous lancer dans vos recherches, de déterminer quel type de fournisseurs vous recherchez.<br/><br/>
    
    Plusieurs options existent:<br/><br/>
    
    Un fabricant qui pourra donner vie à votre propre idée de produit.<br/><br/>
    Un fournisseur qui propose une offre de gros pour des marques et des produits qui existent déjà.<br/><br/>
    Un dropshipper pour fournir des produits et gérer les commandes de produits et de marques qui existent déjà. Le dropshipping est une solution si vous ne disposez pas de ressources financières suffisantes ou d’un entrepôt assez grand.
    FOURNISSEURS BELGES, EUROPÉENS OU ÉTRANGERS ?<br/><br/>
    
    Une question importante à laquelle vous devez aussi répondre avant de commencer vos recherches est de savoir si vous voulez vous approvisionner en Belgique ou à l’étranger. Vous savez probablement déjà qu’il est généralement moins cher d’obtenir vos produits à l’étranger (en Asie par exemple), mais il y a d’autres points à considérer pour prendre votre décision.<br/><br/>
    
    L’approvisionnement au niveau national ou européen impliquera de meilleurs standards de travail et une meilleure qualité de fabrication. Vous aurez également un avantage marketing lié au fait que vos produits seront fabriqués en Belgique ou en Europe, un détail qui plaît de plus en plus aux consommateurs. Les délais de livraison seront forcément moins longs. Par contre, les coûts de fabrication seront plus élevés et le choix de produits plus restreint (certains articles ne sont d’ailleurs même plus fabriqués en Europe).<br/><br/>
    
    En ce qui concerne l’approvisionnement à l’étranger (essentiellement en Asie), les coûts de fabrication sont plus bas et il existe une très longue liste de fabricants parmi lesquels choisir. Par contre, les standards de travail sont souvent négligés et les produits de moins bonne qualité. A noter que, si vous souhaitez faire fabriquer un nouveau produit, il y a très peu de protection intellectuelle et votre idée ne sera donc pas protégée. La communication peut être également plus difficile, car tous les fournisseurs n’ont pas forcément un bon niveau d’anglais. Les délais et coûts de livraison seront plus importants,  et attention également à ne pas oublier les procédures liées à la douane et à l’importation. <br/><br/> 
    
    PAR OÙ COMMENCER ?<br/><br/>
    
    Maintenant que vous savez ce que vous recherchez, comment trouver vos futurs fournisseurs ?<br/><br/>
    
    Les méthodes suivantes peuvent être utilisées :   <br/><br/>
    
    Internet sera votre meilleur atout. Veillez à utiliser les bons mots-clés via un moteur de recherche (exemple : « fournisseurs/grossistes + la thématique du produit cherché») et à tester avec différentes combinaisons de mots-clés. Attention, car beaucoup de sites de fournisseurs sont peu optimisés et n’apparaîtront donc pas dans les premières recherches. Ne vous fiez pas non plus à la qualité et au design de leur site web. C’est souvent un aspect qui est négligé chez les vendeurs en gros qui jouent beaucoup moins sur l’expérience client qu’un vendeur de détail.<br/><br/>
    Utilisez les annuaires BtoB en ligne. Ces annuaires qui contiennent des milliers de fournisseurs peuvent paraître la solution magique, mais prudence, beaucoup ne sont pas mis à jour. Pour la Belgique et l’Europe vous avez entre autres : Kompass et Europages et pour l’Asie : made-in-china.com et Alibaba.<br/><br/>
    Les salons, réservés aux professionnels, permettent également de prendre contact avec des fournisseurs. Mais avant de réserver votre ticket pour un salon, vérifier bien que l’investissement est intéressant en faisant quelques recherches sur les sociétés présentes.<br/><br/>
    Les recommandations : certains des meilleurs partenariats peuvent provenir de recommandations de la part de connaissances ou d’autres entrepreneurs.<br/><br/>
    COMMENT CONTACTER DES FOURNISSEURS ?<br/><br/>
    
    Une fois que vous avez trouvé un fournisseur, comment allez-vous l’approcher ?<br/><br/>
    
    Par e-mail ou par téléphone, le premier contact est important et voici quelques questions essentielles à considérer :<br/><br/>
    
    Quelle est la quantité minimale de commande ? (MOQ en anglais, « minimum order quantity ».) Assurez-vous que ce minimum fonctionne pour vous et que vous avez les moyens financiers pour investir. Cette quantité minimale de commande va varier largement en fonction du produit demandé et du fournisseur, et même si cet aspect est souvent négociable, il est important d’aborder la question à l’avance.<br/><br/>
    Quel est le prix pour un échantillon ? Commander des échantillons avant de passer une commande complète reste la meilleure façon de vous assurer de la qualité des produits. Le prix des échantillons va aussi varier en fonction du produit et du fournisseur.<br/><br/>
    Quel est le prix de production ? L’une des questions les plus importantes que vous allez devoir poser est : « combien va vous coûter la fabrication du produit ? ». Vous devriez probablement leur demander le prix pour plusieurs quantités, afin de vous faire une idée de leur tarif. Si vous faites fabriquer une grosse quantité de produits, le prix de revient par unité sera moins élevé.<br/><br/>
    Quel est le délai de fabrication ? Connaître le délai nécessaire pour produire votre commande est une information très importante, et en fonction de votre domaine d’activité et du profil de votre client, ce critère influencera la qualité de votre service.<br/><br/>
    Quelles sont les conditions de paiement ? Plusieurs fournisseurs vont exiger des nouvelles entreprises de payer à l’avance la totalité de la  commande . Cela est important à savoir puisque le stock va représenter un coût élevé pour les startups.
    Les fournisseurs reçoivent énormément de demandes de la part d’acheteurs peu sérieux. Il est donc habituel de ne pas recevoir une réponse de leur part. Pour mettre toutes les chances de votre côté, évitez les e-mails trop longs et allez à l’essentiel. Le premier e-mail que vous envoyez à un fabricant doit être clair et précis. Évitez d’en dire trop sur vous-même et votre projet. Evitez également de mentionner une quantité de commande trop faible. Si vous demandez un devis qui est bien en dessous de la commande minimale, vous risquez de ne pas recevoir de réponse. D’où l’importance de demander d’abord les quantités minimales de commande. Et dernier conseil, contactez le fournisseur dans sa langue ou éventuellement en anglais.<br/><br/>
    
    QUAND FAIRE LA DÉMARCHE ?<br/><br/>
    
    Commencez vos recherches dès le début, à savoir lors de l’élaboration de votre plan d’entreprise ou business plan. Une part non négligeable de vos frais va dépendre des fournisseurs. Il est donc important de les répertorier dans votre plan financier.<br/><br/>
    
    Beaucoup de fournisseurs refusent de donner des informations si vous n’avez pas de numéro d’entreprise. Tentez d’abord de négocier pour obtenir des catalogues de produit et des listes de prix en expliquant que vous êtes dans le processus de création de votre entreprise et que vous cherchez à construire un partenariat sur le long terme.<br/><br/>
    
    COMMENT SAVOIR SI UN FOURNISSEUR EST FIABLE ?<br/><br/>
    
    Pour vérifier la fiabilité d’un fournisseur potentiel,  comprendre s’il répond à vos exigences et s’il offre un bon rapport qualité-prix, vous pouvez utiliser les critères suivants :<br/><br/>
    
    Le fournisseur est-il aisément joignable ? Testez si le fournisseur est facilement accessible par téléphone et par e-mail. Si le fournisseur met plus de quelques jours à chaque fois pour vous répondre et est peu réactif, cela montre déjà un problème de fiabilité et de rapidité du service.<br/><br/>
    La société est-elle digne de confiance ? Vérifiez toujours si la société est légitime et quand elle a été créée. Cela vous donnera des indications sur l’expérience du fournisseur et cela vous évitera également de tomber dans une arnaque.<br/><br/>
    Quelles sont les conditions de vente ? Lisez bien les conditions de vente afin d’éviter tout mal entendu lors d’une future collaboration. Si le fournisseur n’a pas de conditions de vente écrites, vérifiez bien que toutes les informations importantes se trouvent dans le contrat ou demandez l’ajout de certaines informations manquantes (ex : délai et moyen de paiement, délai de livraison, modalités de retour et modalités en cas de marchandises défectueuses).<br/><br/>
    Existe-il un service après-vente ? Une bonne politique de retour concernant les colis endommagés est primordiale, et encore plus si vous faites appel au dropshipping.<br/><br/>
    Si la réponse à ces questions n’est pas claire ou si la personne de contact ne vous donne pas de réponse complète, tirez-en des conclusions et cherchez d’autres fournisseurs.<br/><br/>
    
    Enfin, le moyen ultime pour s’assurer de la qualité des produits et du professionnalisme du fournisseur est de tester la collaboration. Si vous avez du temps et des moyens financiers, passez une petite commande ou demandez quelques échantillons. Cela vous permettra de vérifier la rapidité et fiabilité du fournisseur, la qualité des produits et le service de livraison.<br/><br/>
    
    Comme vous l’aurez compris, trouver de bons fournisseurs prend du temps. En utilisant la méthode présentée ci- dessus, vous mettez toutes les chances de votre côté pour construire des partenariats de confiance sur le long terme.<br/><br/>
    
    Crédit : 1819 Hub Brussels<br/><br/>
    
    <a href="https://1819.brussels/infotheque/avant-de-demarrer/comment-trouver-les-meilleurs-fournisseurs-pour-votre-projet">https://1819.brussels/infotheque/avant-de-demarrer/comment-trouver-les-meilleurs-fournisseurs-pour-votre-projet</a>`
  },
  {
    id: 19,
    title: "Ecommerce – Le guide le plus complet pour réussir au Maroc",
    category: "Fournisseur",
    date:'mars 1, 2016',
    description: "En ecommerce, au lieu d’avoir une vitrine physique en fer et béton pour y vendre des produits, vous pouvez avoir un site web et vous y placer les photos et les descriptions de vos produits, chaque visiteur qui visite votre site aura alors l’opportunité d’acheter vos produits et de vous paye en ligne.",
    imageUrl: image19,
    content:`Ecommerce – Le guide le plus complet pour réussir au Maroc
    L’ecommerce est tout simplement le commerce que nous tous connaissons (acheter un produit et le vendre), mais de manière digitale…<br/><br/>
    
    En ecommerce, au lieu d’avoir une vitrine physique en fer et béton pour y vendre des produits, vous pouvez avoir un site web et vous y placer les photos et les descriptions de vos produits, chaque visiteur qui visite votre site aura alors l’opportunité d’acheter vos produits et de vous paye en ligne.<br/><br/>
    
    L’ecommerce est un business fleurissant Au Maroc, il y existe principalement deux types du ecommerce :<br/><br/>
    
    COD : le cash on delivery, ou le paiement à la livraison, vous commandez en ligne et vous ne payez le vendeur qu’au moment de la réception de votre produit<br/><br/>
    
    Paiement en ligne : vous payez le vendeur sur son site web, puis il vous livre le produit.<br/><br/>
    
    La forme du ecommerce la plus répandu au Maroc est celle de la COD, les marocains achètent de plus en plus en ce mode pour deux raisons :<br/><br/>
    
    La pandémie de Corona à convaincue les marocains de la nécessité d’apprendre à acheter sur internet car elle était la seule option pendant les mois du couve feu.<
    Des acteurs majeurs comme Jumia ont entré le marché marocain, ils ont investi des budgets colossaux sur le marketing pour répande la culture du eshoping, et attirer le consommateur marocain.
    Le paiement en cash à la livraison est la formule la plus rassurante pour les marocains et les pays de MENA pour acheter en ligne, il n’y a pas encore assez de confiance du coté consommateur dans les transactions en ligne, et il n’est y’est pas encore habitué.
    Dans le reste de l’article, on va se focaliser sur le COD : cash on delivery.<br/><br/>
    
    Ecommerce : les étapes à suivre pour établir un business ecommrce/COD au Maroc :<br/><br/>
    
    Voici rapidement les étapes et les bonnes pratiques pour construire un business ecommerce basé sur le mode de paiement à la livraison au Maroc, on va expliquer chacune de ces étapes par la suite :<br/><br/>
    
    Choisir la bonne approche selon la taille de votre business et de votre budget
    Choisir la bonne plateforme digitale pour votre vitrine en ligne
    Bien choisir le produit à vendre
    Choisir un fournisseur pour votre produit
    Choisir la bonne stratégie de marketing digital pour commercialiser vote produit
    Donner le bon prix à votre produit
    ECommerce : les étapes à suivre pour établir un business e-commerce/COD solide et rentable au Maroc :
    
    1-    Choisir la bonne approche selon la taille de votre business et de votre budget :<br/><br/>
    
    Le ecommerce est un business qui n’est pas aussi facile comme on le présente.<br/><br/>
    
    Au fait, les experts de l’e-commerce et les gens qui le pratiquent réellement (y compris moi qui a rédigé cet article), surtout le COD (cash on delivery), confirment que c’est un domaine assez compétitif et challengent, il requiert le sens d’analyse, une aisance avec les chiffres, être à jour avec les nouveautés, les stratégies et les outils du marketing digital, et avoir surtout de la persévérance et d’une vision de long terme.<br/><br/>
    
    Pour réussir dans l’ecommerce il faut tout d’abord poser un business plan solide et bien pensée.<br/><br/>
    
    Vous pouvez trouver plusieurs modèles sur internet de business modèle, mais c’est rare que vous allez trouver un plan adapté à l’ecommerce, et plus précisément le cash on delivery au Maroc. Si vous souhaitez recevoir le business plan avec lequel je travail, vous pouvez m’envoyer un email sur admin@csfunnel.ma, et je vous enverrai celui avec lequel je travail.<br/><br/>
    
    Parmi les points les plus importants dans votre business plan ecommerce c’est celui de votre budget actuel.<br/><br/>
    
    Votre budget actuel va déterminer quels produits vous allez vendre, quelle stratégie de marketing vous allez suivre pour promouvoir vos produits, quels outils vous allez utiliser dans votre business, et bien sûr combien de ressources humaines vous pouvez faire travailler avec vous.<br/><br/>
    
    Au Maroc, vous pouvez vous positionner au sein de ces trois tranches en ce qui concerne le ecommerce :<br/><br/>
    
    Un budget de : 4000dh à 50.000 dh—-à Débutant
    
    Un budget de : 60.000 dh à 200.000 dh—à intermédiaire
    
    Un budget plus que 200.000 dh-à au-delà de l’intermédiaire.
    
    Il faut noter que les niveaux débutant, intermédiaire…ne s’agit pas de vote expérience en ecommerce, mais de votre capacité financière par rapport au marché marocain et le niveau de compétition qui y existe actuellement.<br/><br/>
    
    Dépendant de votre capacité financière, vous allez pouvoir…<br/><br/>
    
    Choisir la bonne approche de vente et de management de votre business cash on delivery :<br/><br/>
    
    Voici un plan de guide pour réussir votre business ecommerce selon votre capacité financière (votre budget) :<br/><br/>
    
    Un budget de : 4000dh à 50.000 dh :<br/><br/>
    
    Dans cette tranche de budget votre business ecommerce sera en phase de démarrage, vote ultime objectif sera ainsi de réaliser assez de ventes pour couvrir vos dépenses, vous devez dans cette étape favoriser la découverte du marché marocain, apprendre à gérer votre équipe qui sera probablement composée de vous et d’une fille qui fait la confirmation des commandes par téléphone.<br/><br/>
    
    Vous allez aussi vous focaliser sur la construction d’une base de données de clients satisfaits qui vont vous recommander, et revenir acheter de vous.<br/><br/>
    
    Vous serez aussi amené à vous focaliser sur une seule plateforme de commercialisation de vos produits.<br/><br/>
    
    L’essentiel dans cette étape dans votre business ecommerce est :<br/><br/>
    
    Découvrir le marché
    Bâtir une base de clients satisfaits
    Maitriser la publicité sur Facebook
    Trouver une bonne personne pour confirmer les commandes par téléphone
    Etablir une bonne relation avec les grossistes
    Générer des revenus pour couvrir seulement les dépenses
    Gagner de la confiance en soi.
    Une remarque : Dans cette étape vous allez rencontrer plusieurs difficultés, la plus importante c’est le retour des commandes non livrées, un client commande sur votre site et puis refuse recevoir sa commande, cela est dû à plusieurs raisons, mais la plus importante c’est l’entreprise de livraison avec laquelle vous travailler.
    
    L’entreprise de livraison peut détruire complétement votre business ecommerce si elle n’est pas sérieuse, malheureusement seulement quelque entreprise de livraison qui se respecte au Maroc, alors informez-vous très bien avant d’envoyer vos colis avec une entreprise de livraison, entrez dans les groupes Facebook de l’ecommerce au Maroc, posez vos questions à propos des meilleurs entreprises de livraison ,et vous allez recevoir de très belles réponses qui vont vous économisez des milliers de dirhams.<br/><br/>
    
    Le critère le plus important pour choisir l’entreprise de livraison pour votre business ecommerce est comment elle fait le suivi avec votre client, c’est à dire : est-ce que le livreur appel le client par téléphone à l’avance pour programmer la livraison ?<br/><br/>
    
    Si le client change de rendez-vous, est ce que l’entreprise de livraison accepte cela sans vous charger quoi que ce soit de plus ?<br/><br/>
    
    Est-ce que le livreur peut attendre le client pendant un bon moment pour le rencontrer ?<br/><br/>
    
    Est-ce que le livreur est assez éduqué pour pouvoir tenir une conversation de bon niveau avec les clients ? …<br/><br/>
    
    Ces questions sont un premier filtre, mais mon conseil est de croire au test, faites passez vous-même une commande de votre propre produit et observez le comportement du livreur avec vous, c’est là que vous allez pouvoir prendre une très bonne décision quant à l’entreprise de livraison.<br/><br/>
    
    Or au Maroc, un bon modèle c’est Jumia, elle possède un service de livraison infaillible est très performant, et je pense qu’elle est encore d’ouvrir ce service aux entreprises qui vendent hors Jumia, je serai parmi les premiers clients !<br/><br/>
    
    Si vous voulez ma recommandation, alors envoyez-moi un email à admin@csfunnel.ma, et je t’enverrai en privé les entreprises que je recommande.<br/><br/>
    
    Un budget de : 60.000 dh à 200.000 dh<br/><br/>
    
    Un tel budget vous permet d’optimiser votre travail et accélérer la vitesse de votre business e-commerce.<br/><br/>
    
    Ce budget vous permettra de recruter une fille expérimentée pour vous faire la confirmation des commandes et le suivi avec les clients.
    
    Vous pouvez créer des capsules vidéos professionnelles pour vos publicité, par expérience, plus la vidéo promotionnelle du produit est de qualité professionnelle, plus le cout de publicité diminue, plus vous réaliser des ventes, et plus votre marque/entreprise devient de plus en plus connues.<br/><br/>
    
    Pour le ecommerce au Maroc, vous pouvez obtenir une vidéo top à 2000 dh, ou vous pouvez même louer un studio professionnel et bien équipé pour faire vous-même vos vidéos.<br/><br/>
    
    Pour la dernière option, je recommande personnellement le studio professionnel de l’école Racine de l’enseignement supérieure Marrakech, il était monté par des professionnels de l’audio-visuel en collaboration avec les experts du marketing digital, en plus les prix de locations sont abordables tous.<br/><br/>
    
    Votre budget à ce stade vous permettra aussi de recruter os propres livreurs dans les grandes villes au Maroc, cela va multiplier par 10 la rapidité de livraison et vous allez voir une augmentation pas moins de 10% du taux de livraison.<br/><br/>
    
    Vous allez envoyer à chaque livreur une quantité de stock dans la ville où il se trouve, une fois la commande est confirmée, vous lui envoyez un message pour la livrer sur champs, Imaginer alors la surprise du client lorsqu’il reçoit en moins de 2 heures sa commande, ça va faire parler de votre entreprise dans un très bon sens, et vous serez réputé de digne de confiance.<br/><br/>
    
    Dans cette étape, vous pouvez aussi augmenter la quantité de ton stock, plus de stock est égal à plus de potentiel de vente et de revenues, et vous pouvez même personnaliser vote emballage pour vous démarquer, et faire connaitre encore plus votre marque.<br/><br/>
    
    Un budget plus que 200.000 dh :<br/><br/>
    
    Si vous avez un tel budget, alors vous serez capable de faire tout ce qu’on a vu jusqu’ici à grande échelle, c’est-à-dire vous allez pouvoir commander beaucoup plus de stock, avoir plus qu’une seule personne pour te faire la confirmation des commandes, avoir plusieurs livreurs qui travaillent avec vous uniquement, investir dans des plateformes pour faire la publicité, avoir plus de vidéos professionnelles…etc<br/><br/>
    
    Dans cette étape, i faut commencer à mettre votre marque et ne se focaliser pas seulement sur la vente.<br/><br/>
    
    Une marque bien établie vous fera gagner des sommes d’argent colossaux, car vous aurez moins besoin de payer les publicités digitales puisqu’il suffit d’envoyer un sms, un email ou un message Whatsapp pour inviter vos clients précédant d’acheter votre nouveau produit. En plus…<br/><br/>
    
    Ces mêmes clients vont vous recommander auprès de leurs proches si vous avez bien travaillé votre marque. C’est ce qu’on appel en ecommerce le branding.<br/><br/>
    
    Vous pouvez sou traiter carrément les publicités digitales et les confier à une autre entreprise spécialisée, et ne faire que le suivi pendant que vous vous focaliser à agrandir le business, discuter de nouveaux prix avec les fournisseurs, ou bien conclure des partenariats…<br/><br/>
    
    2-    Choisir la bonne plateforme digitale pour votre vitrine en ligne<br/><br/>
    
    Vous voulez réussir dans le ecommerce ? Alors il faut traiter votre vitrine en ligne comme la vitrine de vente dans le monde réel. <br/><br/>
    
    La plupart des gens qui se lancent dans le ecommerce donnent de l’importance à toutes les étapes pour établir un business ecommerce, cependant, lorsqu’il s’agit de la plateforme de vente de leurs produits, ils choisissent l’option la moins chère quel que soit la réputation, les options, et le design de cette plateforme.<br/><br/>
    
    Lorsque tu choisies la plateforme pour établir votre vitrine, alors choisi-la du point de vue de votre client, est ce que cette vitrine va inspirer confiance qui encourage les gens à acheter de vous ?<br/><br/>
    
    Est-ce que la plateforme offre des designs personnalisés pour distinguer ta vitrine des vitrines des compétiteurs ?<br/><br/>
    
    Est-ce que votre vitrine ou site web se charge rapidement ?<br/><br/>
    
    Est-ce que les données de vos clients sont en sécurité avec cette plateforme ?<br/><br/>
    
    Quelle est l’entreprise qui a développé cette plateforme ?<br/><br/>
    
    Et bien sûr, combien cette plateforme va te couter ?<br/><br/>
    
    Si vous demander ma recommandation, alors voici les trois plateformes que je recommande pour ouvrir une vitrine/siteweb professionnel, sure, rapide, qui inspire confiance, et qui existe assez longtemps sur le marché.<br/><br/>
    
    3-   Bien choisir le produit à vendre en ecommerce<br/><br/>
    
    S’il y’a un facteur qui détermine la réussite de votre business ecommerce à 90%, c’est bien le produit que tu as choisi pour vendre. Imagine ce scénario…<br/><br/>
    
    Tu as apporté un grand stock d’un produit de la chine avec une grande somme d’argent, y compris le prix du produit, le dédouanement, le transitaire, l’emballage, le stockage…cependant…<br/><br/>
    
    Lorsque t’as lancé des publicités pour le vendre les gens n’achètent pas, dans ce cas ca sera une perte lourde qui peut faire couler ton business ecommerce, si tu veux éviter au maximum cette situation, alors écoute-moi attentivement…<br/><br/>
    
    En ecommerce, on a un concept qui s’appelle le « Winning product », ou bien le « produit gagnant ».<br/><br/>
    
    Le produit gagnant est un produit qui se vend rapidement en grandes quantités, tu peux vendre 1000 unités de ce produit en 24 heures seulement. Peut-être que ton stock sera fini mais la demande continue à augmenter. C’est ça le winning product, et c’est sur les winning products que tu dois te concentrer dans ton business ecommerce. Or…<br/><br/>
    
    Trouver un winning product n’est pas facile, cela demande de la recherche, de l’expérience, et de l’intelligence. Mais malgré ça, je vais te donner quelques critères si tu les respecte dans ta recherche des produits à vendre, tu tomberas sur un winning product. Voici les règles…<br/><br/>
    
    Il résout vraiment un problème :<br/><br/>
    
    Le winning product résout un problème consistant chez une partie de gens, par exemple chez les femmes, les hommes, les gens qui ont un chat ou u chien, les gens qui souffrent du mal de dos, etc<br/><br/>
    
    Ce critère est très important car l’acheteur aura moins d’objection pour acheter le produit, il ira même à emprunter de l’argent pour l’acheter si vraiment il va lui résoudre un problème dans sa vie quotidienne.<br/><br/>
    
    Un winning produit que peut être vous connaissez déjà c’est celui montré dans l’image suivante :<br/><br/>
    
    C’est un produit pratique qui résout le problème des rayons de soleil lorsqu’on est dans l’automobile, il est simple et pas cher, pourtant, des entreprises ont amassé une grande fortune juste en le vendant.<br/><br/>
    
    Il n’est pas cher :<br/><br/>
    
    Le prix d’achat du winning product n’est pas cher, au Maroc, la plupart des produits winner ont un prix d’achat moins de 10$, comme ça, tu auras la possibilité d’élargir la marge de ton profit à la vente de chaque pièce de ton produit.<br/><br/>
    
    Il est petit de taille<br/><br/>
    
    Le winning product doit etre de petite taile (pas volumineux), car plus que le produit est volumineux, plus le cout de son transport sera élevé, et plus votre profit sera diminué.<br/><br/>
    
    Il a l’effet WOOW :<br/><br/>
    
    Ceci est un critère décisif pour qualifier un produit de winning product, l’effet woow représente la réaction du client à la vue du produit, peut-être il résout un problème de manière ingénieuse, peut être son design est différent, un exemple d’un produit qui envahi le marché marocain est celui-ci :<br/><br/>
    
    Ce produit vous permet de bouger n’importe quoi facilement et quel que soit son poids, tout ce que vous allez à faire c’est de placer des rouleaux au-dessous de l’objet comme montré sur la photo, et voilà, vous pouvez maintenant bouger votre frigo, canapé, machine à laver en toute faciliter sans le moindre effort !<br/><br/>
    
    Ne requiert pas des autorisations spéciales de l’état :<br/><br/>
    
    Généralement, les produits relatifs à la santé requièrent des autorisations spéciales de l’état pour les laisser entrer au Maroc, je ne dis pas qu’il faut les éviter complétement, mais lorsque vous êtes débutant il faut les éviter, car ces produits vont te couter beaucoup de temps, d’argent et de logistique !<br/><br/>
    
    Vous êtes le premier à le vendre :<br/><br/>
    
    Ou du moins vous faites partie des premières personnes le vendre, c’est pour ça pour réussir en ecommerce, vous devez être toujours avoir un œil sur le marché, et regarder chaque nouveau produit qui entre, sinon, vous risquez d’importer un produit déjà vendu, ou comme on le dit en ecommerce : un produit saturé.<br/><br/>
    
    Un produit de bonne qualité :<br/><br/>
    
    Un produit de mauvaise qualité va engendrer une réaction négative chez le consommateur, il va détruire votre réputation, et même vous trainer devant la justice, donc soyez sûr que le produit que vous comptez vendre est de bonne qualité.<br/><br/>
    
    Maintenant qu’in vu comment choisir le bon produit à vendre, nous allons voir comment choisir le bon fournisseur pour vous l’importer…<br/><br/>
    
    4-   Choisir le bon fournisseur pour réussir votre business ecommerce :<br/><br/>
    
    Le fournisseur est un facteur qui pèse très lourd dans tout business ecommerce, prenez votre temps pour le choisir surtout au début, car ce sera votre partenaire – s’il est digne de confiance- tout au long de la vie de votre business ecommerce.<br/><br/>
    
    Pour choisir le bon fournisseur, il aut tout d’abord savoir combien d’année d’expérience il a dans le domaine, personnellement je n’irai pas avec un fournisseur ayant moins de 5 ans d’expérience car c’est trop risqué.<br/><br/>
    
    Si vous êtes sur un site comme alibab.com par exemple, alors, vous avez l’opportunité de voir combien d’années un certain fournisseur a accumulé, l’évaluation de ses clients, son emplacement, etc…<br/><br/>
    
    À propos d’Alibaba, si vous contactez un fournisseur, alors soyez sûr que c’est lui-même qui fournit le produit, pas un intermédiaire, sinon, vous allez payer beaucoup plus ce que vous devez normalement !<br/><br/>
    
    Il y’a d’autres astuces pour trouver des fournisseurs pour votre produit, mais ces trois sont suffisantes pour mener à bien votre business ecommerce.<br/><br/>
    
    5-   Donner le bon prix à votre produit :<br/><br/>
    
    Le prix de vente de votre produit ne dépend pas de vous malheureusement, en ecommerce surtout dans le mode du cash on delivery/ paiement à a livraison, le calcul de ce prix est différent.<br/><br/>
    
    Pour donner le bon prix à votre produit, vous devez suivre ces étapes, (vous êtes obligé de le faire, car au début de mon business ecommerce, j’ai perdu presque 30.000 dh à cause de ce simple calcul que je n’ai pas fait, c’était une très bonne leçon pour moi)<br/><br/>
    
    Tout d’abord calculez les charges relatives au produit (C1) :<br/><br/>
    Cela comprend le prix d’achat du produit+ le prix d’importation+ prix de la douane+ le prix du transitaire+ le prix du transport à la place du stockage+ le prix du stockage.<br/><br/>
    
    Ensuite calculez les charges relatives à l’opération (C2):<br/><br/>
    Cela comprend le prix des appels pour confirmer les commandes+ le prix des publicités+ le prix de l’emballage + prix de livraison.<br/><br/>
    
    Enfin, ajouter 5% de risque sur les charges et voyez ce que ça vous donnera (C3°.
    Donc le prix avec lequel vous pouvez vendre votre produit est : C1+C2+C3+ 20% à 30%<br/><br/>
    
    Cependant, il faut tester plusieurs prix de vente avant de se fixer sur un prix, si le produit est nouveau et winner, alors vous pouvez ajouter même 70% sans aucun problème au lier de 20% ou 30%.<br/><br/>
    
    Maintenant, passons à l’étape suivante dans le ecommerce : la commercialisation et le marketing<br/><br/>
    
    6-   Choisir la bonne stratégie de marketing digital pour commercialiser vote produit en mode paiement à la livraison<br/><br/>
    
    La bonne stratégie de marketing digital pour commercialiser votre produit doit être variable et flexible tout au long de la vie de ton produit, car il faut savoir qu’en ecommerce, vous n’allez pas vendre…<br/><br/>
    
     
    
    Crédit : Morad. Csfunnel.com (CEO). Auteur de « Les tactiques confidentielles du marketing digital »<br/><br/>
    
    <a href="https://www.linkedin.com/pulse/ecommerce-le-guide-plus-complet-pour-r%C3%A9ussir-au-maroc-morad-chykhy/?originalSubdomain=fr">https://www.linkedin.com/pulse/ecommerce-le-guide-plus-complet-pour-r%C3%A9ussir-au-maroc-morad-chykhy/?originalSubdomain=fr</a>`
  },
];
export default articles;