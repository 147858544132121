import React, {useEffect} from "react";
import { NavLink } from "../../components";

const Conditions = () => {

    useEffect(() => {
        document.body.removeAttribute("class");
        document.body.classList.add(
          "home",
          "page-template-default",
          "page-id-79",
          "page",
          "wp-custom-logo",
        );
    
        // Cleanup on component unmount
        return () => {
          document.body.classList.remove(
            "home",
            "page",
            "page-template-default",
            "page",
            "page-id-79",
            "wp-custom-logo",
          );
        };
      }, []); 

  return (
    <>
        <div>
            <NavLink step_1="Accueil" step_2="Conditions générales d’utilisation" />
        </div>
        <div id="primary" className="content-area">
        <main id="main" className="site-main">
            <article
            id="post-3"
            className="post-3 page type-page status-publish hentry"
            >
            <header className="entry-header">
                <h1 className="entry-title">Conditions générales d’utilisation</h1>
            </header>
            <div style={{fontSize:'15px', lineHeight : 1.85}} className="entry-content">
                <div className="vc_row wpb_row vc_row-fluid">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                        <div className="wpb_text_column wpb_content_element">
                        <div className="wpb_wrapper">
                            <p>
                            <strong>Article 1</strong>: <strong>Objet</strong>
                            </p>
                            <p>
                            Les présentes CGU ou Conditions Générales
                            d’Utilisation encadrent juridiquement l’utilisation
                            des services du site b2bmaroc.com.
                            </p>
                            <p>
                            Constituant le contrat entre la société Marocaine{" "}
                            <strong>Organic Line SARL AU</strong> – éditrice du
                            site <strong>b2bmaroc.com</strong> – et l’
                            <strong>Utilisateur</strong> du site b2bmaroc.com,
                            l’accès au site b2bmaroc.com doit être précédé de
                            l’acceptation de ces CGU. L’accès à ce site signifie
                            l’acceptation des présentes CGU.
                            </p>
                            <p>
                            <strong>Article 2</strong>:{" "}
                            <a href="https://www.legalplace.fr/guides/mentions-legales/">
                                Mentions légales
                            </a>
                            </p>
                            <p>
                            L’édition du site <strong>b2bmaroc.com</strong> est
                            assurée par la société Marocaine{" "}
                            <strong>Organic Line SARL AU</strong> inscrite au RC
                            sous le numéro 24011, dont le siège social est
                            domicilié à : 67 Hay Laayoune 3eme étage Appt numéro
                            5, El Alia, Mohammedia, Maroc.
                            </p>
                            <p>
                            <strong>B2B Maroc</strong> est une marque déposée
                            auprès de l’
                            <strong>OMPIC</strong> (Office Marocain de la
                            propriété industrielle et commerciale) sous le numéro
                            d’enregistrement <strong>241117</strong> appartenant à
                            la même société Organic Line SARL AU.
                            </p>
                            <p>
                            <strong>Utilisateur(s):</strong> désigne tout
                            utilisateur du Site <strong>b2bmaroc</strong>.
                            </p>
                            <p>
                            <strong>
                                Le site , le site b2bmaroc , le site b2bmaroc.com :
                            </strong>{" "}
                            désigne l’entreprise et l’administrateurs du site{" "}
                            <strong>b2bmaroc</strong>.
                            </p>
                            <p>
                            <strong>Article 3</strong>:
                            <strong> Accès au site</strong>
                            </p>
                            <p>
                            Le site b2bmaroc.com permet à l’Utilisateur d’accéder
                            aux services suivants:
                            </p>
                            <ul>
                            <li>
                                Mise en relation entre les utilisateurs
                                professionnels qui consultent le site;
                            </li>
                            <li>
                                Création d’une page professionnelle de chaque
                                utilisateur inscrit au site;
                            </li>
                            <li>
                                Consultations du contenu professionnel au sein du
                                site par les utilisateurs inscrits ou non inscrits;
                            </li>
                            <li>Aide à la prospection commerciale;</li>
                            <li>
                                Achat des services payants dites Premium et achat
                                d’espaces de publicitaires au sein du site.
                            </li>
                            </ul>
                            <p>
                            Le site b2bmaroc.com est accessible gratuitement
                            depuis n’importe où par tout utilisateur disposant
                            d’un accès à Internet. Tous les frais nécessaires pour
                            l’accès aux services (matériel informatique, connexion
                            Internet…) sont à la charge de l’utilisateur.
                            </p>
                            <p>
                            L’accès aux services dédiés aux membres s’effectue à
                            l’aide d’un identifiant et d’un mot de passe. Les
                            services du site sont réservés uniquement aux seuls
                            professionnels donc juridiquement en capacité de
                            souscrire des contrats en droit Marocain.
                            </p>
                            <p>
                            Pour des raisons de maintenance ou autres, l’accès au
                            site peut être interrompu ou suspendu par l’éditeur
                            sans préavis ni justification.
                            </p>
                            <p>
                            Certains services proposés par le site b2bmaroc.com
                            sont payants et nécessitent un abonnement annuel ou
                            partiel de la part du l’utilisateur du service.
                            </p>
                            <p>
                            <strong>Article 4</strong>:{" "}
                            <strong>Collecte des données</strong>
                            </p>
                            <p>
                            Le site b2bmaroc.com utilise uniquement des cookies
                            fonctionnels liés au bon fonctionnement du site et à
                            l’amélioration de l’experience utilisateur. Ces
                            cookies essentiels permettent aux utilisateurs de
                            naviguer sur le site et de s’inscrire et se connecter
                            à leurs comptes ainsi que de sécuriser leurs
                            connexions et modifier leurs préférences.
                            </p>
                            <p>
                            Les cookies correspondent à de petits fichiers déposés
                            temporairement sur le disque dur de l’ordinateur de
                            l’Utilisateur. Ces cookies sont nécessaires pour
                            assurer l’accessibilité et la navigation sur le site.
                            Ces fichiers ne comportent pas d’informations
                            personnelles et ne peuvent pas être utilisés pour
                            l’identification d’une personne.
                            </p>
                            <p>
                            Le site b2bmaroc.com n’utilise pas des cookies
                            marketing et les cookies tiers pour les utilisateurs
                            et ne vends pas les données des utilisateurs aux
                            entreprises tierces.
                            </p>
                            <p>
                            En navigant sur le site b2bmaroc.com, l’Utilisateur
                            accepte les cookies fonctionnels. La désactivation de
                            ces derniers peuvent s’effectuer via les paramètres du
                            logiciel de navigation de l’utilisateur; cependant,
                            cela peut affecter la navigation et la connexion au
                            sein des différentes pages et liens du site.
                            </p>
                            <p>
                            <strong>Article 5</strong>:{" "}
                            <strong>Propriété intellectuelle</strong>
                            </p>
                            <p>
                            Les marques, logos ainsi que les contenus du site
                            b2bmaroc sont protégés par le Code de la propriété
                            intellectuelle et par le droit d’auteur.
                            </p>
                            <p>
                            La reproduction et la copie des contenus par
                            l’Utilisateur requièrent une autorisation préalable du
                            site. Dans ce cas, toute utilisation à des usages
                            commerciaux ou à des fins publicitaires est proscrite.
                            </p>
                            <p>
                            <strong>Article 6</strong>&nbsp;:{" "}
                            <strong>Responsabilité</strong>
                            </p>
                            <p>
                            Bien que les informations publiées sur le site
                            b2bmaroc.com sont réputées fiables, le site se réserve
                            la faculté d’une non-garantie de la fiabilité des
                            sources.
                            </p>
                            <p>
                            Le site b2bmaroc.com décline toute responsabilité
                            concernant des éventuels virus ou bien des manœuvres
                            malicieuses d’un tiers pouvant infecter le matériel
                            informatique de l’Utilisateur après l’utilisation ou
                            l’accès à ce site.
                            </p>
                            <p>
                            Le site b2bmaroc.com ne peut être tenu pour
                            responsable en cas de force majeure ou du fait
                            imprévisible et insurmontable d’un tiers.
                            </p>
                            <p>
                            La garantie totale de la sécurité et la
                            confidentialité des données n’est pas assurée par le
                            site. Cependant, le site s’engage et s’efforce à
                            mettre en œuvre toutes les méthodes requises pour le
                            faire au mieux.
                            </p>
                            <p>
                            <strong>Article 7</strong>&nbsp;:{" "}
                            <strong>Liens hypertextes</strong>
                            </p>
                            <p>
                            Le site b2bmaroc.com peut être constitué de liens
                            hypertextes. En cliquant sur ces derniers,
                            l’Utilisateur sortira du site. Cette dernière n’a pas
                            de contrôle et ne peut pas être tenue responsable du
                            contenu des pages web relatives à ces liens.
                            </p>
                            <p>
                            <strong>Article 8</strong>&nbsp;:{" "}
                            <strong>Publication par l’Utilisateur</strong>
                            </p>
                            <p>
                            Le site b2bmaroc.com permet aux Utilisateurs membres
                            de publier des informations professionnelles au sein
                            du site.
                            </p>
                            <p>
                            Dans ses publications, l’Utilisateur est tenu de
                            respecter les règles du présent contrat.
                            </p>
                            <p>
                            Le site dispose du droit d’exercer une modération à
                            priori sur les publications et peut refuser leur mise
                            en ligne sans avoir à fournir de justification.
                            </p>
                            <p>
                            L’Utilisateur est tenu responsable de tout contenu
                            qu’il met en ligne. L’Utilisateur s’engage à ne pas
                            publier de contenus listés ci-dessous :<br />
                            </p>
                            <ul>
                            <li style={{ listStyleType: "none" }}>
                                <ul>
                                <li>
                                    Produits, services et appareils liées aux&nbsp;:
                                    drogues, cigarettes, stupéfiants, stéroïdes,
                                    &nbsp;produis acholiques ou d’autres produits
                                    qui présentent un risque pour la sécurité des
                                    consommateurs.
                                </li>
                                </ul>
                            </li>
                            </ul>
                            <p></p>
                            <ul>
                            <li style={{ listStyleType: "none" }}>
                                <ul>
                                <li>
                                    Les produits ou services qui sont considérés
                                    comme obscènes ou à caractère sexuel ou
                                    pornographique.
                                </li>
                                </ul>
                            </li>
                            </ul>
                            <p></p>
                            <ul>
                            <li style={{ listStyleType: "none" }}>
                                <ul>
                                <li>
                                    Les produits ou services financières liées aux
                                    Institutions Financières et Fiscales, Banques,
                                    Sociétés d’assurances et réassurance,
                                    institutions ou courtiers de FOREX.
                                </li>
                                </ul>
                            </li>
                            </ul>
                            <p></p>
                            <ul>
                            <li style={{ listStyleType: "none" }}>
                                <ul>
                                <li>
                                    Les articles qui encouragent, promeuvent,
                                    facilitent ou donnent des instructions à
                                    d’autres personnes pour qu’elles se lancent dans
                                    une activité illégale.
                                </li>
                                </ul>
                            </li>
                            </ul>
                            <p></p>
                            <ul>
                            <li style={{ listStyleType: "none" }}>
                                <ul>
                                <li>
                                    Les biens volés, y compris les biens numériques
                                    et virtuels
                                </li>
                                </ul>
                            </li>
                            </ul>
                            <p></p>
                            <ul>
                            <li style={{ listStyleType: "none" }}>
                                <ul>
                                <li>
                                    La promotion de la haine, de la violence, de
                                    l’intolérance raciale ou d’autres formes
                                    d’intolérance discriminatoire oul’exploitation
                                    financière d’un crime
                                </li>
                                </ul>
                            </li>
                            </ul>
                            <p></p>
                            <ul>
                            <li style={{ listStyleType: "none" }}>
                                <ul>
                                <li>
                                    Les éléments qui enfreignent ou violent tout
                                    droit d’auteur, marque de commerce, droit de
                                    publicité ou de confidentialité outout autre
                                    droit de propriété en vertu des lois de toute
                                    juridiction
                                </li>
                                </ul>
                            </li>
                            </ul>
                            <p></p>
                            <ul>
                            <li style={{ listStyleType: "none" }}>
                                <ul>
                                <li>
                                    Les munitions, les armes à feu, ou certaines
                                    pièces ou accessoires d’armes à feu, ou
                                    certaines armes ou couteaux
                                </li>
                                </ul>
                            </li>
                            </ul>
                            <p></p>
                            <ul>
                            <li style={{ listStyleType: "none" }}>
                                <ul>
                                <li>
                                    Les produits numériques qui enfreignent un droit
                                    d’auteur, des marques ou tout autre droit
                                    appartiennent à une entreprise
                                </li>
                                </ul>
                            </li>
                            </ul>
                            <p></p>
                            <ul>
                            <li style={{ listStyleType: "none" }}>
                                <ul>
                                <li>
                                    Les dispositifs de diffusion de médias en
                                    continu qui permettent d’accéder à des contenus
                                    non autorisés, notamment les boîtiers IPTV et
                                    services similaires.
                                </li>
                                </ul>
                            </li>
                            </ul>
                            <p></p>
                            <ul>
                            <li style={{ listStyleType: "none" }}>
                                <ul>
                                <li>
                                    Les produits ou services liées ou facilitants
                                    des transactions suspectes dont la source n’est
                                    pas justifiée ou illégale.
                                </li>
                                </ul>
                            </li>
                            </ul>
                            <p></p>
                            <ul>
                            <li style={{ listStyleType: "none" }}>
                                <ul>
                                <li>
                                    Les Produits ou services aidant à fournir des
                                    informations fausses, inexactes ou trompeuses
                                </li>
                                </ul>
                            </li>
                            </ul>
                            <p></p>
                            <ul>
                            <li style={{ listStyleType: "none" }}>
                                <ul>
                                <li>
                                    Harcèlement ou menace des employés de notre
                                    société, agents ou bien des autres utilisateurs
                                    du site b2bmaroc.com
                                </li>
                                </ul>
                            </li>
                            </ul>
                            <p>
                            La suppression ou la modification par le site du
                            contenu ou du compte de l’Utilisateur peut s’effectuer
                            à tout moment, pour n’importe quelle raison et sans
                            préavis.
                            </p>
                            <p>
                            Le site se réserve le droit de supprimer une partie du
                            contenu d’un utilisateur si ce dernier dépasse les 15
                            % de la base de données totale du site. L’utilisateur
                            recevra auparavant un message de la part de
                            l’administration du site via son adresse e-mail ou son
                            compte sur le site l’invitant à réduire son contenu.
                            En cas de refus ou d’absence de réponse de la part de
                            l’utilisateur, le site procèdera à une suppression
                            partielle du contenu de l’utilisateur.
                            </p>
                            <p>
                            <strong>Article 9</strong>&nbsp;:{" "}
                            <strong>
                                Les services payants et tarifs au sein du site
                            </strong>{" "}
                            <strong>b2bmaroc.com</strong>
                            </p>
                            <p>
                            Le site utilise une sécurité avancée SSL de navigation
                            et des modes de paiement de transactions sécurisés et
                            s’efforce à offrir une sécurité optimale lors des
                            transactions commerciales des services payants au sein
                            du site. Cependant, la garantie des transactions
                            commerciales n’est pas assurée par le site en cas de
                            force majeure, de piratage de données ou d’un
                            comportement frauduleux d’un tiers.
                            </p>
                            <p>
                            Les tarifs des services payants dites Premium sont
                            clairs et sont en HT (hors taxes fiscales Marocaines)
                            pour les Utilisateurs résidents résidents dans le
                            territoire Marocain et sont exprimés en Dirham
                            Marocain. pour les Utilisateurs internationaux non
                            résidents dans le territoire Marocain les tarifs sont
                            affichés en Dollar Américain HT (Hors Taxes) exclus
                            des taxes fiscales Marocaines.
                            </p>
                            <p>
                            Cependant, L’achat de certains bannières publicitaires
                            peut être partiel et non annuel. dans ce cas,
                            l’utilisateur sera informé par le prix (incluant les
                            taxes possibles) et la durée d’affichage des bannières
                            avant et après la conception de la facture commerciale
                            mise entre, nous la société, et l’Utilisateur du
                            service commercial.
                            </p>
                            <p>
                            Nous nous réservons le droit de modifier les tarifs,
                            frais et commissions de nos services à tout moment.
                            </p>
                            <p>
                            En cas de modification des tarifs, ces modifications
                            seront effectives dès leur mise en ligne sur le site.
                            L’utilisateur pourra visualiser les nouveaux tarifs
                            dans le site b2bmaroc.com .
                            </p>
                            <p>
                            En cas de réduction des tarifs (Rabais, Remise,
                            Ristourne), L’utilisateur sera informé par la
                            réduction des tarifs dans la facture commerciale mise
                            entre, nous la société, et l’Utilisateur.
                            </p>
                            <p>
                            <strong>Article 10</strong>&nbsp;:{" "}
                            <strong>Durée du contrat</strong>
                            </p>
                            <p>
                            Le présent contrat est valable pour une durée
                            indéterminée. Le début de l’utilisation des services
                            du site marque l’application du contrat à l’égard de
                            l’Utilisateur. Cependant, une modification ou une mise
                            à jour de ce présent contrat peut avoir lieu.
                            </p>
                            <p>
                            <strong>Article 11</strong>&nbsp;:{" "}
                            <strong>
                                Droit applicable et juridiction compétente
                            </strong>
                            </p>
                            <p>
                            Le présent contrat est soumis à la législation
                            Marocaine. L’absence de résolution à l’amiable des cas
                            de litige entre les parties implique le recours aux
                            tribunaux Marocains compétents pour régler le
                            contentieux.
                            </p>
                            <p>
                            Toutes procédures engagées en justice par un
                            Utilisateur ou tiers lésé à l’encontre du site devront
                            être prises en charge par l’Utilisateur ou tiers.
                            <br />
                            </p>
                            <hr className="wp-block-separator has-alpha-channel-opacity" />
                            <em>
                            Nous vous encourageons à signaler immédiatement toute
                            violation de cette politique d’utilisation acceptable.
                            </em>
                            <p></p>
                            <p>
                            <em>
                                Si vous avez une question concernant cette politique
                                d’utilisation acceptable, ou si vous souhaitez
                                déposer une plainte, vous pouvez nous contacter via
                            </em>{" "}
                            <a href="mailto:contact@b2bmaroc.com">
                                contact@b2bmaroc.com
                            </a>
                            </p>
                            <p>&nbsp;</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </article>
        </main>
        </div>
    </>
  );
};

export default Conditions;
