import React from 'react'
import { Link } from 'react-router-dom';
import { Experimental_Device_Set, Page_A_Propos } from '../../constants/images';

const AproposNous = () => {

    const information = JSON.parse(localStorage.getItem("information-owner"))

    return (
        <article
            id="post-2494"
            className="post-2494 page type-page status-publish has-post-thumbnail hentry"
        >
        <header
            className="entry-header header-with-cover-image"
            style={{
            backgroundImage:
                `url(${Page_A_Propos})`,
            }}
        >
            <div className="caption">
            <h1 className="entry-title"></h1>
            </div>
        </header>
        <div className="entry-content">
            <div style={{marginTop : '80px', lineHeight: 1.85}} className="vc_row wpb_row vc_row-fluid vc_custom_1649894513964">
            <div className="wpb_column vc_column_container vc_col-sm-6">
                <div className="vc_column-inner vc_custom_1646827090823">
                <div className="wpb_wrapper">
                    <div className="wpb_text_column wpb_content_element vc_custom_1687358971712">
                    <div className="wpb_wrapper">
                        <h2
                        className="contact-page-title"
                        style={{ textAlign: "left" }}
                        >
                        <strong>B2B MAROC</strong>
                        </h2>
                    </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element vc_custom_1687359029128">
                    <div className="wpb_wrapper">
                        <p style={{ textAlign: "justify", color: "#5e5873" }}>
                        <strong>B2B MAROC</strong> est une plateforme B2B (
                        <strong>Business to Business</strong>) de mise en relation
                        des professionnels au Maroc et à l’international (
                        <strong>Grossistes</strong>, <strong>détaillants</strong>,{" "}
                        <strong>fabricants</strong>, <strong>producteurs</strong>,
                        prestataires de services, <strong>exportateurs</strong>,{" "}
                        <strong>importateurs</strong>). elle appartient à la
                        société Marocaine <strong>Organic Line SARL AU</strong>.
                        </p>
                    </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element vc_custom_1676489567202">
                    <div className="wpb_wrapper">
                        <p style={{ color: "#5e5873" }}>
                        <strong>B2B Maroc</strong> vous propose de créer votre
                        page entreprise au sein de la plateforme, cette page vous
                        permet de présenter votre activité ainsi que vos produits
                        ou services aux professionnels. Ces derniers pourront voir
                        vos produits ou services listés dans votre page et
                        éventuellement vous contacter pour entrer en relation avec
                        vous.
                        </p>
                        <p style={{ color: "#5e5873" }}>
                        Vous pouvez également grâce à la plateforme de publier une
                        annonce d’achat, de vente ou bien d’export si vous
                        souhaitez cibler les marchés internationaux ainsi que de
                        contacter les autres clients et demander des Devis
                        gratuits.
                        </p>
                        <p style={{ color: "#5e5873" }}>
                        La plateforme est l’emplacement idéal pour valoriser votre
                        image de marque, d’améliorer votre présence sur le net
                        afin d’attirer plus de professionnels vers vous.
                        </p>
                        <p style={{ color: "#5e5873" }}>
                        En optant pour les packs{" "}
                        <span style={{ color: "#f9a607" }}>PREMIUM</span>, vous
                        bénéficier d’une panoplie d’avantages et d’options comme
                        l’aide à la prospection, la mise en place de vos produits
                        ou services en tête de liste par catégorie/sous-catégorie,
                        la mise en place d’une centaine de vos produits ou
                        services et bien plus. Vous pouvez effectuer le paiement
                        sécurisé de l’un des packs choisis via plusieurs modes de
                        paiement.
                        </p>
                    </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element vc_custom_1646830806265">
                    <div className="wpb_wrapper">
                        <p style={{ color: "#5e5873" }}>
                        Veuillez bien consulter <em>nos packs </em>
                        <strong>PREMIUM</strong> pour davantage d’informations.
                        </p>
                    </div>
                    </div>
                    <div className="vc_btn3-container vc_btn3-inline vc_custom_1670244747240">
                    <Link
                        className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-round vc_btn3-style-outline vc_btn3-icon-right vc_btn3-color-orange"
                        to="/plans/"
                        title="Nos Packs PREMIUM"
                    >
                        Voir nos packs PREMIUM{" "}
                        <i className="vc_btn3-icon vc-material vc-material-arrow_forward"></i>
                    </Link>
                    </div>
                </div>
                </div>
            </div>
            <div className="wpb_column vc_column_container vc_col-sm-6">
                <div className="vc_column-inner vc_custom_1649893902329">
                <div className="wpb_wrapper">
                    <div className="wpb_text_column wpb_content_element vc_custom_1715647506018">
                    <div className="wpb_wrapper">
                        <p>&nbsp;</p>
                        <p style={{ textAlign: "justify" }}>
                        <Link to="/avantages-de-vendre-sur-b2b-maroc/">
                            <strong>B2B Maroc</strong>
                        </Link>{" "}
                        se soucie également aux enjeux économiques Marocains,
                        c’est la raison pour laquelle nous avons mis des solutions
                        spéciales pour les entreprises de recyclage et activités
                        liées, les exportateurs ainsi que les Auto-entrepreneurs
                        qui éprouvent parfois des difficultés pour se lancer.
                        <br />
                        Le développement de ces derniers aura un impact majeur sur
                        la croissance de l’économie Marocaine.
                        </p>
                    </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element vc_custom_1716484384660">
                    <div className="wpb_wrapper">
                        <p style={{ textAlign: "justify" }}>
                        En plus, un pack spécial{" "}
                        <strong>Auto-entrepreneur</strong> et un{" "}
                        <strong>pack Export</strong> et Pack pour les entreprises
                        de <strong>recyclage</strong> et activités liées est
                        disponible afin de les soutenir dans leurs investissements
                        et attirer les professionnels vers eux.
                        </p>
                    </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element vc_custom_1716484408593">
                    <div className="wpb_wrapper">
                        <p style={{ textAlign: "justify" }}>
                        Finalement, vous pouvez également consulter notre page
                        Blog en menu principal qui regroupe des conseils dans le
                        domaine des affaires au Maroc par exemple:
                        </p>
                        <p style={{ textAlign: "justify" }}>
                        Vente, achat, export, investissement, trouver des
                        investisseurs, etc ..
                        </p>
                    </div>
                    </div>
                    <div className="vc_btn3-container vc_btn3-inline vc_custom_1670244734662">
                    <Link
                        className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-round vc_btn3-style-outline vc_btn3-icon-right vc_btn3-color-orange"
                        to="/plans"
                        title="Nos Packs PREMIUM"
                    >
                        Voir nos packs PREMIUM{" "}
                        <i className="vc_btn3-icon vc-material vc-material-arrow_forward"></i>
                    </Link>
                    </div>
                    <div className="wpb_single_image wpb_content_element vc_align_left">
                    <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper vc_box_rounded vc_box_border_grey">
                        <img
                            fetchpriority="high"
                            decoding="async"
                            width="2560"
                            height="1920"
                            src={Experimental_Device_Set}
                            className="vc_single_image-img attachment-full"
                            alt="14-Experimental-Device-Set-Mockup"
                            title="14-Experimental-Device-Set-Mockup"
                        />
                        </div>
                    </figure>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="vc_row wpb_row vc_row-fluid">
            <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner">
                <div className="wpb_wrapper">
                    <div className="wpb_text_column wpb_content_element">
                    <div className="wpb_wrapper">
                        <p>
                            Nous sommes également presents dans les réseaux sociaux et
                            sociaux-professionnels listés ci-dessous
                        </p><div className="footer-social-icons mt-3">
                                <ul style={{
                                    fontSize : '20px'
                                }} className="social-icons list-unstyled nav justify-content-md-start justify-content-center align-items-center">
                                    <li>
                                    <a
                                        className="fab fa-facebook"
                                        target="_blank"
                                        href={information?.facebook}
                                    ></a>
                                    </li>
                                    <li>
                                    <a
                                        className="fab fa-linkedin"
                                        target="_blank"
                                        href={information?.linkdin}
                                    ></a>
                                    </li>
                                    <li>
                                    <a
                                        className="fab fa-instagram"
                                        target="_blank"
                                        href={information?.instagram}
                                    ></a>
                                    </li>
                                </ul>
                            </div>
                    </div>{" "}
                    </div>{" "}
                </div>{" "}
                </div>{" "}
            </div>{" "}
            </div>{" "}
        </div>{" "}
        </article>
    );

};

export default AproposNous;
