import React from 'react'

const NosPaiements = () => {
  return (
    <section
  data-vc-full-width="true"
  data-vc-full-width-init="true"
  className="vc_section vc_custom_1717176837281"
  style={{
    position: "relative",
    boxSizing: "border-box"
  }}
>
  <div className="vc_row wpb_row vc_row-fluid vc_custom_1717176749054">
    <div className="wpb_column vc_column_container vc_col-sm-12">
      <div className="vc_column-inner">
        <div className="wpb_wrapper">
          <h2
            style={{
              fontSize: "36px",
              color: "#434343",
              textAlign: "left"
            }}
            className="vc_custom_heading contact-page-title"
          >
            Nos solutions de paiement
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div className="vc_row wpb_row vc_row-fluid vc_custom_1717176900103">
    <div className="wpb_column vc_column_container vc_col-sm-12">
      <div className="vc_column-inner">
        <div className="wpb_wrapper">
          <div className="wpb_text_column wpb_content_element">
            <div className="wpb_wrapper">
              <ul>
                <li><strong>Paiement par carte de crédit</strong></li>
              </ul>
              <p>
                Le paiement est effectué par carte de crédit via notre solution
                de paiement sécurisée
              </p>
              <ul>
                <li><strong>Paiement cash à la livraison</strong></li>
              </ul>
              <p>
                Le paiement cash à la livraison est disponible, le paiement est
                effectué après la configuration de votre page premium par l’un
                de nos employés dans notre local ou bien dans le vôtre.
              </p>
              <ul>
                <li><strong>Paiement par virement bancaire</strong></li>
              </ul>
              <p>
                Le paiement est effectué par un virement du montant à payer sur
                notre compte bancaire, ou via l’une des agences de transfert
                d’argent au nom convenu. Contactez-nous pour qu’on puisse vous
                envoyer notre numéro de compte bancaire.
              </p>
              <p>
                Pour en savoir plus sur nos modes de paiement,{" "}
                <a href="https://www.b2bmaroc.com/contactez-nous/">
                  contactez-nous
                </a>{" "}
                par email ou par téléphone
              </p>
              <p>&nbsp;</p>
              <p>
                <em>
                  Envie d’une publicité avancée de votre marque au sein du site
                  et dans les réseaux sociaux ?{" "}
                </em>
                <a href="http://b2bmaroc.com/contactez-nous/">
                  <em>Contactez-nous!</em>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default NosPaiements