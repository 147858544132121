import React, {useState, useEffect} from 'react'
import { baseRoute } from '../../constants/env'

const SearchFBar = ({sortWith, setCity, setCountrySelected, setTypeSelected, hundelClick}) => {
    
    const [CountriesAndCities, setCountriesAndCities] = useState([])
    const [countries, setCountries] = useState([])
    const [cities, setCities] = useState([])
    const types = [
        'Détaillant',
        'Fabricant',
        'Producteur',
        'Prestataire de services',
        'Grossiste',
        'Exportateur',
        'Importateur',
        'Grand compte',
        'Revendeur',
        'Startup'
    ];
    
    useEffect(() => {

        (async () => {
    
        try {
            
            const response = await fetch(`/assets/filtered_countries.json`);
            const data = await response.json();
            setCountriesAndCities(data);
            const countryNames = data.map((country) => country.country_name);
            setCountries(countryNames);
    
        } catch (error) {
    
            console.error('Error fetching countries:', error);
            return [];
            
        }
    
        })()
    
    }, [])

    const hundelChange = (e) => {

        if(e.target.value != ''){
            setCountrySelected(e.target.value)
            setCities(
                CountriesAndCities.filter(item => item.country_name == e.target.value)[0]?.cities
            )

        }

    }

  return (
    <div style={{
        background: '#f1f1f1',
        padding: '25px',
        borderRadius: '9px',
        marginBottom : '20px'
    }}>
        <h3>Filters</h3>
        <hr />
        <div style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            gap: '20px',
            flexWrap: 'wrap',
        }} className='main-searchBar'>

            <div className='select'>
                <select
                    style={{
                        padding: '.594rem 1rem',
                    }}
                    onChange={(e) => setTypeSelected(e.target.value)}
                >
                    <option value=''>Type d’entreprise</option>
                    {
                        types?.map(item => (
                            <option value={item}>{item}</option>
                        ))
                    }
                </select>
            </div>

            <div className='select'>
                <select
                    style={{
                        padding: '.594rem 1rem',
                    }}
                    onChange={(e) => hundelChange(e)}
                >
                    <option value=''>Select payer</option>
                    {
                        countries?.map(item => (
                            <option value={item}>{item}</option>
                        ))
                    }
                </select>
            </div>

            <div className='select'>
                <select
                    style={{
                        padding: '.594rem 1rem',
                    }}
                    onChange={(e) => setCity(e.target.value)}
                >
                    <option value=''>Select ville</option>
                    {
                        cities?.map(item => (
                            <option value={item}>{item}</option>
                        ))
                    }
                </select>
            </div>

            <button 
                style={{
                    background: '#333e48',
                    color: 'white',
                }}  
                className='button'
                onClick={(e) => hundelClick(1, sortWith)}
            >
                Rechercher
            </button>

        </div>
    </div>
  )
}

export default SearchFBar