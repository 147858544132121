import React, {useEffect} from 'react'
import { Outlet } from 'react-router-dom'
import { Footer, Header, TopBar } from './components'
import { axiosClient } from './constants/axionsClient';
import './styles/styles.css'
function App() {

  const hundelCloseMenu = () => {
    const divPageId = document.querySelector("#page");
    const bodyElement = document.body;

    if (divPageId?.classList.contains("off-canvas-bg-opacity")) {
      bodyElement.classList.remove("off-canvas-active");
      divPageId.classList.remove("off-canvas-bg-opacity");

      document
        .querySelectorAll(".off-canvas-navigation-wrapper")
        ?.forEach((ele) => {
          ele.classList.remove("toggled");
          ele.querySelector("#default-oc-header").removeAttribute("style");
        });
    }
  };

  const closeSideBar = () => {
    document.querySelector('#content').classList.remove('active-hh-sidebar')
  }

  useEffect(()=>{

    (async()=>{

      const information = localStorage.getItem('information-owner')

      if(!information){

        try {
          
          const res = await axiosClient.get("/information")
          if(res.status === 200){

            localStorage.setItem('information-owner', JSON.stringify(res.data))

          }

        } catch (error) {
          console.log(error);
        }

      }

    })()

  }, [])

  return (
    <>
      <TopBar/>
      <Header />
      <div id="content" onClick={() => hundelCloseMenu()} className="site-content" tabIndex="-1">
              <div onClick={() => closeSideBar()} className='sideFilter' />
              <div className="container">
                  <div className="site-content-inner row">
                      <Outlet/>
                  </div>
              </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
